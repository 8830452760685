/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { HighlightOff } from "@mui/icons-material";
import { Dialog } from "@mui/material";
import React, { ReactNode } from "react";
import { ContentPage, ModalCloseIcon, ModalInnerContainer } from "./styled";

export function Modal({
    isOpen,
    onClose,
    children,
    style,
    className,
    dataCy
}: {
    style?: React.CSSProperties;
    className?: string;
    dataCy?: string;
    isOpen: boolean;
    onClose: (event: React.MouseEvent<HTMLImageElement>, reason?: "backdropClick" | "escapeKeyDown") => void;
    children: ReactNode;
    }): React.ReactElement {

    if (!dataCy) {
        dataCy = "modal_btnClose"
    }

    return (
        <Dialog open={isOpen} onClose={onClose} className={className}>
            <ModalCloseIcon component={HighlightOff} onClick={onClose} data-cy={dataCy} />

            <ModalInnerContainer style={style}>
                <ContentPage>{children}</ContentPage>
            </ModalInnerContainer>
        </Dialog>
    );
}

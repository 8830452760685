import { iD3Data, iData } from "../types";

interface DeleteD3NodeRelationshipParams {
    d3Data: iD3Data;
    nodeRelationshipId: string;
    data: iData;
    setData: React.Dispatch<React.SetStateAction<iData>>;
}

export const deleteD3NodeRelationship = ({
    d3Data,
    nodeRelationshipId,
    data,
    setData
}: DeleteD3NodeRelationshipParams): iData => {
    const newDataNodeRelationships = data.nodeRelationships.filter((nr) => nr._id !== nodeRelationshipId);

    // Remove connections between object and nodeRelationship
    const newDataRels = data.relLinks.filter((rl) => {
        if (rl.source._id === nodeRelationshipId || rl.target._id === nodeRelationshipId) {
            return false;
        }
        return true;
    });

    // Remove nodeRelationship within object
    const newDataObjects = data.objects.filter((item) => {
        item.nodeRelationships = item.nodeRelationships.filter((nr) => nr.rel?._id !== nodeRelationshipId);
        return item;
    });

    // Remove connections between script and nodeRelationship
    const newDataScripts = data.scripts.map((s) => {
        s.relationships = s.relationships.filter((rel) => rel.relatedTo !== nodeRelationshipId)
        return s
    });

    const newData = {
        ...data,
        objects: newDataObjects,
        scripts: newDataScripts,
        nodeRelationships: newDataNodeRelationships,
        relLinks: newDataRels
    };

    setData(newData);
    return newData;
};

import { gql } from "@apollo/client";

export const ADD_EXISTING_USER_SHARED = gql`
  mutation addExistingUserShared($email: String!, $serverID: ID!, $tenantID: ID) {
      addExistingUserShared(input: { email: $email, serverID: $serverID, tenantID: $tenantID })
  }
`;

export const ADD_NEW_USER_SHARED = gql`
  mutation addNewUserShared($email: String!, $serverID: ID!, $emailOptions: emailOptionsInput!) {
      addNewUserShared(input: { email: $email, serverID: $serverID, emailOptions: $emailOptions }) {
          _id
      }
  }
`;

export const REMOVE_USER_SHARED = gql`
  mutation removeUserShared($serverID: ID!, $userID: ID!) {
    removeUserShared(input: { userID: $userID, serverID: $serverID })
  }
`;

export const GET_USERS_FROM_DATASERVER_SHARED = gql`
  query getUsersByDataServerShared($serverID: ID!, $first: Int, $skip: Int, $searchWord: String) {
    getUsersByDataServerShared(input: { serverID: $serverID, first: $first, skip: $skip, searchWord: $searchWord }) {
          users {
              _id
              email
              name
              createDateTime
              isVerified
              role
              address
              company
              country
              mobilePhone
              creationDate
          }
          totalItems
      }
  }
`;

export const PRIM_ADMIN_ADD_EXISTING_USER = gql`
  mutation adminExistingUser($email: String!, $tenantID: ID!) {
      adminExistingUser(input: { email: $email, tenantID: $tenantID })
  }
`;

export const PRIM_ADMIN_CREATE_NEW_USER = gql`
  mutation adminAddNewUser($email: String!, $tenantID: ID, $emailOptions: emailOptionsInput!) {
      adminAddNewUser(input: { email: $email, tenantID: $tenantID, emailOptions: $emailOptions }) {
          _id
          email
          name
          role
          company
          address
          country
          mobilePhone
      }
  }
`;

export const ADMIN_CHANGE_USER_ROLE = gql`
  mutation adminChangeUserRole($tenantID: ID, $userID: ID!, $role: TenantRole!) {
      adminChangeUserRole(input: { userID: $userID, tenantID: $tenantID, role: $role })
  }
`;

export const ADMIN_REMOVE_USER_FROM_TENANT = gql`
  mutation adminRemoveUser($tenantID: ID!, $userID: ID!) {
      adminRemoveUser(input: { userID: $userID, tenantID: $tenantID })
  }
`;
export const ADMIN_ADD_USER_TO_TENANT = gql`
  mutation adminAddUser($tenantID: ID!, $userID: ID!) {
      adminAddUser(input: { userID: $userID, tenantID: $tenantID })
  }
`;

export const GET_USERS_BY_TENANT = gql`
  query adminGetUsersByTenant($tenantID: ID!, $first: Int, $skip: Int, $searchWord: String) {
      adminGetUsersByTenant(input: { tenantID: $tenantID, first: $first, skip: $skip, searchWord: $searchWord }) {
          users {
              _id
              email
              name
              createDateTime
              role
              address
              company
              country
              mobilePhone
              isVerified
              isExpiredVerifyCode
              verificationCode
              timestamp
          }
          totalItems
      }
  }
`;

export const GET_USERS = gql`
  query adminGetUsers($first: Int!, $skip: Int!, $searchWord: String) {
      adminGetUsers(input: { first: $first, skip: $skip, searchWord: $searchWord }) {
          users {
              _id
              email
              name
              createDateTime
              roles
              address
              company
              country
              mobilePhone
              timestamp
              tenants {
                  role
                  tenantID: _id
                  createDateTime
                  name
                  niamID
              }
              isVerified
              isExpiredVerifyCode
              verificationCode
          }
          totalItems
      }
  }
`;

export const ADMIN_SET_PWD = gql`
  mutation adminSetPwd($pwd: String!, $userID: ID!) {
    adminSetPwd(input: { pwd: $pwd, userID: $userID })
  }
`;

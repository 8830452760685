import { Step, Theme } from "@mui/material";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { BackButton, ContainerRegister, NextButton } from "./styled";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            justifyContent: "center"
        },
        instructions: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1)
        }
    })
);

interface HorizontalLinearStepperProps {
    children: React.ReactNode;
    steps: { id: number; title: string; isValid?: boolean }[];
    onActiveStep: (step: number) => void;
    beforeNext: () => void;
    setActiveStep: React.Dispatch<React.SetStateAction<number>>;
    activeStep: number;
}

export default function HorizontalLinearStepper({
    children,
    steps,
    onActiveStep,
    beforeNext,
    setActiveStep,
    activeStep
}: HorizontalLinearStepperProps): React.ReactElement {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();

    const handleNext = (): void => {
        beforeNext();
    };

    const handleBack = (): void => {
        if (activeStep === 0) {
            history.goBack();
            return;
        }

        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    React.useEffect(() => {
        onActiveStep(activeStep);

    }, [activeStep]);

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                        optional?: React.ReactNode;
                    } = {};

                    return (
                        <Step key={label.id} completed={stepProps.completed}>
                            <StepLabel optional={labelProps.optional}>
                                {label.title}
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>

            <div className={classes.instructions}>
                {children}
            </div>

            <div>
                {activeStep < steps.length - 1 && (
                    <ContainerRegister>
                        <BackButton color="inherit" onClick={handleBack}>
                            {t("createNewUser:button.back")}
                        </BackButton>

                        <NextButton onClick={handleNext} disabled={!steps[activeStep].isValid} data-cy="registerPage_btnNext">
                            {t("createNewUser:button.next")}
                        </NextButton>
                    </ContainerRegister>
                )}
            </div>
        </div>
    );
}

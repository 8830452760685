import styled from "styled-components";

export const LeftPanelFlexContainer = styled.div<{
    $isShowDashboard?: boolean;
}>`
    width: 125px;
    position: fixed;
    top: 100px;
    bottom: 25px;
    left: 12px;
    border-radius: 6px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);
    background-color: ${({ theme }) => theme.colors.white};
    padding: 0 8px;
    justify-content: start;
    align-items: center;
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-height: 550px;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.05);
    }

    scrollbar-gutter: ${({ $isShowDashboard }) => ($isShowDashboard ? "auto" : "stable")};
`;

export const BR = styled.div`
    padding: 1px 0 0 0;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.borderLight};
`;

export const TextHolder = styled.p`
    height: 15px;
    margin: 5px 0 0;
    font-family: IBM Plex Sans;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: ${({ theme }) => theme.colors.fontNormal};
    user-select: none;
`;

import React from "react";

function SvgDefs(): React.ReactElement {
    return (
        <>
            <defs>
                <marker
                    id="arrowheadend-enum"
                    markerWidth="10"
                    markerHeight="7"
                    refX="0"
                    refY="3.5"
                    orient="auto"
                    stroke="red"
                    style={{ fill: "red" }}
                >
                    <polygon points="0 0, 10 3.5, 0 7" />
                </marker>

                <marker
                    id="arrowheadstart-enum"
                    markerWidth="10"
                    markerHeight="7"
                    refX="5"
                    refY="3.5"
                    orient="auto"
                    stroke="red"
                    style={{ fill: "red" }}
                >
                    <polygon points="0 3.5, 10 7, 10 0" />
                </marker>

                <marker
                    id="arrowheadend-hover"
                    markerWidth="10"
                    markerHeight="7"
                    refX="0"
                    refY="3.5"
                    orient="auto"
                    stroke="purple"
                    style={{ fill: "purple" }}
                >
                    <polygon points="0 0, 10 3.5, 0 7" />
                </marker>

                <marker
                    id="arrowheadstart-hover"
                    markerWidth="10"
                    markerHeight="7"
                    refX="5"
                    refY="3.5"
                    orient="auto"
                    stroke="purple"
                    style={{ fill: "purple" }}
                >
                    <polygon points="0 3.5, 10 7, 10 0" />
                </marker>

                <marker
                    id="arrowheadend-path"
                    markerWidth="10"
                    markerHeight="7"
                    refX="0"
                    refY="3.5"
                    orient="auto"
                    stroke="rgb(100, 0, 100)"
                    style={{ fill: "rgb(100, 0, 100)" }}
                >
                    <polygon points="0 0, 10 3.5, 0 7" />
                </marker>

                <marker
                    id="arrowheadstart-path"
                    markerWidth="10"
                    markerHeight="7"
                    refX="5"
                    refY="3.5"
                    orient="auto"
                    stroke="rgb(100, 0, 100)"
                    style={{ fill: "rgb(100, 0, 100)" }}
                >
                    <polygon points="0 3.5, 10 7, 10 0" />
                </marker>

                <marker
                    id="arrowheadend"
                    markerWidth="10"
                    markerHeight="7"
                    refX="0"
                    refY="3.5"
                    orient="auto"
                    stroke="#1e88e5"
                    style={{ fill: "#1e88e5" }}
                >
                    <polygon points="0 0, 10 3.5, 0 7" />
                </marker>

                <marker
                    id="arrowheadstart"
                    markerWidth="10"
                    markerHeight="7"
                    refX="5"
                    refY="3.5"
                    orient="auto"
                    stroke="#1e88e5"
                    style={{ fill: "#1e88e5" }}
                >
                    <polygon points="0 3.5, 10 7, 10 0" />
                </marker>
            </defs>

            <defs>
                <filter id="innerBlur">
                    <feGaussianBlur stdDeviation={7.3} />
                </filter>
            </defs>

            <defs>
                <filter id="outerBlur">
                    <feGaussianBlur stdDeviation={3} />
                </filter>
            </defs>
        </>
    );
}

export default SvgDefs;

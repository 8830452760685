/* eslint-disable max-lines */
import { isNotEmpty } from "@3edges/utils/dist/utils";
import { getStorage } from "cache";
import { iD3Selection, RelationshipType } from "components/PrimGraphicalCanvas/types";
import { BaseType, local, select, Selection } from "d3-selection";
import { DefaultTheme } from "styled-components";

interface LinkType {
    source: { x: number; y: number; _id: string };
    target: { x: number; y: number; _id: string };
    type: "enum" | RelationshipType.IMPLEMENT | RelationshipType.PATHS | RelationshipType.NODE_RELATION | RelationshipType.RELATION;
    curve?: number;
    displayName?: string;
    _id: string;
    isPath?: boolean;
    isPathCreate?: boolean;
    isPathRead?: boolean;
    isPathUpdate?: boolean;
    isPathDelete?: boolean;
    simplePath?: { _id: string; relatedTo: string };
    simplePathCreate?: { _id: string; relatedTo: string };
    simplePathRead?: { _id: string; relatedTo: string };
    simplePathUpdate?: { _id: string; relatedTo: string };
    simplePathDelete?: { _id: string; relatedTo: string };
    isAction?: boolean;
    isVC?: boolean;
}

const calcCurve = (d: LinkType): string => {
    const mx = (d.source.x + d.target.x) / 2;
    const my = (d.source.y + d.target.y) / 2;
    const dx = d.target.x - d.source.x;
    const dy = d.target.y - d.source.y;
    const ap = Math.atan(-dx / dy);
    const qx = mx + d.curve * Math.cos(ap);
    const qy = my + d.curve * Math.sin(ap);

    return `M ${d.source.x} ${d.source.y} Q ${qx} ${qy} ${d.target.x} ${d.target.y}`;
};

const orbitAngle = local<number>(), linkAngle = local<number>();

export const enterLink =
        (theme: DefaultTheme) => (selection: iD3Selection): void => {
            selection
                .classed("link", true)
                .attr("id", (d: { _id: string }) => `relationshipID_${d._id}`)
                .attr("data-cy", (d: { name: string }) => `relationshipID_${d.name}`)
                // eslint-disable-next-line func-names
                .each(function(d: LinkType)
                {
                    const isPetal = d.source?._id === d.target?._id

                    // Send to back from orbit
                    if (isPetal) {
                        select(this).lower();
                        select(this).classed("petals", isPetal)
                    } else {
                        select(this).raise();
                    }

                    if (d.type === RelationshipType.IMPLEMENT) {
                        select(this)
                            .append("line")
                            .classed("implementLink", true)
                            .attr("x1", d.source.x)
                            .attr("y1", d.source.y)
                            .attr("x2", d.target.x)
                            .attr("y2", d.target.y)
                            .attr("stroke-linecap", "round")
                            .attr("stroke-width", 2);
                    } else if (d.type === RelationshipType.PATHS) {
                        select(this)
                            .append("line")
                            .classed("pathsLink", true)
                            .attr("x1", d.source.x)
                            .attr("y1", d.source.y)
                            .attr("x2", d.target.x)
                            .attr("y2", d.target.y)
                            .attr("stroke", "red")
                            .attr("stroke-linecap", "round")
                            .attr("stroke-width", 2);
                    } else {
                        if (
                            (d.source._id && d.target._id && d.source._id === d.target._id) ||
                            (!d.source._id && !d.target._id && d.source === d.target)
                        ) {
                            select(this).append("path")
                        }

                        if (d.type === "enum") {
                            select(this)
                                .append("path")
                                .attr("fill", "transparent")
                                .classed("implementEnumLink", true)
                                .attr("id", d._id)
                                .attr("stroke-linecap", "round")
                                .style("stroke-dasharray", "3, 3")
                                .attr("stroke", "red")
                                .attr("stroke-width", 2);
                        } else
                        {
                            if (d.source._id !== d.target._id) {
                                select(this)
                                    .classed("NR_Link", d.type === RelationshipType.NODE_RELATION)
                                    .append("path")
                                    .attr("fill", "transparent")
                                    .attr("id", d._id)
                                    .attr("stroke-linecap", "round")
                                    .attr("stroke", "#1e88e5")
                                    .attr("stroke-width", 2);
                            }
                        }

                        select(this)
                            .append("text")
                                .attr("dy", -5)
                                .attr("stroke", "transparent")
                                .attr("stroke-linecap", "round")
                            .append("textPath")
                                .attr("cursor", "grab")
                                .style("font-size", `${d.source === d.target ? "10px" : "13px"}`)
                                .style("font-weight", "bold")
                                .style("fill", "#555")
                                .style("letter-spacing", "3px")
                                .attr("text-anchor", "middle")
                                .attr("startOffset", "50%")
                                .attr("xlink:href", isPetal ? `#petal_${d._id}` : `#${d._id}`)
                                .text(d.displayName)

                        const isShowDashboard = getStorage("isShowDashboard");

                        if (!isShowDashboard && isNotEmpty(d.simplePath)) {

                            if(isPetal){
                                select(this).classed("setColorLinePetal", true);
                            }

                            select(this).classed("setColorPath", true)

                            select(this)
                                .append("text")
                                .attr("dy", 15)
                                .attr("stroke", "transparent")
                                .attr("stroke-linecap", "round")
                                .append("textPath")
                                .attr("cursor", "grab")
                                .style("font-size", "15px")
                                .style("fill", "#555")
                                .style("letter-spacing", "3px")
                                .attr("text-anchor", "middle")
                                .attr("startOffset", "50%")
                                .attr("id", `simplePath_${d._id}` )
                                .attr("xlink:href", isPetal ? `#petal_${d._id}` : `#${d._id}`)
                                .text("CRUD");
                        }

                        if (!isShowDashboard && isNotEmpty(d.simplePathCreate)) {

                            if(isPetal){
                                select(this).classed("setColorLinePetal", true);
                            }

                            select(this).classed("setColorPath", true)

                            select(this)
                                .append("text")
                                .attr("dy", 15)
                                .attr("stroke", "transparent")
                                .attr("stroke-linecap", "round")
                                .append("textPath")
                                .attr("cursor", "grab")
                                .style("font-size", "15px")
                                .style("fill", "#555")
                                .style("letter-spacing", "3px")
                                .attr("text-anchor", "middle")
                                .attr("startOffset", "50%")
                                .attr("id", `simplePathCreate_${d._id}` )
                                .attr("xlink:href", isPetal ? `#petal_${d._id}` : `#${d._id}`)
                                .text("Create");
                        }

                        if (!isShowDashboard && isNotEmpty(d.simplePathRead)) {

                            if(isPetal){
                                select(this).classed("setColorLinePetal", true);
                            }

                            select(this).classed("setColorPath", true)

                            select(this)
                                .append("text")
                                .attr("dy", 15)
                                .attr("stroke", "transparent")
                                .attr("stroke-linecap", "round")
                                .append("textPath")
                                .attr("cursor", "grab")
                                .style("font-size", "15px")
                                .style("fill", "#555")
                                .style("letter-spacing", "3px")
                                .attr("text-anchor", "middle")
                                .attr("startOffset", "50%")
                                .attr("id", `simplePathRead_${d._id}` )
                                .attr("xlink:href", isPetal ? `#petal_${d._id}` : `#${d._id}`)
                                .text("Read");
                        }

                        if (!isShowDashboard && isNotEmpty(d.simplePathUpdate)) {

                            if(isPetal){
                                select(this).classed("setColorLinePetal", true);
                            }

                            select(this).classed("setColorPath", true)

                            select(this)
                                .append("text")
                                .attr("dy", 15)
                                .attr("stroke", "transparent")
                                .attr("stroke-linecap", "round")
                                .append("textPath")
                                .attr("cursor", "grab")
                                .style("font-size", "15px")
                                .style("fill", "#555")
                                .style("letter-spacing", "3px")
                                .attr("text-anchor", "middle")
                                .attr("startOffset", "50%")
                                .attr("id", `simplePathUpdate_${d._id}` )
                                .attr("xlink:href", isPetal ? `#petal_${d._id}` : `#${d._id}`)
                                .text("Update");
                        }

                        if (!isShowDashboard && isNotEmpty(d.simplePathDelete)) {

                            if(isPetal){
                                select(this).classed("setColorLinePetal", true);
                            }

                            select(this).classed("setColorPath", true)

                            select(this)
                                .append("text")
                                .attr("dy", 15)
                                .attr("stroke", "transparent")
                                .attr("stroke-linecap", "round")
                                .append("textPath")
                                .attr("cursor", "grab")
                                .style("font-size", "15px")
                                .style("fill", "#555")
                                .style("letter-spacing", "3px")
                                .attr("text-anchor", "middle")
                                .attr("startOffset", "50%")
                                .attr("id", `simplePathDelete_${d._id}` )
                                .attr("xlink:href", isPetal ? `#petal_${d._id}` : `#${d._id}`)
                                .text("Delete");
                        }

                        if (!isShowDashboard && d.isAction)
                        {
                            if(isPetal){
                                select(this).classed("setColorLinePetal", true);
                            }

                            select(this).classed("setColorPath", true)

                            select(this)
                                .append("text")
                                .attr("dy", 15)
                                .attr("stroke", "transparent")
                                .attr("stroke-linecap", "round")
                                .append("textPath")
                                .attr("cursor", "grab")
                                .style("font-size", "15px")
                                .style("fill", "#555")
                                .style("letter-spacing", "3px")
                                .attr("text-anchor", "middle")
                                .attr("startOffset", "50%")
                                .attr("id", `actionRelationship_${d._id}`)
                                .attr("xlink:href", `#${d._id}`)
                                .text("AuthAction");
                        }

                        if (!isShowDashboard && d.isVC)
                        {
                            if(isPetal){
                                select(this).classed("setColorLinePetal", true);
                            }

                            select(this).classed("setColorPath", true)

                            select(this)
                                .append("text")
                                .attr("dy", 15)
                                .attr("stroke", "transparent")
                                .attr("stroke-linecap", "round")
                                .append("textPath")
                                .attr("cursor", "grab")
                                .style("font-size", "15px")
                                .style("fill", "#555")
                                .style("letter-spacing", "3px")
                                .attr("text-anchor", "middle")
                                .attr("startOffset", "50%")
                                .attr("id", `vcRelationship_${d._id}`)
                                .attr("xlink:href", `#${d._id}`)
                                .text("AuthVC");
                        }

                        const currentAuthSimplePath = select(`#simplePath_${d._id}`)
                        const currentAuthSimplePathCreate = select(`#simplePathCreate_${d._id}`)
                        const currentAuthSimplePathRead = select(`#simplePathRead_${d._id}`)
                        const currentAuthSimplePathUpdate = select(`#simplePathUpdate_${d._id}`)
                        const currentAuthSimplePathDelete = select(`#simplePathDelete_${d._id}`)
                        const currentAuthRelAction = select(`#actionRelationship_${d._id}`)
                        const currentAuthRelVC = select(`#vcRelationship_${d._id}`)

                        const listLabel = [];

                        if (currentAuthSimplePath.node()) {
                            listLabel.push(currentAuthSimplePath)
                        }

                        if (currentAuthSimplePathCreate.node()) {
                            listLabel.push(currentAuthSimplePathCreate)
                        }

                        if (currentAuthSimplePathRead.node()) {
                            listLabel.push(currentAuthSimplePathRead)
                        }

                        if (currentAuthSimplePathUpdate.node()) {
                            listLabel.push(currentAuthSimplePathUpdate)
                        }

                        if (currentAuthSimplePathDelete.node()) {
                            listLabel.push(currentAuthSimplePathDelete)
                        }

                        if (currentAuthRelAction.node()) {
                            listLabel.push(currentAuthRelAction)
                        }

                        if (currentAuthRelVC.node()) {
                            listLabel.push(currentAuthRelVC)
                        }

                        switch (listLabel.length) {

                            case 1: {
                                listLabel[0].attr("startOffset", "50%").attr("font-size", "16px");

                            break;
                            }
                            case 2: {
                                listLabel[1].attr("startOffset", "66%").attr("font-size", "14px");
                                listLabel[0].attr("startOffset", "33%").attr("font-size", "14px");

                            break;
                            }
                            case 3: {
                                listLabel[2].attr("startOffset", "75%").attr("font-size", "13px");
                                listLabel[1].attr("startOffset", "50%").attr("font-size", "13px");
                                listLabel[0].attr("startOffset", "25%").attr("font-size", "13px");

                            break;
                            }
                            case 4: {
                                listLabel[3].attr("startOffset", "80%").attr("font-size", "12px");
                                listLabel[2].attr("startOffset", "60%").attr("font-size", "12px");
                                listLabel[1].attr("startOffset", "40%").attr("font-size", "12px");
                                listLabel[0].attr("startOffset", "20%").attr("font-size", "12px");

                            break;
                            }
                            case 5: {
                                listLabel[4].attr("startOffset", "82%").attr("font-size", "11px");
                                listLabel[3].attr("startOffset", "66%").attr("font-size", "11px");
                                listLabel[2].attr("startOffset", "50%").attr("font-size", "11px");
                                listLabel[1].attr("startOffset", "32%").attr("font-size", "11px");
                                listLabel[0].attr("startOffset", "16%").attr("font-size", "11px");

                            break;
                            }
                            case 6: {
                                listLabel[5].attr("startOffset", "84%").attr("font-size", "10px");
                                listLabel[4].attr("startOffset", "70%").attr("font-size", "10px");
                                listLabel[3].attr("startOffset", "56%").attr("font-size", "10px");
                                listLabel[2].attr("startOffset", "42%").attr("font-size", "10px");
                                listLabel[1].attr("startOffset", "28%").attr("font-size", "10px");
                                listLabel[0].attr("startOffset", "14%").attr("font-size", "10px");

                            break;
                            }
                            case 7: {
                                listLabel[6].attr("startOffset", "86.5%").attr("font-size", "9px");
                                listLabel[5].attr("startOffset", "75%").attr("font-size", "9px");
                                listLabel[4].attr("startOffset", "62.2%").attr("font-size", "9px");
                                listLabel[3].attr("startOffset", "50%").attr("font-size", "9px");
                                listLabel[2].attr("startOffset", "37.5%").attr("font-size", "9px");
                                listLabel[1].attr("startOffset", "25%").attr("font-size", "9px");
                                listLabel[0].attr("startOffset", "12.5%").attr("font-size", "9px");

                            break;
                            }
                            // No default
                            }
                    }
                })

        };

export const updateLink =
        (theme: DefaultTheme) => (selection: Selection<BaseType, LinkType, BaseType, unknown>): void => {
            // eslint-disable-next-line func-names
            selection.each(function(linkData)
            {
                if (linkData.type === RelationshipType.IMPLEMENT || linkData.type === RelationshipType.PATHS) {
                    select(this)
                        .select("line")
                        .attr("x1", linkData.source.x)
                        .attr("y1", linkData.source.y)
                        .attr("x2", linkData.target.x)
                        .attr("y2", linkData.target.y)
                        .attr(
                            "transform",
                            `translate(${(linkData.source.x + linkData.target.x) * 0.1} , ${
                                (linkData.source.y + linkData.target.y) * 0.1
                            }) scale(0.8)`
                        );
                } else {
                    const path = select(this).select("path");
                    const isPetal = linkData.source._id === linkData.target._id

                    if (linkData.source._id === linkData.target._id) {
                        const orbit = select(`#orbitID_${linkData.source._id || ""}`);

                        if (linkAngle.get(path.node() as Element)) {
                            path.attr("transform", `translate(${linkData.source.x} , ${linkData.source.y}) scale(2)`);
                            path.transition().duration(1)
                            path.attr("d", path.attr("d"))
                            path.attr("id", isPetal ? `petal_${linkData._id}` : linkData._id)
                        } else {
                            let tempAngle = orbitAngle.get(orbit.node() as Element);

                            if (!tempAngle) {
                                tempAngle = 0;
                            }

                            const outsidePetal = true;
                            const tmpA = outsidePetal ? 9 : 10;

                            const sinL = (outsidePetal ? 150 : 175) * Math.sin(tempAngle - tmpA);
                            const sinR = (outsidePetal ? 150 : 175) * Math.sin(tempAngle + tmpA);
                            const cosL = (outsidePetal ? 150 : 175) * Math.cos(tempAngle - tmpA);
                            const cosR = (outsidePetal ? 150 : 175) * Math.cos(tempAngle + tmpA);

                            path.attr("fill", "transparent")
                            path.style("stroke", "#1e88e5")
                            path.style("stroke-width", 1)
                            path.transition().duration(1)
                            path.attr("d", `M 0 0 C ${sinL} ${cosL}, ${sinR} ${cosR}, 0 0`)
                            path.attr("id", isPetal ? `petal_${linkData._id}` : linkData._id)
                            .classed("addMarkerStart", Boolean(linkData.source.x > linkData.target.x))
                            .classed("addMarkerEnd", Boolean(linkData.source.x <= linkData.target.x))

                            orbitAngle.set(orbit.node() as Element, tempAngle + 70);
                            linkAngle.set(path.node() as Element, tempAngle);
                        }
                    } else {
                        select(this)
                            .select("path")
                            .attr("d", calcCurve(linkData))
                            .classed("addMarkerStart", Boolean(linkData.source.x > linkData.target.x))
                            .classed("addMarkerEnd", Boolean(linkData.source.x <= linkData.target.x))

                            .attr(
                                "transform",
                                `translate(${(linkData.source.x + linkData.target.x) * 0.1} , ${
                                    (linkData.source.y + linkData.target.y) * 0.1
                                }) scale(0.8) ${
                                    linkData.source.x > linkData.target.x
                                        ? `rotate(180 ${(linkData.source.x + linkData.target.x) / 2} ${
                                              (linkData.source.y + linkData.target.y) / 2
                                          })`
                                        : ""
                                }`
                            );
                    }
                }
            })
        };


import { Button, ButtonProps } from "@mui/material";
import styled from "styled-components";

export const BoxRelative = styled.div`
    position: relative;
`;

export const BoxAbsolute = styled.div`
    position: absolute;
    left: 0;
    z-index: 1;
`;

export const UsersContainer = styled(Button).attrs((prop) => ({
            color: prop.color ? prop.color : "primary",
            variant: "contained"
        } as ButtonProps))`
    && {
        height: 38px;
        padding: 10px 16px;
        box-sizing: border-box;
        border-radius: 4px;
        font-family: IBM Plex Sans;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: center;
    }
`;

/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable camelcase */

export const setStorage = (key: string, value: string | unknown, sharedBetweenTabs: boolean = true): void => {
    if (typeof value === "string") {
        sharedBetweenTabs ? localStorage.setItem(`PRIM-${key}`, value) : sessionStorage.setItem(`PRIM-${key}`, value);
    } else {
        const data = JSON.stringify(value);
        sharedBetweenTabs ? localStorage.setItem(`PRIM-${key}`, data) : sessionStorage.setItem(`PRIM-${key}`, data);
    }
};

export const getStorage = <T = unknown>(key: string, sharedBetweenTabs: boolean = true): T => {
    const value = sharedBetweenTabs ? localStorage.getItem(`PRIM-${key}`) : sessionStorage.getItem(`PRIM-${key}`) || "";

    try {
        const data = JSON.parse(value) as T;
        return data;
    } catch {}

    return value as unknown as T;
};

export const delStorage = (key: string, sharedBetweenTabs: boolean = true): void => {
    sharedBetweenTabs ? localStorage.removeItem(`PRIM-${key}`) : sessionStorage.removeItem(`PRIM-${key}`);
};

export const clearStorage = (): void => {
    localStorage.clear();
    sessionStorage.clear();
};

export const selectedUser = (value?: any): any => {
    if (value) {
        setStorage("user", value);
    } else {
        return getStorage<any>("user") || { _id: "", email: "" } as any
    }
};

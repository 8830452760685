/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/prop-types */
import { ReactComponent as CustomMutationIcon } from "assets/images/icon-custom-mutation.svg";
import { ReactComponent as InterfaceIcon } from "assets/images/left-panel-interface.svg";
import { ReactComponent as ObjectIcon } from "assets/images/left-panel-object.svg";
import { ReactComponent as PolicyActionIcon } from "assets/images/policy-action.svg";

import { ReactComponent as TagIcon } from "assets/images/left-panel-tag.svg";
import { ReactComponent as ScriptIcon } from "assets/images/left-panel-script.svg";
import { ReactComponent as PolicyResourceIcon } from "assets/images/policy-resource.svg";
import { ReactComponent as NodeRelationshipIcon } from "assets/images/left-panel-node.svg";
import { ReactComponent as EnumIcon } from "assets/images/enums-icon.svg";

import { ReactComponent as PolicyArrowIcon } from "assets/images/policy-arrow.svg";
import {
    ReactComponent as PolicyClaimIcon,
    ReactComponent as PolicyHolderIcon,
    ReactComponent as PolicyIssuerIcon,
    ReactComponent as PolicyVCIcon,
} from "assets/images/policy-vc.svg";

import { TimelineOutlined as PolicyOperatorIcon } from '@mui/icons-material';
import { ReactComponent as PolicySubjectIcon } from "assets/images/policy-subject.svg";
import DraggableContainer from "components/DraggableContainer";
import React from "react";
import { useTranslation } from "react-i18next";
import { BR, LeftPanelFlexContainer, TextHolder } from "./styled";

export const leftPanelItems: {
    icon?: React.ReactNode;
    textCode: string;
    id: string;
    visible: boolean;
}[] = [
    {
        icon: <ObjectIcon style={{ width: 24, height: 24 }} />,
        textCode: "leftPanel:object",
        id: "LPD-Object",
        visible: true
    },
    {
        icon: <InterfaceIcon style={{ width: 24, height: 24 }} />,
        textCode: "leftPanel:interface",
        id: "LPD-Interface",
        visible: true
    },
    {
        icon: <ScriptIcon style={{ width: 24, height: 24 }} />,
        textCode: "leftPanel:script",
        id: "LPD-Script",
        visible: true
    },
    {
        icon: <CustomMutationIcon style={{ width: 24, height: 24 }} />,
        textCode: "leftPanel:customMutation",
        id: "LPD-CustomMutation",
        visible: true
    },
    {
        icon: <TagIcon style={{ width: 24, height: 24 }} />,
        textCode: "leftPanel:tag",
        id: "LPD-Tag",
        visible: true
    },
    {
        icon: <NodeRelationshipIcon style={{ width: 24, height: 24 }} />,
        textCode: "leftPanel:nodeRelationship",
        id: "LPD-NodeRelationship",
        visible: true
    },
    {
        icon: <EnumIcon style={{ width: 24, height: 24 }} />,
        textCode: "leftPanel:enum",
        id: "LPD-Enum",
        visible: true
    },
    {
        icon: <PolicyArrowIcon style={{ width: 24, height: 24 }} />,
        textCode: "leftPanel:arrow",
        id: "LPD-Path",
        visible: false
    },
    {
        icon: <PolicyArrowIcon style={{ width: 24, height: 24 }} />,
        textCode: "leftPanel:arrowCreate",
        id: "LPD-PathCreate",
        visible: false
    },
    {
        icon: <PolicyArrowIcon style={{ width: 24, height: 24 }} />,
        textCode: "leftPanel:arrowRead",
        id: "LPD-PathRead",
        visible: false
    },
    {
        icon: <PolicyArrowIcon style={{ width: 24, height: 24 }} />,
        textCode: "leftPanel:arrowUpdate",
        id: "LPD-PathUpdate",
        visible: false
    },
    {
        icon: <PolicyArrowIcon style={{ width: 24, height: 24 }} />,
        textCode: "leftPanel:arrowDelete",
        id: "LPD-PathDelete",
        visible: false
    },
    {
        icon: <PolicyResourceIcon style={{ width: 24, height: 24 }} />,
        textCode: "leftPanel:resource",
        id: "LPD-Resource",
        visible: false
    },
    {
        icon: <PolicySubjectIcon style={{ width: 24, height: 24 }} />,
        textCode: "leftPanel:subject",
        id: "LPD-Subject",
        visible: false
    },
    // {
    //     icon: <PolicyIcon style={{ width: 24, height: 24 }} />,
    //     textCode: "leftPanel:policy",
    //     id: "LPD-Policy",
    //     visible: false
    // },
    {
        icon: <PolicyActionIcon style={{ width: 24, height: 24 }} />,
        textCode: "leftPanel:action",
        id: "LPD-Action",
        visible: false
    },
    {
        icon: <PolicyOperatorIcon style={{ width: 24, height: 24 }} />,
        textCode: "leftPanel:AndOperator",
        id: "LPD-AndOperator",
        visible: false
    },
    {
        icon: <PolicyOperatorIcon style={{ width: 24, height: 24 }} />,
        textCode: "leftPanel:OrOperator",
        id: "LPD-OrOperator",
        visible: false
    },
    // {
    //     icon: <PolicyFieldIcon style={{ width: 24, height: 24 }} />,
    //     textCode: "leftPanel:field",
    //     id: "LPD-Field",
    //     visible: false
    // },
    {
        icon: <PolicyVCIcon style={{ width: 24, height: 24 }} />,
        textCode: "leftPanel:vc",
        id: "LPD-VC",
        visible: false
    },
    {
        icon: <PolicyHolderIcon style={{ width: 24, height: 24 }} />,
        textCode: "leftPanel:holder",
        id: "LPD-Holder",
        visible: false
    },
    {
        icon: <PolicyIssuerIcon style={{ width: 24, height: 24 }} />,
        textCode: "leftPanel:issuer",
        id: "LPD-Issuer",
        visible: false
    },
    {
        icon: <PolicyClaimIcon style={{ width: 24, height: 24 }} />,
        textCode: "leftPanel:claim",
        id: "LPD-Claim",
        visible: false
    },
];

interface LeftPanelProps {
    isShowDashboard?: boolean;
}

function LeftPanel({ isShowDashboard }: LeftPanelProps): React.ReactElement {
    const { t } = useTranslation();

    return (
        <LeftPanelFlexContainer $isShowDashboard={isShowDashboard}>
            {leftPanelItems
            .filter(item => item.visible === isShowDashboard)
                .map((lpi) => (
                    <DraggableContainer draggable id={lpi.id} key={lpi.textCode}>
                        <>
                            {lpi.icon}

                            <TextHolder>
                                {t(lpi.textCode)}
                            </TextHolder>
                        </>
                    </DraggableContainer>
                ))
                .reduce((result, item) => (
                    <>
                        {result}

                        <BR />

                        {item}
                    </>
                ))}
        </LeftPanelFlexContainer>
    );
}

export default LeftPanel;

/* eslint-disable unicorn/prefer-module */
/* eslint-disable unicorn/prefer-node-protocol */
/* eslint-disable @typescript-eslint/no-require-imports */
declare global
{
    interface Window {
        _env: Partial<NodeJS.ProcessEnv>;
    }
}

globalThis.Buffer = globalThis.Buffer || require('buffer').Buffer;

export const REACT_ENV: any = { ...process.env, ...globalThis._env }

import { toastError } from "@3edges/utils/dist/toastify";
import { isNotEmpty } from "@3edges/utils/dist/utils";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useDeleteD3Items } from "components/PrimGraphicalCanvas/deleteD3Items/useDeleteD3Items";
import { EDeleteType } from "components/PrimGraphicalCanvas/types";
import { useCanvasContext } from "contexts/canvasContext";
import { useData } from "contexts/dataContext";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { rPImplementGet } from "types/operation-result-types";
import { RP_DELETE_IMPLEMENT, RP_GET_IMPLEMENT } from "./gql";
import { Form, LoadingIconStyled, RightPanelBreadCrumbStyled, RightPanelDeleteIconStyled, RightPanelLoadingStyled } from "./styled";

interface RPImplementProps {
    isOpen: boolean;
    setIsOpen: (state: boolean) => void;
}

function RPImplement ({ isOpen, setIsOpen }: RPImplementProps): React.ReactElement {
    const { d3Data } = useCanvasContext();
    const { itemSelected, setItemSelected } = useData();
    const [getData, { data, loading }] = useLazyQuery<rPImplementGet>(RP_GET_IMPLEMENT, { fetchPolicy: "no-cache" });
    const onDelete = useDeleteD3Items();

    const { t } = useTranslation();
    const [deleteImplement] = useMutation(RP_DELETE_IMPLEMENT);

    useEffect(() => {
        if (isNotEmpty(itemSelected.data._id)) {
            getData({ variables: { implementID: itemSelected.data._id } });
        }
    }, [itemSelected.data]);

    if (loading && !data) {
        return null
    }

    return (
        <>
            <RightPanelLoadingStyled $isShowing={loading}>
                <LoadingIconStyled />
            </RightPanelLoadingStyled>

            <RightPanelBreadCrumbStyled
                $isShowing={isOpen}
                onClick={(event) => {
                    d3Data.zoomOnNodesInstance(event, itemSelected.data.type === "Implement" && itemSelected.data.parent);
                }}
            >
                {itemSelected.data.type === "Implement" && itemSelected.data.parent.displayName}
            </RightPanelBreadCrumbStyled>

            <Form>
                <RightPanelDeleteIconStyled
                    onClick={() => {
                        setIsOpen(false);

                        void deleteImplement({
                            variables: {
                                implementID: itemSelected.data._id
                            }
                        }).then(({ errors }: any) => {
                            if (errors) {
                                for (const e of errors) {
                                    toastError(t(`validations:${e.message}`));
                                }
                                return;
                            }

                            onDelete({
                                id: String(data.niamImplement.interfaceID) + String(data.niamImplement.objectID),
                                type: EDeleteType.IMPLEMENT
                            });

                            setItemSelected(null);
                        }).catch((error) => console.log(error));
                    }}
                />
            </Form>
        </>
    );
}

export default RPImplement;

import { useData } from "contexts/dataContext";
import { zoomIdentity } from "d3-zoom";
import { NodeTypename } from "../types";
import { DataPoint, UseZoomOnLinksParams } from "./types";

const UseZoomOnNodes = (): ((props: UseZoomOnLinksParams) => (e: any, d: DataPoint["source"]) => UseZoomOnLinksParams["zoomInstance"]["transform"]) => {
    const { setItemSelected } = useData();

    return ({ zoomInstance, width, height, container }: UseZoomOnLinksParams) => (e, d): any => {
        e.preventDefault();
        e.stopPropagation();

        // Centralize node on click
        container
        .transition()
        .duration(750)
        .call(
            zoomInstance.transform,
            zoomIdentity
                .translate(width / 2, height / 2)
                .scale(1)
                .translate(-d.x, -d.y - 65)
        );

        if (d.__typename === NodeTypename.NIAM_OBJECT)
        {
                setItemSelected({
                    data: {
                        type: "Object",
                        _id: d._id,
                        displayName: d.displayName,
                        x: d.x,
                        y: d.y,
                        __typename: d.__typename,
                        isSubject: d.isSubject
                    },
                    hasSaved: true
                });
        }

        if (d.__typename === NodeTypename.NIAM_INTERFACE) {
            setItemSelected({
                data: {
                    type: "Interface",
                    _id: d._id,
                    displayName: d.displayName,
                    x: d.x,
                    y: d.y,
                    __typename: d.__typename
                },
                hasSaved: true
            });
        }

        if (d.__typename === NodeTypename.NIAM_SCRIPT) {
            setItemSelected({
                data: {
                    type: "Script",
                    _id: d._id,
                    displayName: d.displayName,
                    x: d.x,
                    y: d.y,
                    __typename: d.__typename
                },
                hasSaved: true
            });
        }

        if (d.__typename === NodeTypename.NIAM_CUSTOM_MUTATION) {
            setItemSelected({
                data: {
                    type: "CustomMutation",
                    _id: d._id,
                    displayName: d.displayName,
                    x: d.x,
                    y: d.y,
                    __typename: d.__typename
                },
                hasSaved: true
            });
        }

        if (d.__typename === NodeTypename.NIAM_NODE_RELATIONSHIP) {
            setItemSelected({
                data: {
                    type: "NodeRelationship",
                    _id: d._id,
                    displayName: d.displayName,
                    x: d.x,
                    y: d.y,
                    __typename: d.__typename
                },
                hasSaved: true
            });
        }

        if (d.__typename === NodeTypename.NIAM_TAG) {
            setItemSelected({
                data: {
                    type: "Tag",
                    _id: d._id,
                    displayName: d.displayName,
                    x: d.x,
                    y: d.y,
                    __typename: d.__typename
                },
                hasSaved: true
            });
        }

        if (d.__typename === NodeTypename.NIAM_ENUM) {
            setItemSelected({
                data: {
                    type: "Enum",
                    _id: d._id,
                    displayName: d.displayName,
                    x: d.x,
                    y: d.y,
                    __typename: d.__typename
                },
                hasSaved: true
            });
        }
    };
};

export default UseZoomOnNodes;

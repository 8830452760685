import styled from "styled-components";

export const MainDiv = styled.div`
    background-color: ${(props) => props.theme.colors.background};
`;

export const PageContainer = styled.div`
    background-color: ${(props) => props.theme.colors.background};
    width: 100vw;
    height: 100vh;
    position: relative;
`;

export const CenteredContainer = styled.div`
    background-color: ${(props) => props.theme.colors.white};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

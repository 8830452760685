/* eslint-disable react/forbid-component-props */
import { toastError } from "@3edges/utils/dist/toastify";
import PanelHeader from "components/PanelHeader";
import PanelServer from "components/PanelServer";
import SidebarWrapper from "components/SidebarWrapper";
import { useData } from "contexts/dataContext";
import { PageContainer } from "pages/Canvas/styled";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useParams } from "react-router-dom";
import PanelEclipse from "ui-components/PanelEclipse";

function DataServers(): React.ReactElement {
    const { t } = useTranslation();
    const { tenantID } = useParams<{ tenantID: string }>();
    const { setServer, getTenant, loadingDS, tenant, loadingTenant, errorDS, errorTenant } = useData();

    const [open, setOpen] = React.useState(true);

    const handleDrawerOpen = (): void => {
        setOpen(true);
    };

    const handleDrawerClose = (): void => {
        setOpen(false);
    };

    useEffect(() => {
        if (tenantID) {
            getTenant(tenantID);
        }
    }, [tenantID]);

    useEffect(() => {
        setServer(null)
    }, []);

    if (loadingDS || loadingTenant || (tenantID && !tenant)) {
        return null;
    }

    if (errorDS || errorTenant) {
        toastError(t(`serverInfo:${errorDS.message || errorTenant.message}`));
        return <Redirect to="/" />;
    }

    return (
        <SidebarWrapper open={open} handleDrawerClose={handleDrawerClose}>
            <PageContainer>
                <PanelHeader open={open} handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen} />

                <PanelServer />

                <PanelEclipse />
            </PageContainer>
        </SidebarWrapper>
    );
}
export default DataServers;

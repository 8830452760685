import React from "react";
import { InnerEllipse, OuterEllipse } from "./styled";

function PanelEclipse(): React.ReactElement {
    return (
        <OuterEllipse>
            <InnerEllipse />
        </OuterEllipse>
    );
}

export default PanelEclipse;

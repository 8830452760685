import styled from "styled-components";

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;

    .title {
        font-size: 20px;
    }

    .tenantsContainer {
        border-top: 1px solid #e3e3e3;
        border-bottom: 1px solid #e3e3e3;
        border-radius: 3px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
    }
`;

export const TenantItem = styled.div`
    padding: 5px;
    transition: 0.1s linear all;

    &:hover {
        cursor: pointer;
        font-weight: 600;
        background: #e3e3e3;
    }
`;

import styled from "styled-components";

export const OuterEllipse = styled.div`
    width: 72vw;
    height: 73vh;
    position: fixed;
    bottom: 0;
    right: 0;
    border-radius: 100% 0 0 0;
    -webkit-filter: blur(7.3px);
    filter: blur(7.3px);
    border: solid 2px rgba(38, 81, 119, 0.2);
`;

export const InnerEllipse = styled.div`
    position: fixed;
    width: 71vw;
    height: 72vh;
    bottom: 0;
    right: 0;
    border-radius: 100% 0 0 0;
    -webkit-filter: blur(30.2px);
    filter: blur(30.2px);
    border: solid 2px rgba(38, 81, 119, 0.72);
`;

/* eslint-disable unicorn/no-lonely-if */
/* eslint-disable unicorn/no-document-cookie */
/* eslint-disable unicorn/prefer-ternary */
/* eslint-disable react/function-component-definition */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/no-multi-comp */
import { toastError } from "@3edges/utils/dist/toastify";
import { clearStorage, delStorage, getStorage, setStorage } from "cache";
import ShareDataServer from "components/ModalDataServer/ShareDataServer";
import { useCookie } from "contexts/cookieContext";
import { REACT_ENV } from "environmentVariables";
import Activation from "pages/Activation";
import AdminPage from "pages/AdminPage";
import Code from "pages/AuthorizationCode";
import Canvas from "pages/Canvas";
import CreateNewUser from "pages/CreateNewUser";
import DataServers from "pages/DataServers";
import { GoogleAuth } from "pages/GoogleAuth";
import Login from "pages/Login";
import NotFoundPage from "pages/NotFoundPage";
import Profile from "pages/Profile";
import ResetPWD from "pages/ResetPWD";
import ServiceAccounts from "pages/ServiceAccounts";
import Tenants from "pages/Tenants";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIdleTimer } from "react-idle-timer";
import { BrowserRouter, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { refreshTokenExchange, removeAllCookiesNoRerender } from "utilsFn";

function SwitchRoutes(): React.ReactElement
{
    const [isAuth, setIsAuth] = useState(true);
    const { getAccessTokenCookie } = useCookie();
    const currentCookie = getAccessTokenCookie();
    const refreshTokenLocalStorage = getStorage(REACT_ENV.REACT_APP_REFRESH_TOKEN_LOCAL_STORAGE_NAME ? REACT_ENV.REACT_APP_REFRESH_TOKEN_LOCAL_STORAGE_NAME : "default_nid_r") as string;
    const { t } = useTranslation();

    const history = useHistory();
    const location = useLocation();

    if (!["/", "/login", "/activation", "/activation-set-pwd", "/new-user", "/reset-pwd", "/code", "/googleAuth"].includes(location.pathname) && !currentCookie && !refreshTokenLocalStorage) {
        toastError(t("validations:session.expired"), { toastId: 0 });
        history.push("/login")
    }

    if (!currentCookie && !refreshTokenLocalStorage && isAuth) {
        setIsAuth(false)
    } else if (!currentCookie && refreshTokenLocalStorage && isAuth) {
        refreshTokenExchange(refreshTokenLocalStorage).then((response) => {
            if (!response) {
                setIsAuth(false)
            }
        }).catch((error) => console.log(error))
    }

    const forceLogout = async (): Promise<void> => {
        toastError(t("validations:session.expired"), { toastId:0 });
        clearStorage();
        removeAllCookiesNoRerender();
        globalThis.location.href = "/login"
    }

    const { getRemainingTime } = useIdleTimer({
        timeout: Number(REACT_ENV.REACT_APP_IDLE_TIME_IN_MINUTES ? REACT_ENV.REACT_APP_IDLE_TIME_IN_MINUTES : 10) * 60_000,
        throttle: 500
    })

    useEffect(()=> {
        const lastActivityTime = getStorage("lastActivityTime") as number
        const nowCurrent = Date.now()

        if(lastActivityTime && ((nowCurrent - lastActivityTime) > Number(REACT_ENV.REACT_APP_IDLE_TIME_IN_MINUTES ? REACT_ENV.REACT_APP_IDLE_TIME_IN_MINUTES : 10) * 60_000)) {
            if(currentCookie) {
                forceLogout()
            } else {
                delStorage("lastActivityTime")
            }
        }

        setInterval(() => {
            const remainingTimeInSeconds = Math.ceil(getRemainingTime() / 1000)

            if (remainingTimeInSeconds <= 0) {
                if(currentCookie) {
                    forceLogout()
                } else {
                    delStorage("lastActivityTime")
                }
            } else {
                const now = Date.now()
                setStorage("lastActivityTime", now)
            }
        }, 5000)
    }, [])

    return isAuth ? (
        <Switch>
            <Route component={Tenants} path="/" exact />

            <Route component={Login} path="/login" />

            <Route component={Code} path="/code" />

            <Route component={GoogleAuth} path="/googleAuth" />

            <Route component={DataServers} path="/:tenantID?/servers" />

            <Route component={() => <Activation withSetPwd />} path="/activation-set-pwd" />

            <Route component={Canvas} path="/ds/:serverID" />

            <Route component={ShareDataServer} path="/:serverID/share" />

            <Route component={Profile} path="/profile" />

            <Route component={ServiceAccounts} path="/service-accounts" />

            {REACT_ENV.REACT_APP_ENABLE_SELFREGISTRATION === 'true' &&
                <Route component={CreateNewUser} path="/new-user" />
            }

            <Route component={AdminPage} path="/:tenantID?/admin-page" />

            <Route path="*" component={() => <NotFoundPage isAuth />} />
        </Switch>
    ) : (
        <Switch>
            <Route component={Login} path="/" exact />

            <Route component={Login} path="/login" />

            <Route component={Code} path="/code" />

            <Route component={GoogleAuth} path="/googleAuth" />

            <Route component={Activation} path="/activation" exact />

            <Route component={() => <Activation withSetPwd />} path="/activation-set-pwd" />

            <Route component={ResetPWD} path="/reset-pwd" />

            {REACT_ENV.REACT_APP_ENABLE_SELFREGISTRATION === 'true' &&
                <Route component={CreateNewUser} path="/new-user" />
            }

            <Route path="*" component={NotFoundPage} />
        </Switch>
    )
}

function Routes (): React.ReactElement {
    return (
        <BrowserRouter>
            <SwitchRoutes />
        </BrowserRouter>
    );
}

export default Routes;

import { FirstPage as FirstPageIcon, KeyboardArrowLeft, KeyboardArrowRight, LastPage as LastPageIcon } from "@mui/icons-material";
import { IconButton, Table, TableBody, TableRow, Theme, useTheme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { ReactElement, useEffect, useState } from "react";
import { StyledTablePagination } from "./styled";

interface IPagination {
    totalItems: number;
    onChangePage: (e: number) => void;
    onCountChange: (e: number) => void;
    perPage: number;
    pageReset: number;
}

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

const useStyles1 = makeStyles((theme: Theme) => createStyles({
    root: {
        flexShrink: 0,
        fontSize: 16
    }
}));

function TablePaginationActions (props: TablePaginationActionsProps): ReactElement {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                {theme.direction === "rtl"
                    ? <LastPageIcon />
                    : <FirstPageIcon />}
            </IconButton>

            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === "rtl"
                    ? <KeyboardArrowRight />
                    : <KeyboardArrowLeft />}
            </IconButton>

            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === "rtl"
                    ? <KeyboardArrowLeft />
                    : <KeyboardArrowRight />}
            </IconButton>

            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === "rtl"
                    ? <FirstPageIcon />
                    : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

// eslint-disable-next-line react/no-multi-comp
export function Pagination ({ totalItems = 1, onChangePage, onCountChange, pageReset = 0, perPage = 10 }: IPagination): ReactElement {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        setPage(pageReset);
    }, [pageReset]);

    useEffect(() => {
        setRowsPerPage(perPage);
    }, [perPage]);

    const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void =>
    {
        setPage(newPage);
        onChangePage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void =>
    {
        const itemsPerPage = Number.parseInt(event.target.value)
        setRowsPerPage(itemsPerPage);
        onCountChange(itemsPerPage);
    };

    return (
        <Table style={{ flex: 1 }}>
            <TableBody>
                <TableRow>
                    <StyledTablePagination
                        count={totalItems}
                        onPageChange={handleChangePage}
                        page={page > 0 && totalItems < rowsPerPage ? 0 : page}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        labelRowsPerPage="Users per page"
                        rowsPerPage={rowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </TableRow>
            </TableBody>
        </Table>
    );
}

import styled from "styled-components";

export const MainContainer = styled.div`
    background-color: ${(props) => props.theme.colors.background};
    width: 100vw;
    height: 100vh;
    position: relative;

    .tableContainer {
        width: 90%;
        margin: 50px auto 0px;
        display: flex;
        justify-content: center;
    }
`;

export const Body = styled.div`
    width: 100vw;
    padding: 48px 223px 80px 120px;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.colors.background};
    flex: 0;
    display: flex;
    flex-direction: column;
`;

import { iD3Data, iData } from "../types";

export const deleteD3Enum = ({
    d3Data,
    enumId,
    data,
    setData
}: {
    d3Data: iD3Data;
    enumId: string;
    data: iData;
    setData: React.Dispatch<React.SetStateAction<iData>>;
}): iData => {
    const newDataEnums = data.enums.filter((e) => e._id !== enumId);
    const newRelLinks = data.relLinks.filter((e) => e.source._id !== enumId);

    // When you remove an orbit, which has a relationship with the interface orbit
    const newDataInterfaces = data.interfaces.map((item) => {
        item.fields = item.fields.filter(f => f.relatedTo !== enumId);
        return item
    });

    // When you remove an orbit, which has a relationship with the object orbit
    const newDataObjects = data.objects.map((item) => {
        item.fields = item.fields.filter(f => f.relatedTo !== enumId);
        return item
    });

    const newData = {
        ...data,
        enums: newDataEnums,
        objects: newDataObjects,
        interfaces: newDataInterfaces,
        relLinks: newRelLinks
    };

    setData(newData);
    return newData;
};

import { iD3Data, iData } from "../types";

interface DeleteD3InterfaceParams {
    d3Data: iD3Data;
    interfaceId: string;
    data: iData;
    setData: React.Dispatch<React.SetStateAction<iData>>;
}

export const deleteD3Interface = ({ d3Data, interfaceId, data, setData }: DeleteD3InterfaceParams): iData => {
    const newDataInterfaces = data.interfaces.filter((item) => item._id !== interfaceId);

    // Remove the blue line (relation)
    const newDataRels = data.relLinks.filter((rl) => {
        if (rl.source._id === interfaceId || rl.target._id === interfaceId) {
            return false;
        }
        return true;
    });

    // Remove the grey line (implementation)
    const newDataImplements = data.implementLinks.filter((il) => {
        if (il.source._id === interfaceId || il.target._id === interfaceId) {
            return false;
        }
        return true;
    });

    // When you remove an orbit, which has an implementation with the interface orbit
    const newDataObjects = data.objects.map((item) => {
        item.interfaces = item.interfaces.filter(f => f._id !== interfaceId);
        return item
    });

    const newData = {
        ...data,
        objects: newDataObjects,
        interfaces: newDataInterfaces,
        implementLinks: newDataImplements,
        relLinks: newDataRels
    };

    setData(newData);
    return newData;
};

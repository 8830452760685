import { toastError, toastSuccess, toastWarning } from "@3edges/utils/dist/toastify";
import { isEmpty } from "@3edges/utils/dist/utils";
import { useLazyQuery, useMutation } from "@apollo/client";
import { COLORS } from "assets/defaultVariables";
import { selectedUser } from "cache";
import { ButtonListFlex, ConfirmButton } from "components/ModalDataServer/styled";
import { Title } from "components/PanelProfile/styled";
import { useData } from "contexts/dataContext";
import { ADD_EXISTING_USER_SHARED, ADD_NEW_USER_SHARED } from "pages/AdminPage/gql";
import { PRIM_CHECK_IF_USER_ALREADY_EXISTS } from "pages/CreateNewUser/gql";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { Modal } from "ui-components/modal";
import { TextField } from "ui-components/styleds";
import { validateEmail } from "utils";

interface ICreatingUserFrom {
    isModalOpen: boolean;
    onClose: () => void;
    setIsModalOpen: (state: boolean) => void;
    serverID: string;
}

export default function CreatingUserFormShared ({
    isModalOpen,
    setIsModalOpen,
    serverID,
    onClose
}: ICreatingUserFrom): React.ReactElement {
    const { t } = useTranslation();
    const { tenant } = useData();
    const [addExistingUserShared] = useMutation(ADD_EXISTING_USER_SHARED);
    const [addNewUserShared] = useMutation(ADD_NEW_USER_SHARED);
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [checkIfUserAlreadyExists] = useLazyQuery(PRIM_CHECK_IF_USER_ALREADY_EXISTS);

    useEffect(() => {
        if (!isModalOpen) {
            setIsLoading(false);
            setEmail("");
        }
    }, [isModalOpen]);

    const addExitingUser = (email, serverID, tenantID): void => {
        // Add user
        void addExistingUserShared({
            variables: {
                email,
                serverID,
                tenantID
            }
        }).then(({ errors }: any) => {
            if (errors) {
                for (const e of errors) {
                    toastError(t(`validations:${e.message}`));
                }

                setIsLoading(false);
                return;
            }

            toastSuccess(t("validations:user.added.success"));

            setIsLoading(false);
            setIsModalOpen(false);
            setEmail("");
            onClose();
        }).catch((error) => console.log(error));
    }

    const onConfirm = (): void => {
        if (validateEmail(email).length === 0) {

            if (selectedUser().email === email) {
                toastWarning(t("validations:add.yourself"));
                return;
            }

            setIsLoading(true);

            void checkIfUserAlreadyExists({
                variables: {
                    email
                }
            }).then(({ data: dataExists }) => {
                const tenantID = tenant?._id;

                if (tenantID) {
                    addExitingUser(email, serverID, tenantID);
                    return;
                }

                // eslint-disable-next-line no-negated-condition
                if (dataExists.checkIfUserAlreadyExists.isVerified === null) {
                    void addNewUserShared({
                        variables: {
                            email,
                            serverID,
                            emailOptions: {
                                subject: t("createNewUser:step3.emailOptions.subject"),
                                html: t("createNewUser:step3.emailOptions.html"),
                                text: t("createNewUser:step3.emailOptions.text")
                            }
                        }
                    }).then(({ errors }: any) => {

                        if (errors) {
                            const alreadExists = errors.some((e) => e.message.includes("AddUser.ALREADY_EXISTS"));

                            if (alreadExists) {
                                void Swal.fire({
                                    icon: "question",
                                    title: t("sharedPage:add.to.user.label"),
                                    showConfirmButton: true,
                                    confirmButtonText: t("sharedPage:try.to.add.yes"),
                                    showCancelButton: true,
                                    cancelButtonText: t("sharedPage:try.to.add.no"),
                                    confirmButtonColor: COLORS.GREEN,
                                    cancelButtonColor: COLORS.RED
                                }).then((result) => {
                                    if (result.isConfirmed) {

                                        void addExistingUserShared({
                                            variables: {
                                                email,
                                                serverID
                                            }
                                        }).then(({ errors }: any) => {
                                            if (errors) {
                                                for (const e of errors) {
                                                    toastError(t(`validations:${e.message}`));
                                                }

                                                setIsLoading(false);
                                                return;
                                            }

                                            toastSuccess(t("validations:user.added.success"));

                                            setIsLoading(false);
                                            setIsModalOpen(false);
                                            setEmail("");
                                            onClose();
                                        }).catch((error) => console.log(error));

                                    }
                                }).catch((error) => console.log(error));

                                return;
                            }

                            for (const e of errors) {
                                toastError(t(`validations:${e.message}`));
                            }

                            setIsLoading(false);
                            return;
                        }

                        setIsLoading(false);
                        setIsModalOpen(false);
                        setEmail("");
                        onClose();

                        toastSuccess(t("validations:user.added.success"));
                    }).catch((error) => console.log(error));
                } else {
                    addExitingUser(email, serverID, tenantID);
                    return;

                }
            }).catch((error) => console.log(error));

        } else {
            toastError(t(`validations:${validateEmail(email)}`));
        }
    };

    return (
        <Modal
                isOpen={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false);
                    onClose()
                }}
                style={{ width: "600px" }}
            >
            <div>
                <Title>
                    {t("profile:addUser.title")}
                </Title>

                <TextField
                        value={email}
                        autoFocus
                        style={{ marginTop: 20, width: "100%" }}
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        label={t("profile:email.label")}
                    />

                <ButtonListFlex>
                    <ConfirmButton onClick={onConfirm} disabled={isLoading || isEmpty(email)}>
                        {t("profile:addUser.button")}
                    </ConfirmButton>
                </ButtonListFlex>
            </div>
        </Modal>
    );
}

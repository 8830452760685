import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    position: absolute;
    bottom: 0px;
    right: 0px;
    margin: 6px;
    cursor: pointer;
    border: 1px solid #fff;
    height: 50px;

    &:hover {
        border: 1px solid #e5e5e5;
    }
`;

export const ContainerRight = styled.div`
    display: flex;
    margin: 6px;
    padding: 0 10px 0 10px;
    background-color: #fff;
    border: 1px solid #fff;
    height: 50px;
`;

/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/forbid-component-props */
import { toastClose, toastError, toastLoading, toastSuccess, toastUpdate } from "@3edges/utils/dist/toastify";
import { decryptText, isNotEmpty } from "@3edges/utils/dist/utils";
import { useLazyQuery, useMutation } from "@apollo/client";
import { MenuItem } from "@mui/material";
import { COLORS } from "assets/defaultVariables";
import { ButtonListFlex, ConfirmButton, TextAreaStyled } from "components/ModalDataServer/styled";
import PanelHeader from "components/PanelHeader";
import { SelectFieldStyled } from "components/RightPanel/styled";
import SidebarWrapper from "components/SidebarWrapper";
import { REACT_ENV } from "environmentVariables";
import { PageContainer } from "pages/Canvas/styled";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { CloudServiceAccounts } from "types/operation-result-types";
import { Modal } from "ui-components/modal";
import { AddDataServerIconStyled, TextBox } from "ui-components/PanelCard/styled";
import { PwdField } from "ui-components/pwdField";
import CardSA from "./CardSA";
import { ADD_SERVICE_ACCOUNT, COLLECT_CLUSTERS_BY_SERVICE_ACCOUNT, DELETE_SERVICE_ACCOUNT, EDIT_SERVICE_ACCOUNT, GET_SERVICE_ACCOUNTS } from "./gql";
import { Body, Card, FlexList, LoadingData, Title } from "./styled";


const cloudsAvailables = [
    { id: 1, context: CloudServiceAccounts.GCP, title: "Google" },
    { id: 2, context: CloudServiceAccounts.AWS, title: "Amazon" },
    // { id: 3, context: CloudServiceAccounts.AZURE, title: "Azure" }
]

function ServiceAccounts (): React.ReactElement {
    const { t } = useTranslation();
    const [open, setOpen] = useState(true);

    const [isDisabled, setIsDisabled] = useState(true);
    const [dataCollection, setDataCollection] = useState([]);

    const [collectClustersByServiceAccount, { data: dataCluster, loading: loadingClusters }] = useLazyQuery(COLLECT_CLUSTERS_BY_SERVICE_ACCOUNT, { fetchPolicy: "no-cache" });
    const [dataClusters, setDataClusters] = useState([]);

    const [cloudSelected, setCloudSelected] = useState("");
    const [serviceAccountID, setServiceAccountID] = useState("");
    const [serviceAccountGCP, setServiceAccountGCP] = useState("");
    const [serviceAccountAWSClientID, setServiceAccountAWSClientID] = useState("");
    const [serviceAccountAWSClientSecret, setServiceAccountAWSClientSecret] = useState("");

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [getServiceAccounts, { data, loading }] = useLazyQuery(GET_SERVICE_ACCOUNTS, { fetchPolicy: "no-cache" });
    const [addServiceAccount] = useMutation(ADD_SERVICE_ACCOUNT);
    const [editServiceAccount] = useMutation(EDIT_SERVICE_ACCOUNT);
    const [deleteServiceAccount] = useMutation(DELETE_SERVICE_ACCOUNT);

    useEffect(() => {
        getServiceAccounts()
    }, [])

    useEffect(() => {
        if (isModalOpen && serviceAccountID) {
            onRefresh()
        }
    }, [isModalOpen])

    useEffect(() => {
        if (dataCluster && dataCluster.collectClustersByServiceAccount.length > 0) {
            setDataClusters(dataCluster.collectClustersByServiceAccount)
        } else {
            setDataClusters([])
        }
    }, [dataCluster]);

    useEffect(() => {
        if (!loading && data) {
            setDataCollection(data.getServiceAccounts)
        }
    }, [data, loading])

    useEffect(() => {
        setIsDisabled(true)

        if (isNotEmpty(cloudSelected) && (isNotEmpty(serviceAccountGCP) || (isNotEmpty(serviceAccountAWSClientID) && isNotEmpty(serviceAccountAWSClientSecret)))) {
            setIsDisabled(false)
        }
    }, [cloudSelected, serviceAccountGCP, serviceAccountAWSClientID, serviceAccountAWSClientSecret])

    const handleDrawerOpen = (): void => {
        setOpen(true);
    };

    const handleDrawerClose = (): void => {
        setOpen(false);
    };

    const onDelete = (ID: any): void =>
    {
        void Swal.fire({
            icon: "error",
            title: t("serviceaccounts:try.to.delete"),
            showConfirmButton: true,
            confirmButtonText: t("serviceaccounts:try.to.delete.yes"),
            showCancelButton: true,
            cancelButtonText: t("serviceaccounts:try.to.delete.no"),
            confirmButtonColor: COLORS.GREEN,
            cancelButtonColor: COLORS.RED
        }).then((result) => {
            if (result.isConfirmed) {
                const toastID = toastLoading()

                void deleteServiceAccount({
                    variables: {
                        serviceAccountID: ID
                    }
                }).then(({ errors }: any) => {
                    toastClose(toastID)

                    if (errors) {
                        for (const e of errors) {
                            toastError(t(`validations:${e.message}`));
                        }
                        return;
                    }

                    toastSuccess(t("serviceaccounts:message.after.deleted"));
                    setDataCollection(dataCollection.filter(i => i._id !== ID))
                }).catch((error) => console.log(error));
            }
        }).catch((error) => console.log(error));
    }

    const onSelect = (value: any): void => {
        value.serviceAccountGCP = decryptText(value.serviceAccountGCP, REACT_ENV.REACT_APP_INTERNAL_SECRET)
        value.serviceAccountAWSClientID = decryptText(value.serviceAccountAWSClientID, REACT_ENV.REACT_APP_INTERNAL_SECRET)
        value.serviceAccountAWSClientSecret = decryptText(value.serviceAccountAWSClientSecret, REACT_ENV.REACT_APP_INTERNAL_SECRET)

        setServiceAccountID(value._id)
        setCloudSelected(value.cloud)

        switch (value.cloud) {
            case CloudServiceAccounts.AWS: {
                setServiceAccountAWSClientID(value.serviceAccountAWSClientID)
                setServiceAccountAWSClientSecret(value.serviceAccountAWSClientSecret)
                break;
            }
            case CloudServiceAccounts.GCP: {
                setServiceAccountGCP(value.serviceAccountGCP)
                break;
            }
        }
    }

    const onRefresh = (): void => {
        collectClustersByServiceAccount({ variables: { serviceAccountID } })
    }

    const onConfirm = (): void => {
        const toastID = toastLoading()

        if (isNotEmpty(serviceAccountID)) {
            void editServiceAccount({
                variables: {
                    serviceAccountID,
                    cloud: cloudSelected,
                    serviceAccountGCP: serviceAccountGCP,
                    serviceAccountAWSClientID: serviceAccountAWSClientID,
                    serviceAccountAWSClientSecret: serviceAccountAWSClientSecret,
                }
            }).then(({ data, errors }: any) => {
                if (errors) {
                    toastClose(toastID)

                    for (const e of errors) {
                        toastError(t(`validations:${e.message}`));
                    }
                    return;
                }

                // Overwrite the existent
                const itemsChanged = dataCollection.map((d) => {
                    if (d._id === data.editServiceAccount._id) {
                        d = data.editServiceAccount
                    }

                    return d
                })

                toastUpdate(toastID, t("validations:success.done"), "success")
                setDataCollection([...itemsChanged])
            }).catch((error) => console.log(error));
        } else {
            void addServiceAccount({
                variables: {
                    cloud: cloudSelected,
                    serviceAccountGCP: serviceAccountGCP,
                    serviceAccountAWSClientID: serviceAccountAWSClientID,
                    serviceAccountAWSClientSecret: serviceAccountAWSClientSecret,
                }
            }).then(({ data, errors }: any) => {
                if (errors) {
                    toastClose(toastID)

                    for (const e of errors) {
                        toastError(t(`validations:${e.message}`));
                    }
                    return;
                }

                toastUpdate(toastID, t("validations:success.done"), "success")
                setDataCollection([data.addServiceAccount, ...dataCollection])
            }).catch((error) => console.log(error));
        }

        onClose()
    }

    const onClose = (): void => {
        setServiceAccountID("")
        setCloudSelected("")
        setServiceAccountGCP("")
        setServiceAccountAWSClientID("")
        setServiceAccountAWSClientSecret("")

        setIsModalOpen(false)
    }

    return (
        <SidebarWrapper open={open} handleDrawerClose={handleDrawerClose}>
            <PageContainer>
                <PanelHeader open={open} handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen} />

                <Body>
                    <Title>
                        {t("panel:header.service.accounts")}
                    </Title>

                    <FlexList>
                        <Card $isAdd onClick={() => setIsModalOpen(true)}>
                            <AddDataServerIconStyled $smallCard={false} />

                            <TextBox $isAdd $smallCard={false} style={{ fontWeight: "bold" }}>
                                {t("serviceaccounts:List.addNew.title")}
                            </TextBox>
                        </Card>

                        {loading && (
                            <LoadingData>
                                <div>Loading data</div>
                                <div className="loader" />
                            </LoadingData>
                        )}

                        {!loading && dataCollection.length > 0 && dataCollection.map((sa) => (
                            <CardSA
                                key={`card-service-account-key-${sa._id}`}
                                sa={sa}
                                onDelete={onDelete}
                                onSelect={onSelect}
                                setIsModalOpen={setIsModalOpen}
                            />
                        ))}
                    </FlexList>

                    <Modal
                        isOpen={isModalOpen}
                        onClose={() => {
                            onClose()
                        }}
                        style={{ width: "600px" }}
                    >
                        <>
                            <div>
                                <Title>
                                    {t("serviceaccounts:Modal.addNew.title")}
                                </Title>

                                <SelectFieldStyled
                                    value={cloudSelected}
                                    label={t("serviceaccounts:Modal.cloudType")}
                                    style={{ width: '100%' }}
                                    onChange={(e) => {
                                        setCloudSelected(e.target.value)

                                        switch (e.target.value) {
                                            case CloudServiceAccounts.AWS: {
                                                setServiceAccountGCP("")
                                                break;
                                            }
                                            case CloudServiceAccounts.GCP: {
                                                setServiceAccountAWSClientID("")
                                                setServiceAccountAWSClientSecret("")
                                                break;
                                            }
                                        }
                                    }}>
                                    {cloudsAvailables.map(({ context, title }) => (
                                        <MenuItem key={context} value={context}>
                                            {title}
                                        </MenuItem>
                                    ))}
                                </SelectFieldStyled>

                                {cloudSelected === CloudServiceAccounts.AWS && (
                                    <>
                                        <PwdField
                                            style={{ marginTop: 20, marginBottom: 20, width: "100%" }}
                                            label={t("serviceaccounts:Modal.aws.client_id.label")}
                                            value={serviceAccountAWSClientID}
                                            onChange={(e) => {
                                                setServiceAccountAWSClientID(e.target.value);
                                            }}
                                        />

                                        <PwdField
                                            label={t("serviceaccounts:Modal.aws.client_secret.label")}
                                            value={serviceAccountAWSClientSecret}
                                            onChange={(e) => {
                                                setServiceAccountAWSClientSecret(e.target.value);
                                            }}
                                        />
                                    </>
                                )}

                                {cloudSelected === CloudServiceAccounts.GCP && (
                                    <TextAreaStyled
                                        label={t("serviceaccounts:Modal.gcp.label")}
                                        style={{ width: '100%' }}
                                        value={serviceAccountGCP}
                                        maxRows={10}
                                        onChange={(e) => {
                                            setServiceAccountGCP(e.target.value);
                                        }}
                                    />
                                )}

                                <ButtonListFlex>
                                    <ConfirmButton onClick={onConfirm} disabled={isDisabled}>
                                        {t("serviceaccounts:Modal.addButton")}
                                    </ConfirmButton>
                                </ButtonListFlex>
                            </div>

                            {serviceAccountID && (
                                <div>
                                    {loadingClusters ? "Loading..." : (
                                        <>
                                            <table style={{ border: '1px solid', marginTop: 50, padding: 10, width: "100%" }}>
                                                <thead>
                                                    <tr>
                                                        <th align="left"><u>Name</u></th>
                                                        <th align="left"><u>Location</u></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {dataClusters.map((apiInfo, i) => {
                                                        return (
                                                            <tr key={`apiInfo-${i}`}>
                                                                <td>
                                                                    {apiInfo.name}
                                                                </td>
                                                                <td>
                                                                    {apiInfo.location}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>

                                            <ButtonListFlex>
                                                <ConfirmButton onClick={onRefresh}>
                                                    {t("serviceaccounts:Modal.refreshButton")}
                                                </ConfirmButton>
                                            </ButtonListFlex>
                                        </>
                                    )}
                                </div>
                            )}
                        </>
                    </Modal>
                </Body>

            </PageContainer>
        </SidebarWrapper>
    );
}

export default ServiceAccounts;

import { iD3Data, iData, NodeTypename } from "../types";

interface DeleteD3ObjectParams {
    d3Data: iD3Data;
    objectId: string;
    data: iData;
    setData: React.Dispatch<React.SetStateAction<iData>>;
}

export const deleteD3Object = ({ d3Data, objectId, data, setData }: DeleteD3ObjectParams): iData => {
    const nodeRelationship: { x: number; y: number; __typename: string; _id: string; displayName: string }[] = [];

    let newDataObjects = data.objects.filter((object) => object._id !== objectId);

    // When you remove an object, which has a relationship with another object
    newDataObjects = newDataObjects.map((s) => {
        s.fields = s.fields.filter(obj => obj.relatedTo !== objectId)
        return s;
    })

    // Remove the blue line (relation)
    let newDataRels = data.relLinks.filter((rl) => {
        if (rl.source._id === objectId || rl.target._id === objectId) {

            // When you remove an orbit, which has a relationship with the nodeRelationship orbit
            if (rl.source.__typename === NodeTypename.NIAM_NODE_RELATIONSHIP || rl.target.__typename === NodeTypename.NIAM_NODE_RELATIONSHIP) {
                nodeRelationship.push(rl.source.__typename === NodeTypename.NIAM_NODE_RELATIONSHIP ? rl.source : rl.target);
            }

            return false;
        }

        return true;
    });

    // When you remove an orbit, which has an implementation with the interface orbit
    const newDataImplements = data.implementLinks.filter((il) => {
        if (il.source._id === objectId || il.target._id === objectId) {
            return false;
        }
        return true;
    });

    // When you remove an orbit, which has a relationship with the nodeRelationship orbit
    const newDataNodeRelationships = data.nodeRelationships.filter((nr) => {
        return nodeRelationship.some((f) => nr._id === f._id) ? null : nr
    });

    // Remove connections between nodeRelationship orbit
    newDataRels = newDataRels.filter((rl) => {
        return nodeRelationship.some((f) => rl.source._id === f._id || rl.target._id === f._id) ? null : rl
    });

    // Remove nodeRelationship from removed object
    newDataObjects = newDataObjects.map((obj) => {
        obj.nodeRelationships = obj.nodeRelationships.filter((onr) => {
            return nodeRelationship.filter((f) => onr.rel?._id === f._id) ? null : onr
        })

        return obj
    });

    // When you remove an object, which has a relationship with the script orbit
    const newDataScripts = data.scripts.filter((s) => {
        s.objects = s.objects.filter(obj => obj._id !== objectId)
        s.relationships = s.relationships.filter(obj => obj.relatedTo !== objectId)

        if (nodeRelationship.length > 0) {
            s.relationships = s.relationships.filter(rel => {
                return nodeRelationship.some((f) => rel.relatedTo === f._id) ? null : rel
            })
        }

        return s;
    })

    const newData = {
        ...data,
        objects: newDataObjects,
        scripts: newDataScripts,
        implementLinks: newDataImplements,
        nodeRelationships: newDataNodeRelationships,
        relLinks: newDataRels
    };

    setData(newData);
    return newData;
};

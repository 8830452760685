import { updateSimulation } from "components/PrimGraphicalCanvas/simulation";
import { iD3Data, iD3Selection, iData, NodeTypename, OrbitItem } from "components/PrimGraphicalCanvas/types";
import { getShown } from "components/PrimGraphicalCanvas/utils";
import { addNewNodeRelationship_addNewNodeRelationship as NewNodeRelationship } from "types/operation-result-types";

const updateFilter = (
    d3Data: iD3Data,
    data: iData | Partial<iData & NewNodeRelationship>,
    values: {
        k: string;
        v: boolean;
    }[],
    enters: {
        enterOrbit: (selection: iD3Selection) => void;
        updateOrbit: (selection: iD3Selection) => void;
        enterLink: (selection: iD3Selection) => void;
        updateLink: (selection: iD3Selection) => void;
    },
    setData: React.Dispatch<React.SetStateAction<iData>>,
// eslint-disable-next-line max-params
): void => {

    const objects = values.find((f) => f.k === "Object");
    const interfaces = values.find((f) => f.k === "Interface");
    const scripts = values.find((f) => f.k === "Script");
    const customMutations = values.find((f) => f.k === "Custom Mutation");
    const tags = values.find((f) => f.k === "Tag");
    const nodeRelationships = values.find((f) => f.k === "Node Relationship");
    const enums = values.find((f) => f.k === "Enum");

    const newDataPaths = data.paths.filter((p) => {
        p.isShown = true;
        return p;
    });

    const newDataImplements = data.implementLinks.filter((il) => {
        il.isShown = true;

        if (!objects.v && (il.source.__typename === NodeTypename.NIAM_OBJECT || il.target.__typename === NodeTypename.NIAM_OBJECT)) {
            il.isShown = false;
        }

        if (!interfaces.v && (il.source.__typename === NodeTypename.NIAM_INTERFACE || il.target.__typename === NodeTypename.NIAM_INTERFACE)) {
            il.isShown = false;
        }
        return il;
    });

    const newDataRels = data.relLinks.filter((rl) => {
        rl.isShown = true;

        if (!objects.v && (rl.source.__typename === NodeTypename.NIAM_OBJECT || rl.target.__typename === NodeTypename.NIAM_OBJECT)) {
            rl.isShown = false;
        }

        if (!interfaces.v && (rl.source.__typename === NodeTypename.NIAM_INTERFACE || rl.target.__typename === NodeTypename.NIAM_INTERFACE)) {
            rl.isShown = false;
        }

        if (!scripts.v && (rl.source.__typename === NodeTypename.NIAM_SCRIPT || rl.target.__typename === NodeTypename.NIAM_SCRIPT)) {
            rl.isShown = false;
        }

        if (!tags.v && (rl.source.__typename === NodeTypename.NIAM_TAG || rl.target.__typename === NodeTypename.NIAM_TAG)) {
            rl.isShown = false;
        }

        if (
            !nodeRelationships.v &&
            (rl.source.__typename === NodeTypename.NIAM_NODE_RELATIONSHIP || rl.target.__typename === NodeTypename.NIAM_NODE_RELATIONSHIP)
        ) {
            rl.isShown = false;
        }
        if (!enums.v && (rl.source.__typename === NodeTypename.NIAM_ENUM || rl.target.__typename === NodeTypename.NIAM_ENUM)) {
            rl.isShown = false;
        }

        return rl;
    });

    const newDataObjects = data.objects.filter((o) => {
        o.isShown = objects.v;
        return o;
    });

    const newDataInterfaces = data.interfaces.filter((i) => {
        i.isShown = interfaces.v;
        return i;
    });

    const newDataScripts = data.scripts.filter((s) => {
        s.isShown = scripts.v;
        return s;
    });

    const newCustomMutations = data.customMutations.filter((cm) => {
        cm.isShown = customMutations.v;
        return cm;
    });

    const newTags = data.tags.filter((t) => {
        t.isShown = tags.v;
        return t;
    });

    const newNodeRelationships = data.nodeRelationships.filter((nr) => {
        nr.isShown = nodeRelationships.v;
        return nr;
    });

    const newEnums = data.enums.filter((e) => {
        e.isShown = enums.v;
        return e;
    });

    const newData: iData = {
        ...data,
        objects: newDataObjects,
        interfaces: newDataInterfaces,
        scripts: newDataScripts,
        customMutations: newCustomMutations,
        tags: newTags,
        nodeRelationships: newNodeRelationships,
        implementLinks: newDataImplements,
        paths: newDataPaths,
        relLinks: newDataRels,
        enums: newEnums
    };

    const orbitShown = getShown([
        ...newDataObjects,
        ...newDataInterfaces,
        ...newDataScripts,
        ...newCustomMutations,
        ...newTags,
        ...newNodeRelationships,
        ...newEnums
    ]);

    const linkShown = getShown([...newDataRels, ...newDataImplements, ...newDataPaths]).reverse();

    d3Data.g
        .selectAll(".orbit")
        .data(orbitShown, (node: OrbitItem) => node._id)
        .exit()
        .remove();

    d3Data.g
        .selectAll(".orbit")
        .data(orbitShown, (node: OrbitItem) => node._id)
        .enter()
        .append("g")
        .call(enters.enterOrbit)
        .on("click", d3Data.zoomOnNodesInstance);

    d3Data.g
        .selectAll(".link")
        .data(linkShown, (node: OrbitItem) => node._id)
        .exit()
        .remove();

    d3Data.g
        .selectAll(".link")
        .data(linkShown, (node: OrbitItem) => node._id)
        .enter()
        .append("g")
        .call(enters.enterLink)
        .on("click", d3Data.zoomOnLinksInstance);

    updateSimulation(d3Data.simulation, orbitShown, linkShown);

    setData(newData);
};

export default updateFilter;

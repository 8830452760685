import styled from "styled-components";

export const Body = styled.div`
    width: 100vw;
    padding: 48px 213px 80px 120px;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.colors.background};
    flex: 0;
    display: flex;
    flex-direction: column;
`;

export const Description = styled.strong<{ $small?: boolean }>`
    font-size: ${({ $small }) => ($small ? "12px" : "16px")};
`;

export const Title = styled.header`
    width: 164px;
    height: 37px;
    font-family: IBM Plex Sans;
    font-size: 28px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    white-space: nowrap;

    a {
        text-decoration: none;
        color: rgba(0, 0, 0, 0.87);

        :hover {
            color: purple;
        }
    }
`;

export const LoadingData = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    margin-top: 10px;
    border-radius: 20px;
    border: 1px solid #F5F5F5;
    flex-direction: column;
`

export const Card = styled.div<{ $isAdd?: boolean; $smallCard?: boolean }>`
    cursor: pointer;
    width: ${({ $smallCard }) => ($smallCard ? "100px" : "300px")};
    height: ${({ $smallCard }) => ($smallCard ? "90px" : "236px")};
    margin: ${({ $smallCard }) => ($smallCard ? "5px 7px 0 0" : "10px 10px 0 0")};
    padding: ${({ $smallCard, $isAdd }) => ($smallCard ? "5px 5px" : $isAdd ? "45px 0": "34px 46px 40px")};
    box-sizing: border-box;
    flex-direction: column;
    display: flex;
    border-radius: ${({ theme }) => theme.sizes.normalBorderRadius};
    background-color: ${({ theme, $isAdd }) => ($isAdd ? " rgba(0, 136, 255, 0.2)" : theme.colors.white)};
    justify-content: start;
    align-items: center;

    ${(props) => (props.$isAdd
            ? ""
            : `
    :hover path {
        fill: #1e88e5 !important;
    }
    :hover span {
        color: #1e88e5 !important;
    }
  `)}
    z-index: 1;
`;

export const FlexList = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 40px 0px;
    margin: 0px -15px 0px 0px;
    box-sizing: border-box;
`;

export const StatusLightIconStyled = styled.div<{ $isActive?: boolean }>`
    & div {
        position: absolute;
        top: 25px !important;
        left: 20px !important;
        z-index: 2;
        height: 15px;
        width: 15px;
    }
`;

import { iD3Data, iData } from "../types";

interface DeleteD3RelationshipParams {
    d3Data: iD3Data;
    relId: string;
    data: iData;
    setData: React.Dispatch<React.SetStateAction<iData>>;
}

export const deleteD3Relationship = ({ d3Data, relId, data, setData }: DeleteD3RelationshipParams): iData => {
    const newDataRels = data.relLinks.filter((rl) => rl._id !== relId);

    const newDataObjects = data.objects.map((item) => {
        item.fields = item.fields.filter((f) => f._id !== relId);
        return item;
    });

    const newData = {
        ...data,
        objects: newDataObjects,
        relLinks: newDataRels
    };

    setData(newData);
    return newData;
};

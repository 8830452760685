import { Button, ButtonProps, Tooltip } from "@mui/material";
import { withStyles } from "@mui/styles";
import styled from "styled-components";

export const ActionButton = styled(Button).attrs((prop) => ({
    color: prop.color
        ? prop.color
        : "primary",
    variant: "contained"
} as ButtonProps))<{ $show: boolean }>`
    && {
        margin-right: 10px;
        padding: 10px 7px;
        box-sizing: border-box;
        border-radius: 4px;
        font-family: IBM Plex Sans;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: center;
        visibility:${({ $show }) => ($show
        ? "visible"
        : "hidden")};
                opacity:${({ $show }) => ($show
        ? "1"
        : "0")};
        transition: 0.3s linear all;
        position: relative;
        text-align: center;
        *{
            cursor: pointer;
        }

    }
`;

export const StyledInput = styled.input`
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0;
`;

export const StyledTooltip = withStyles({
    tooltip: {
        fontSize: "14px"
    }
})(Tooltip);

import { TablePagination } from "@mui/material";
import styled from "styled-components";
export const StyledTablePagination = styled(TablePagination)`
    border-bottom: none !important;

    .MuiToolbar-gutters {
        padding-left: 0px;
        flex-wrap: wrap;
    }
`;

export const ActionsContainer = styled.div``;

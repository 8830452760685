import { iD3Data, iData } from "../types";

interface DeleteD3FieldParams {
    d3Data: iD3Data;
    fieldId: string;
    data: iData;
    setData: React.Dispatch<React.SetStateAction<iData>>;
}

export const deleteD3Field = ({ d3Data, fieldId, data, setData }: DeleteD3FieldParams): iData => {
    let newDataObjects = [...data.objects]
    let newDataInterfaces = [...data.interfaces]
    let newDataNodeRelationships = [...data.nodeRelationships]
    const newDataRels = [...data.relLinks].filter((rl) => rl._id !== fieldId);

    newDataObjects = newDataObjects.map(item => {
        item.fields = item.fields.filter(f => f._id !== fieldId)
        return item
    });

    newDataInterfaces = newDataInterfaces.map(item => {
        item.fields = item.fields.filter(f => f._id !== fieldId)
        return item
    });

    newDataNodeRelationships = newDataNodeRelationships.map(item => {
        item.fields = item.fields.filter(f => f._id !== fieldId)
        return item
    });

    const newData = {
        ...data,
        objects: newDataObjects,
        interfaces: newDataInterfaces,
        nodeRelationships: newDataNodeRelationships,
        relLinks: newDataRels
    };

    setData(newData);
    return newData;
};

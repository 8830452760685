import { iD3Data, iData } from "../types";

interface DeleteD3PathsParams {
    d3Data: iD3Data;
    pathId: string;
    data: iData;
    setData: React.Dispatch<React.SetStateAction<iData>>;
}

export const deleteD3Paths = ({ d3Data, pathId, data, setData }: DeleteD3PathsParams): iData => {
    const newDataPaths = data.paths.filter((il) => {
        if (il._id === pathId) {
            return false;
        }

        return true;
    });

    const newDataObjects = data.objects.filter((object) => {
        object.paths = object.paths.filter((p) => {
            if (p._id === pathId) {
                return false;
            }

            return true;
        });

        return object;
    });

    const newData = {
        ...data,
        objects: newDataObjects,
        paths: newDataPaths
    };

    setData(newData);
    return newData;
};

/* eslint-disable react/forbid-component-props */
import { delStorage } from "cache";
import PanelHeader from "components/PanelHeader";
import PanelTenant from "components/PanelTenant";
import { useData } from "contexts/dataContext";
import { PageContainer } from "pages/Canvas/styled";
import React, { useEffect } from "react";
import PanelEclipse from "ui-components/PanelEclipse";
import SidebarWrapper from "components/SidebarWrapper";

function Tenants(): React.ReactElement {
    const { setTenant, setServer, tenant } = useData()

    const [open, setOpen] = React.useState(true);

    const handleDrawerOpen = (): void => {
        setOpen(true);
    };

    const handleDrawerClose = (): void => {
        setOpen(false);
    };

    useEffect(() => {
        setTenant(null);
        setServer(null);
    }, []);

    return (
        <SidebarWrapper open={open} handleDrawerClose={handleDrawerClose}>
            <PageContainer>
                <PanelHeader open={open} handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen} />

                <PanelTenant />

                <PanelEclipse />
            </PageContainer>
        </SidebarWrapper>
    );
}
export default Tenants;

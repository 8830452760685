// @flow
import { MenuOptionsType } from "components/PrimGraphicalCanvas/types";
import React, { useMemo, useState } from "react";
import { RightClickMenu } from "ui-components/rightClickMenu";

export interface IMenuOption {
    title: string;
    image: string;
    type: MenuOptionsType,
    onClick?: (() => void) | ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void);
    nestedMenuOptions?: IMenuOption[];
    key: string;
}

export interface iContextMenuState {
    x: number;
    y: number;
    visibility: boolean;
    menuOptions: IMenuOption[];
    title: string;
}
export interface iContextMenuStateInput {
    x?: number;
    y?: number;
    visibility?: boolean;
    menuOptions?: IMenuOption[];
    title?: string;
}

export interface ContextMenuContextProps {
    setContextMenuState: (contextMenuProps: iContextMenuStateInput) => void;
}

export const ContextMenuContext = React.createContext<ContextMenuContextProps>({
    setContextMenuState: (contextMenuProps: iContextMenuStateInput) => {}
});

export function ContextMenuProvider({ children }: { children: React.ReactNode }): React.ReactElement {
    const [contextMenuState, setContextMenuState] = useState<iContextMenuState>({
        menuOptions: [],
        title: "",
        visibility: false,
        x: 0,
        y: 0
    });

    const value = useMemo(
        () => ({
            setContextMenuState: (contextMenuStateInput: iContextMenuState) => {
                setContextMenuState({
                    ...contextMenuState,
                    ...contextMenuStateInput
                });
            }
        }),
        [contextMenuState]
    );

    return (
        <ContextMenuContext.Provider value={value}>
            {contextMenuState.visibility && (
                <RightClickMenu
                    title={contextMenuState.title}
                    menuOptions={contextMenuState.menuOptions}
                    x={contextMenuState.x}
                    y={contextMenuState.y}
                    visibility={contextMenuState.visibility}
                />
            )}

            {children}
        </ContextMenuContext.Provider>
    );
}

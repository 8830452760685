import styled from "styled-components";
import { COLORS } from "assets/defaultVariables";

export const FormStyled = styled.form`
    flex: 1;
    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: column;
    padding: 60px 90px;
`;
export const InputWrapperStyled = styled.div`
    flex: 0;
    align-self: stretch;
    margin: 15px 0px;
    display: flex;
    flex-direction: column;
`;
export const InputButtonWrapperStyled = styled.div`
    flex: 0;
    align-self: stretch;
    margin: 15px 0px;
    display: flex;
    flex-direction: row-reverse;
`;
export const InputStyled = styled.input`
    border-radius: 4px;
    min-height: 38px;
    max-height: 38px;
    border: 1px solid ${COLORS.GREY};
    width: 100%;
    padding: 0px 15px;
    box-sizing: border-box;
`;
export const InputLabelStyled = styled.label`
    font-size: 17px;
    padding: 0px 5px 5px;
`;

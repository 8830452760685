import { gql } from "@apollo/client";

export const SAVE_ORBIT_POSITION = gql`
  mutation saveOrbitPosition($orbitID: ID!, $x: Float!, $y: Float!) {
    saveOrbitPosition(input: { orbitID: $orbitID, x: $x, y: $y })
  }
`;

export const SAVE_ORBIT_COLLECTION_POSITIONS = gql`
  mutation saveOrbitCollectionPositions($orbitsCollection: [saveOrbitPositionInput]!) {
    saveOrbitCollectionPositions(input: { orbitsCollection: $orbitsCollection })
  }
`;

import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "ui-components/modal";
import { actionType } from "../ActionButtons/constants";
import { ConfirmButton, Container } from "./styled";

interface IModalChangeRole {
    isModalRoleOpen: boolean;
    onClose: () => void;
    handleClickOption: () => void;
    action?: actionType;
}

export default function ModalChangeRole ({
    isModalRoleOpen,
    onClose,
    handleClickOption,
    action
}: IModalChangeRole): ReactElement {
    const { t } = useTranslation();

    const descriptions = {
        delete: t("adminPage:deleteAccount.question"),
        remove: t("adminPage:removeFromTenant.question"),
        default: t("adminPage:changeRole.question")
    };

    return (
        <Modal isOpen={isModalRoleOpen} onClose={onClose} style={{ width: "600px" }}>
            <Container>
                <div className="description">
                    {descriptions[action] || descriptions.default}
                </div>

                <div className="buttons">
                    <ConfirmButton
                        data-cy="modalChangeRolePage_btnYes"
                        color="rgb(40, 167, 69)"
                        onClick={() => {
                            handleClickOption();
                        }}
                    >
                        {t("adminPage:confirm.yes")}
                    </ConfirmButton>

                    <ConfirmButton
                        color="rgb(221, 51, 51)"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        {t("adminPage:confirm.no")}
                    </ConfirmButton>
                </div>
            </Container>
        </Modal>
    );
}

import * as d3 from "d3";
import { ApplyZoomReturnType } from "./types";

export const applyZoom = ({
    setZoom,
    width,
    height,
    g,
    container,
    zoomOnNodes,
    zoomOnLinks,
    contextMenuState,
}: any): ApplyZoomReturnType =>
{
    const zoomInstance = d3.zoom()
        .scaleExtent([0.1, 3])
        .on("start", () => {
            // mousedown
            (container as any).attr("cursor", "move");
            container.dispatch("mousedown.custom", { bubbles: true, cancelable: true });
        })
        .on("zoom", (event) => {
            // mousemove
            contextMenuState.setContextMenuState({
                visibility: false
            });

            setZoom(event.transform);
            g.attr("transform", event.transform);
        })
        .on("end", (event) => {
            setZoom(event.transform);

            // mouseup
            (container as any).attr("cursor", "default");
            container.dispatch("mouseup.custom", { bubbles: true, cancelable: true });

            // Keep Zoom updated with new positions
            container.call(
                d3.zoom().transform,
                d3.zoomIdentity.translate(event.transform.x, event.transform.y).scale(event.transform.k)
            );
        });

    container.call(zoomInstance)

    const zoomOnNodesInstance = zoomOnNodes({
        container,
        height,
        width,
        zoomInstance
    });

    const zoomOnLinksInstance = zoomOnLinks({
        container,
        height,
        width,
        zoomInstance
    });

    return {
        zoomOnNodesInstance,
        zoomOnLinksInstance
    };
};

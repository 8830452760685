import styled from "styled-components";

export const StatusLightBulb = styled.div<{ $serviceStatus:number }>`
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: ${(props) => (props.$serviceStatus === 0 ? "#913064" : props.$serviceStatus === 1 ? "#8bc34a" : "#FFAC1C") };
    margin-right: 16px;
`;

export const ServerUpdatePanelFlexContainer = styled.div`
    box-sizing: border-box;
    padding: 24px 0px;
    display: flex;
    flex-direction: column;
`;
export const ItemFlexContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
export const URLBox = styled.p`
    margin: 0;
    height: 20px;
    font-family: IBM Plex Sans;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    vertical-align: baseline;
    box-sizing: border-box;
    color: ${({ theme }) => theme.colors.fontNormal};
`;

export const RowContainer = styled.div`
    flex: 0;
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding: 9px 0;
    box-sizing: border-box;
    align-items: center;
`;

export const InfoCell = styled.div`
    width: 130px;
    height: 18px;
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: ${({ theme }) => theme.colors.fontLight};
`;
export const ValueCell = styled.div<{ $isActive?: boolean }>`
    cursor: ${(props) => (props.$isActive ? "pointer" : "text")};
    height: 18px;
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: ${({ theme, $isActive }) => ($isActive ? "#8bc34a" : theme.colors.fontNormal)};
    text-decoration: ${(props) => (props.$isActive ? "underline" : "none")};
`;

export const BR = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.borderLight};
`;

export const GrowingEmptyBox = styled.div`
    flex: 1;
`;

export const ButtonListFlex = styled.div`
    margin: 32px 0 0;
    display: flex;
    flex-direction: row-reverse;
    height: 38px;
    justify-content: start;
    align-items: stretch;
`;

/* eslint-disable no-empty */
import { setCurveFields, setCurveMap } from "utils";
import { NewDataItem } from "../addD3Items/types";
import { updateSimulation } from "../simulation";
import
    {
        EUpdateType,
        NiamImplementElement,
        NiamPathElement, NodeRelationship, NodeTypename, RelationshipType,
        iD3Data,
        iD3Selection,
        iData
    } from "../types";
import { getShown } from "../utils";

interface UpdateD3OrbitParams {
    d3Data: iD3Data;
    orbitID: string;
    data: iData;
    setData: React.Dispatch<React.SetStateAction<iData>>;
    newData: NewDataItem;
    type: EUpdateType;
    rPData: any,
    enters: {
        enterOrbit: (selection: iD3Selection) => void;
        updateOrbit: (selection: iD3Selection) => void;
        enterLink: (selection: iD3Selection) => void;
        updateLink: (selection: iD3Selection) => void;
    };
}

export const updateD3Orbit = ({ rPData, d3Data, orbitID, data, setData, newData, type, enters }: UpdateD3OrbitParams): void => {

    let newDataObjects = data.objects.map((o) =>
    {
        if (o._id === orbitID) {
            return {
                ...o,
                ...newData
            } as typeof o;
        }

        if (o._id === rPData.parent?._id && newData["__typename"] === "NiamField") {
            const nf = o.fields.find(f => f._id === newData._id)

            if (!nf) {
                 o.fields.push({ ...newData } as unknown as any)
            }

            o.fields = o.fields.map(f => {
                if (f._id === newData._id) {
                    return {
                        ...f,
                        ...newData
                    } as typeof f;
                }

                return f
            })
        }

        return o;
    });

    let newDataScripts = data.scripts.map((s) => {
        if (s._id === orbitID) {
            return {
                ...s,
                ...newData
            } as typeof s;
        }
        return s;
    });

    const newDataCustomMutations = data.customMutations.map((cm) => {
        if (cm._id === orbitID) {
            return {
                ...cm,
                ...newData
            } as typeof cm;
        }
        return cm;
    });

    const newTags = data.tags.map((tg) => {
        if (tg._id === orbitID) {
            return {
                ...tg,
                ...newData
            };
        }
        return tg;
    });

    const newEnums = data.enums.map((e) => {
        if (e._id === orbitID) {
            return {
                ...e,
                ...newData
            };
        }
        return e;
    });

    let newDataRels = data.relLinks
        .map((rl) => {
            if (rl.source._id === orbitID || rl.target._id === orbitID) {
                return {
                    ...rl,
                    source: rl.source._id,
                    target: rl.target._id
                } as unknown as NiamImplementElement;
            }
            return rl;
        });

    const newNodeRelationships = data.nodeRelationships.map((nr) => {
        if (nr._id === orbitID) {
            // Remove from old objFrom, relFrom, relTo
            const objFromID = nr.relation?.objFrom?._id;
            const oldRelFrom = nr.relation?.relFrom?._id;
            const oldRelTo = nr.relation?.relTo?._id;

            newDataObjects = newDataObjects.map((dObj) => {
                if (dObj._id === objFromID) {
                    const nodeRelationships: NodeRelationship[] = [];

                    dObj.nodeRelationships.forEach((f: NodeRelationship) => {
                        if (f.rel) {
                            if (f.rel._id !== orbitID) {
                                nodeRelationships.push(f);
                            }
                        } else if (f._id !== orbitID) {
                            nodeRelationships.push(f);
                        }
                    });

                    dObj.nodeRelationships = nodeRelationships;
                }

                return dObj;
            });

            newDataRels = newDataRels.filter((rl) => {
                if (rl._id !== oldRelFrom && rl._id !== oldRelTo) {
                    return rl;
                }

                return false;
            });

            return {
                ...nr,
                ...newData,
            } as typeof nr;
        }

        if (nr._id === rPData.parent?._id && newData["__typename"] === "NiamField") {
            const nf = nr.fields.find(f => f._id === newData._id)

            if (!nf) {
                 nr.fields.push({ ...newData } as unknown as any)
            }

            nr.fields = nr.fields.map(f => {
                if (f._id === newData._id) {
                    return {
                        ...f,
                        ...newData
                    } as typeof f;
                }

                return f
            })
        }

        return nr;
    });

    const newDataInterfaces = data.interfaces.map((i) => {
        if (i._id === orbitID) {
            return {
                ...i,
                ...newData
            } as typeof i;
        }

        if (i._id === rPData.parent?._id && newData["__typename"] === "NiamField") {
            const nf = i.fields.find(f => f._id === newData._id)

            if (!nf) {
                 i.fields.push({ ...newData } as unknown as any)
            }

            i.fields = i.fields.map(f => {
                if (f._id === newData._id) {
                    return {
                        ...f,
                        ...newData
                    } as typeof f;
                }

                return f
            })
        }

        return i;
    });

    const newDataImplements = data.implementLinks.map((il) => {
        if (il.source._id === orbitID || il.target._id === orbitID) {
            return {
                ...il,
                source: il.source._id,
                target: il.target._id
            } as unknown as NiamImplementElement;
        }
        return il;
    });

    const newDataPaths = data.paths.map((il) => {
        if (il.source._id === orbitID || il.target._id === orbitID) {
            return {
                ...il,
                source: il.source._id,
                target: il.target._id
            } as unknown as NiamPathElement;
        }
        return il;
    });

    if (type === EUpdateType.NODE_RELATIONSHIP) {
        newNodeRelationships.forEach((nr) => {
            if (nr._id === orbitID && newData.relation.objFrom) {
                // Add to new objFrom
                const objFromID = newData.relation.objFrom._id;

                newDataObjects = newDataObjects.map((dObj) => {
                    if (dObj._id === objFromID) {
                        dObj.nodeRelationships.push(newData as any);
                    }
                    return dObj;
                });

                newDataRels.push(
                    {
                        curve: 0,
                        source: newData.relation.objFrom._id,
                        target: newData._id,
                        type: RelationshipType.NODE_RELATION,
                        _id: newData.relation.relFrom._id,
                        isShown: true,
                        relation: {
                            objFrom: newData.relation.objFrom,
                            relFrom: newData.relation.relFrom
                        }
                    } as NiamImplementElement,
                    {
                        curve: 0,
                        source: newData._id,
                        target: newData.relation.objTo._id,
                        type: RelationshipType.NODE_RELATION,
                        _id: newData.relation.relTo._id,
                        isShown: true,
                        relation: {
                            objTo: newData.relation.objTo,
                            relTo: newData.relation.relTo
                        }
                    } as NiamImplementElement,
                );
            }
        });
    }

    let addNewDataRelFromEnum = false;
    let updateAnExistingDataRelFromEnum = false;
    let foundIndex = null;

    if (type === EUpdateType.RELATIONSHIP) {
        addNewDataRelFromEnum = newDataRels.every(f => f._id !== orbitID) && newData.fieldType === NodeTypename.NIAM_ENUM;

        newDataRels.forEach((f, index) => {
            // when an existing link's id is the same as input orbitID but its source's id is different
            // from the new relatedTo id, that means we are updating the same field/property from an old
            // Enum value to a new Enum value
            if (f._id === orbitID && newData.relatedTo !== f.source._id) {
                foundIndex = index;
                updateAnExistingDataRelFromEnum = true;
            }
        });

        newDataRels = newDataRels.map((rl) => {
            if (rl._id === orbitID)
            {
                return {
                    ...rl,
                    displayName: newData.eventType ? `${newData.displayName} (${newData.eventType})` : newData.displayName,
                };
            }
            return rl;
        });

        newDataScripts = newDataScripts.map((s) => {
            s.relationships = s.relationships.map((r) => {
                if (r._id === orbitID)
                {
                    return {
                        ...r,
                        ...newData,
                        displayName: newData.eventType ? `${newData.displayName} (${newData.eventType})` : newData.displayName
                    };
                }

                return r;
            }) as typeof s.relationships;

            return s;
        });
    }

    if (newData.fieldType === NodeTypename.NIAM_ENUM) {
        const curveMap = new Map();
        const curve = setCurveFields(curveMap, newData.relatedTo, newData._id)

        const cm = setCurveMap(newData.relatedTo, newData._id, curve)
        curveMap.set(cm[0], cm[1])

        if (addNewDataRelFromEnum) {
            newDataRels.push({
                source: newData.relatedTo,
                target: rPData.parent._id,
                type: "enum",
                curve,
                displayName: newData.displayName,
                _id: newData._id,
                isShown: true
            } as unknown as NiamImplementElement);
        }
        else if (updateAnExistingDataRelFromEnum) {
            if (foundIndex !== null) {
                newDataRels.splice(foundIndex, 1);

                newDataRels.push({
                    source: newData.relatedTo,
                    target: rPData.parent._id,
                    type: "enum",
                    curve,
                    displayName: newData.displayName,
                    _id: newData._id,
                    isShown: true
                } as unknown as NiamImplementElement);
            }
        }
        else {
            newDataRels.filter(f => f._id !== orbitID).push({
                source: newData.relatedTo,
                target: rPData.parent._id,
                type: "enum",
                curve,
                displayName: newData.displayName,
                _id: newData._id,
                isShown: true
            } as unknown as NiamImplementElement);
        }
    }

    const newDataCanvas = {
        objects: newDataObjects,
        interfaces: newDataInterfaces,
        scripts: newDataScripts,
        customMutations: newDataCustomMutations,
        tags: newTags,
        enums: newEnums,
        nodeRelationships: newNodeRelationships,
        implementLinks: newDataImplements,
        paths: newDataPaths,
        relLinks: newDataRels
    };

    setData(newDataCanvas);

    const orbitShown = getShown([
        ...newDataCanvas.interfaces,
        ...newDataCanvas.objects,
        ...newDataCanvas.scripts,
        ...newDataCanvas.customMutations,
        ...newDataCanvas.tags,
        ...newDataCanvas.enums,
        ...newDataCanvas.nodeRelationships
    ]);

    const linkShown = getShown([...newDataCanvas.implementLinks, ...newDataCanvas.relLinks, ...newDataCanvas.paths]).reverse();

    d3Data.g
        .selectAll(".link")
        .data(linkShown, (node: { _id: string }) => node._id)
        .exit()
        .remove();

    d3Data.g
        .selectAll(".orbit")
        .data(orbitShown, (node: { _id: string }) => node._id)
        .enter()
        .append("g")
        .call(enters.enterOrbit)
        .on("click", d3Data.zoomOnNodesInstance)

    updateSimulation(d3Data.simulation, orbitShown, linkShown);

    d3Data.g
        .selectAll(".link")
        .data(linkShown, (node: { _id: string }) => node._id)
        .enter()
        .append("g")
        .call(enters.enterLink)
        .on("click", d3Data.zoomOnLinksInstance)

    if (type === EUpdateType.RELATIONSHIP) {
        if (rPData.parent.__typename === NodeTypename.NIAM_SCRIPT) {
            d3Data.g.select(`#relationshipID_${orbitID}`).select("textPath").text(`${newData.displayName} (${newData.eventType})`);
        } else {
            d3Data.g.select(`#relationshipID_${orbitID}`).select("textPath").text(newData.displayName);
        }
    } else {
        d3Data.g.select(`#orbitID_${orbitID}`).select("text").text(newData.displayName);
    }
};

import styled from "styled-components";

export const Body = styled.div`
    width: 100vw;
    padding: 48px 213px 80px 120px;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.colors.background};
    flex: 0;
    display: flex;
    flex-direction: column;
`;

export const Description = styled.strong<{ $small?: boolean }>`
    font-size: ${({ $small }) => ($small ? "12px" : "16px")};
`;

export const Title = styled.header`
    width: 164px;
    height: 37px;
    font-family: IBM Plex Sans;
    font-size: 28px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    white-space: nowrap;

    a {
        text-decoration: none;
        color: rgba(0, 0, 0, 0.87);

        :hover {
            color: purple;
        }
    }
`;

export const ArrowBreadcrumbs = styled.span`
    border-style: solid;
    border-color: #555;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 5px;
    margin: 0 10px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
`;

export const FlexList = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 40px 0px;
    margin: 0px -15px 0px 0px;
    box-sizing: border-box;
`;

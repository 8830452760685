import { gql } from "@apollo/client";

export const GET_SERVICE_ACCOUNTS = gql`
  query getServiceAccounts {
    getServiceAccounts {
      _id
      cloud
      owner
      serviceAccountGCP
      serviceAccountAWSClientID
      serviceAccountAWSClientSecret
    }
  }
`;

export const GET_SERVICE_ACCOUNTS_BY_USER = gql`
  query getServiceAccountsByUser($serverID: ID) {
    getServiceAccountsByUser(input: { serverID: $serverID }) {
      _id
      cloud
      owner
    }
  }
`;

export const COLLECT_CLUSTERS_BY_SERVICE_ACCOUNT = gql`
  query collectClustersByServiceAccount($serviceAccountID: ID!) {
    collectClustersByServiceAccount(input: { serviceAccountID: $serviceAccountID }) {
      id
      name
      location
    }
  }
`;

export const GET_CLUSTERS_BY_SERVICE_ACCOUNT = gql`
  query getClustersByServiceAccount($serviceAccountID: ID!) {
    getClustersByServiceAccount(input: { serviceAccountID: $serviceAccountID }) {
      _id
      clusterID
      name
      location
    }
  }
`;

export const DELETE_SERVICE_ACCOUNT = gql`
  mutation deleteServiceAccount($serviceAccountID: ID!) {
      deleteServiceAccount(input: { serviceAccountID: $serviceAccountID })
  }
`;

export const ADD_SERVICE_ACCOUNT = gql`
  mutation addServiceAccount($cloud: CloudServiceAccounts!, $serviceAccountGCP: String, $serviceAccountAWSClientID: String, $serviceAccountAWSClientSecret: String) {
      addServiceAccount(input: { cloud: $cloud, serviceAccountGCP: $serviceAccountGCP, serviceAccountAWSClientID: $serviceAccountAWSClientID, serviceAccountAWSClientSecret: $serviceAccountAWSClientSecret }) {
        _id
        cloud
        owner
        serviceAccountGCP
        serviceAccountAWSClientID
        serviceAccountAWSClientSecret
      }
  }
`;

export const EDIT_SERVICE_ACCOUNT = gql`
  mutation editServiceAccount($serviceAccountID: ID, $cloud: CloudServiceAccounts!, $serviceAccountGCP: String, $serviceAccountAWSClientID: String, $serviceAccountAWSClientSecret: String) {
      editServiceAccount(input: { serviceAccountID: $serviceAccountID, cloud: $cloud, serviceAccountGCP: $serviceAccountGCP, serviceAccountAWSClientID: $serviceAccountAWSClientID, serviceAccountAWSClientSecret: $serviceAccountAWSClientSecret }) {
        _id
        cloud
        owner
        serviceAccountGCP
        serviceAccountAWSClientID
        serviceAccountAWSClientSecret
      }
  }
`;

export const ALL_STATUS_SA = gql`
  query getAllStatusSA($serviceAccountID: ID!) {
    getAllStatusSA(input: { serviceAccountID: $serviceAccountID }) {
      saStatus
    }
  }
`

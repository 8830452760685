import { ButtonListFlex, ConfirmButton } from "components/ModalDataServer/styled";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "ui-components/modal";
import { PwdField } from "ui-components/pwdField";
import { TextField } from "ui-components/styleds";
import { isEmpty, isNotEmpty } from "@3edges/utils/dist/utils";

interface ModalDeleteAccountProps {
    isModalOpenAccount: boolean;
    emailAccount: string;
    setEmailAccount: (email: string) => void;
    passwordAccount: string;
    setPasswordAccount: (pass: string) => void;
    waitDelete: boolean;
    onClose: () => void;
    onSave: () => void;
}

function ModalDeleteAccount({
    isModalOpenAccount,
    emailAccount,
    setEmailAccount,
    passwordAccount,
    setPasswordAccount,
    waitDelete,
    onClose,
    onSave
}: ModalDeleteAccountProps): React.ReactElement {
    const { t } = useTranslation();

    useEffect(() => {
        if (!isModalOpenAccount) {
            setEmailAccount("");
            setPasswordAccount("");
        }
    }, [isModalOpenAccount]);

    return (
        <Modal isOpen={isModalOpenAccount} onClose={onClose} style={{ width: "600px" }}>
            <h1>
                {t("profile:delete.account.title")}
            </h1>

            <TextField
                value={emailAccount}
                onChange={(e) => {
                    setEmailAccount(e.target.value);
                }}
                label={t("profile:emailAccount.label")}
            />

            <PwdField
                value={passwordAccount}
                onChange={(e) => {
                    setPasswordAccount(e.target.value);
                }}
                label={t("profile:passwordAccount.label")}
                onKeyPress={(e) => {
                    if (
                        (e.keyCode === 13 || e.charCode === 13) &&
                        isNotEmpty(emailAccount) &&
                        isNotEmpty(passwordAccount)
                    ) {
                        onSave();
                    }
                }}
            />

            <ButtonListFlex>
                <ConfirmButton color="secondary" disabled={waitDelete || isEmpty(emailAccount) || isEmpty(passwordAccount)} onClick={onSave}>
                    {t("root:btn.delete.now.label")}
                </ConfirmButton>
            </ButtonListFlex>
        </Modal>
    );
}

export default ModalDeleteAccount;

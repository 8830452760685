/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable max-params */
import * as d3 from "d3";
import { NiamImplementElement, NiamSystemItem, iD3Data } from "./types";

export const setupSimulation = (nodes: any, links: NiamImplementElement[]): d3.Simulation<unknown, undefined> => {
    return d3.forceSimulation(nodes).force("links", d3.forceLink(links || []).id((d) => (d as any)._id))
}

export const updateSimulation = (simulation: iD3Data["simulation"], nodes: NiamSystemItem[], links: NiamImplementElement[]) => {
    simulation.stop();
    simulation.nodes(nodes).force("links", d3.forceLink(links || []).id((d) => (d as NiamImplementElement)._id))
    simulation.alpha(0.1).alphaDecay(0.2).restart();
};

export const updateSimulationWithPhysics = (simulation: iD3Data["simulation"], nodes: NiamSystemItem[], links: NiamImplementElement[], saveOrbitCollectionPositions: any, setEnablePhysicsEffect?: any) => {
    simulation.stop();

    simulation
        .nodes(nodes)
        .force("links", d3.forceLink(links || []).id((d) => (d as NiamImplementElement)._id))
        .force("collide",d3.forceCollide(200))
        .force("charge", d3.forceManyBody())
        .force("center", d3.forceCenter(window.innerWidth / 2, window.innerHeight / 2))
        .on("end", () => {
            const orbits = nodes.map( node => ({
                    orbitID: node['_id'],
                    x: node['x'],
                    y: node['y'],
            }));

            nodes.length > 0 && saveOrbitCollectionPositions({
                variables: {
                    orbitsCollection: orbits
                }
            })

            setEnablePhysicsEffect(false)
        });

    simulation.alpha(0.1).alphaDecay(0.2).restart();
};

/* eslint-disable react/forbid-component-props */
import { useLazyQuery } from "@apollo/client";
import PanelHeader from "components/PanelHeader";
import { ArrowBreadcrumbs, Description, Title } from "components/PanelServer/styled";
import { useData } from "contexts/dataContext";
import { PageContainer } from "pages/Canvas/styled";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import
    {
        adminGetUsersByTenant_adminGetUsersByTenant_users as TenantUsers,
        adminGetUsers_adminGetUsers_users as Users
    } from "types/operation-result-types";
import { UsersTable } from "./components";
import { GET_USERS, GET_USERS_BY_TENANT } from "./gql";
import { Body } from "./styled";
import SidebarWrapper from "components/SidebarWrapper";
import { LoadingData } from "pages/ServiceAccounts/styled";

function AdminPage (): React.ReactElement {
    const { t } = useTranslation();
    const { tenantID } = useParams<{ tenantID: string }>();
    const { getTenant, tenant, loadingTenant } = useData();

    const [getListUsers, { data, loading, refetch }] = useLazyQuery(tenantID ? GET_USERS_BY_TENANT : GET_USERS, { fetchPolicy: "no-cache" });
    const [paginationData, setPaginationData] = useState({
        skip: 0,
        page: 0,
        first: 10,
        searchWord: ""
    });
    const [open, setOpen] = React.useState(true);

    const handleDrawerOpen = (): void => {
        setOpen(true);
    };

    const handleDrawerClose = (): void => {
        setOpen(false);
    };

    useEffect(() => {
        getTenant(tenantID);

        if (tenantID) {
            getListUsers({ variables: { tenantID, ...paginationData } });
        } else {
            getListUsers({ variables: { ...paginationData } });
        }
    }, [tenantID, paginationData])

    // if (loadingTenant || (tenantID && !tenant)) {
    //     return null
    // }

    // if (loading || (!tenantID && !data)) {
    //     return null
    // }

    return (
        <SidebarWrapper open={open} handleDrawerClose={handleDrawerClose}>
            <PageContainer>
                <PanelHeader open={open} handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen} />

                <Body>
                    <Title>
                        <Link to="/">
                            {t("panel:header.tenants")}
                        </Link>

                        <ArrowBreadcrumbs />

                        {t("panel:header.users")}
                    </Title>

                    <Description>
                        {tenant
                        ? `${t("panel:add.data.server.tenant.selected")}: ${tenant.name}`
                        : t("panel:add.data.server.mode")}
                    </Description>

                    {(loadingTenant || (tenantID && !tenant)) || (loading || (!tenantID && !data)) ? (
                        <LoadingData>
                            <div>Loading data</div>
                            <div className="loader" />
                        </LoadingData>
                    ) :
                        <UsersTable data={data} tenantID={tenantID} refetch={refetch} paginationData={paginationData} setPaginationData={setPaginationData} />
                    }
                </Body>
            </PageContainer>
        </SidebarWrapper>
    );
}

export default AdminPage;

import { iD3Data, iData } from "../types";

interface DeleteD3ScriptParams {
    d3Data: iD3Data;
    scriptId: string;
    data: iData;
    setData: React.Dispatch<React.SetStateAction<iData>>;
}

export const deleteD3Script = ({ scriptId, data, setData }: DeleteD3ScriptParams): iData =>
{
    const newDataScripts = data.scripts.filter((item) => item._id !== scriptId);

    // Remove the blue line (relation)
    const newDataRels = data.relLinks.filter((rl) => rl.source._id !== scriptId);

    // When you remove an orbit, which has a relation with nodeRelationship
    const newDataNodeRelationships = data.nodeRelationships.map((nr) => {
        if (nr.relation?.script?._id === scriptId) {
            nr.relation.script = null
            nr.relation.relScript = null
        }

        return nr;
    })

    const newData = {
        ...data,
        scripts: newDataScripts,
        nodeRelationships: newDataNodeRelationships,
        relLinks: newDataRels
    };

    setData(newData);
    return newData;
};

import { useLazyQuery } from "@apollo/client";
import PanelHeader from "components/PanelHeader";
import { ArrowBreadcrumbs, Description, Title } from "components/PanelServer/styled";
import { useData } from 'contexts/dataContext';
import { UsersShared } from 'pages/AdminPage/components/UsersTable/UsersShared';
import { GET_USERS_FROM_DATASERVER_SHARED } from "pages/AdminPage/gql";
import { Body, MainContainer } from 'pages/AdminPage/styled';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from "react-router-dom";
import SidebarWrapper from "components/SidebarWrapper";

function ShareDataServer(): React.ReactElement {
    const { t } = useTranslation();
    const { serverID } = useParams<{ serverID: string }>();
    const { getDataServerInfo, loadingDS, tenant, server } = useData();

    const [getListUsers, { data, loading, refetch }] = useLazyQuery(GET_USERS_FROM_DATASERVER_SHARED, { fetchPolicy: "no-cache" });
    const [paginationData, setPaginationData] = useState({
        skip: 0,
        page: 0,
        first: 10,
        searchWord: ""
    });

    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = (): void => {
        setOpen(true);
    };

    const handleDrawerClose = (): void => {
        setOpen(false);
    };

    useEffect(() => {
        if (serverID) {
            getListUsers({ variables: { serverID, ...paginationData } });
        }
    }, [serverID, paginationData])

    useEffect(() => {
        if (serverID) {
            getDataServerInfo(serverID)
        }
    }, [serverID])

    if (loadingDS || (serverID && !server)) {
        return null;
    }

    if (loading || (serverID && !data)) {
        return null
    }

    return (
        <SidebarWrapper open={open} handleDrawerClose={handleDrawerClose}>
            <MainContainer>
                <PanelHeader open={open} handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen} />

                <Body>
                    <Title>
                        <Link to={`${tenant ? `/${tenant._id}/servers` : "/servers"}`}>
                            {t("panel:header.tenants")}
                        </Link>

                        <ArrowBreadcrumbs />

                        {t("panel:header.share")}
                    </Title>

                    <Description>
                        {tenant
                            ? `${t("panel:add.data.server.tenant.selected")}: ${tenant.name}`
                            : t("panel:add.data.server.mode")}
                    </Description>

                    <Description>
                        {server && `${t("panel:add.data.server.ds.selected")}: ${server.name}`}
                    </Description>

                    <UsersShared data={data} refetch={refetch} paginationData={paginationData} setPaginationData={setPaginationData} />
                </Body>
            </MainContainer>
        </SidebarWrapper>
    );
}

export default ShareDataServer;

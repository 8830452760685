/* eslint-disable unicorn/no-lonely-if */
/* eslint-disable unicorn/prefer-dom-node-append */
/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import ToastContainer, { toastError } from "@3edges/utils/dist/toastify";
import { base64_decode } from "@3edges/utils/dist/utils";
import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache, NormalizedCacheObject, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import Routes from "Routes";
import { createUploadLink } from "apollo-upload-client";
import { clearStorage, getStorage } from "cache";
import { PrimThemeProvider } from "contexts";
import { useCookie } from "contexts/cookieContext";
import { DataProvider } from "contexts/dataContext";
import { REACT_ENV } from "environmentVariables";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import { ToastContainer } from "react-toastify";
import axios from "axios";
import "sweetalert2/dist/sweetalert2.min.css";
import { compareHash } from "utils";
import { checkToken, refreshTokenExchange } from "utilsFn";
import "./i18n";
import typeDefs from "./typedefs";

function App (): React.ReactElement {
    const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>(undefined);
    const { t } = useTranslation();

    const { getAccessTokenCookie, getIDTokenCookie, getNonceCookie, removeAllCookies } = useCookie();
    const currentCookie = getAccessTokenCookie();
    const currentCookieIDToken = getIDTokenCookie();
    const [csrfValue, setCsrfValue] = useState("")

    async function getCSRFToken() {
        const response = await axios.get(REACT_ENV.REACT_APP_PRIM_BACKEND_URI.replace("/graphql", "/api/csrf-token"), { withCredentials: true });
        return response.data.csrfToken;
    }

    useEffect(() => {
        if (!csrfValue) {
            (async () => {
                const csrfToken = await getCSRFToken();
                setCsrfValue(csrfToken)
            })()
        }
    }, [csrfValue])

    useEffect(() =>
    {
        if (csrfValue)
        {
            const cache = new InMemoryCache({});

            const authLink = setContext((_, { headers }) =>
            {
                return {
                    headers: {
                        ...headers as Record<string, string>,
                        authorization: currentCookie ? `Bearer ${currentCookie}` : null,
                        'CSRF-Token': csrfValue
                    }
                }
            });

            const forceLogout = async () =>
            {
                toastError(t("validations:session.expired"), { toastId: 0 });
                clearStorage();
                removeAllCookies();
                globalThis.location.href = "/login"
            }

            const activityMiddleware = new ApolloLink((operation, forward) =>
            {
                const checkAccessTokenCookie = getAccessTokenCookie();
                const checkIDTokenCookie = getIDTokenCookie();
                const checkNonceCookie = getNonceCookie();
                const refreshTokenLocalStorage = getStorage(REACT_ENV.REACT_APP_REFRESH_TOKEN_LOCAL_STORAGE_NAME ? REACT_ENV.REACT_APP_REFRESH_TOKEN_LOCAL_STORAGE_NAME : "default_nid_r") as string;

                const mutationCollection = [
                    "setAccountAsVerified",
                    "checkIfUserAlreadyExists",
                    "addUser",
                    "getNewVerificationCode",
                    "addNewUser",
                    "updatePWD",
                    "resetPWD",
                    "resetYourPWD",
                ]

                const activity = operation.query.definitions[0]["selectionSet"].selections[0].name.value;

                if (!mutationCollection.includes(activity))
                {
                    // If you lost some cookie, it will logout from UI
                    if (!checkAccessTokenCookie || !checkIDTokenCookie || !checkNonceCookie)
                    {
                        if (refreshTokenLocalStorage)
                        {
                            refreshTokenExchange(refreshTokenLocalStorage).then((response) =>
                            {
                                if (!response)
                                {
                                    forceLogout()
                                }
                            }).catch((error) => console.log(error))
                        } else
                        {
                            forceLogout()
                        }
                    } else
                    {
                        // Check if all request still has a valid 'id_token'
                        checkToken(base64_decode(checkIDTokenCookie)).then((res: any) =>
                        {
                            if (res.status === "SUCCESS")
                            {
                                if (res.verifiedToken.nonce)
                                {
                                    // Check if the nonce code is different within cookie
                                    compareHash(res.verifiedToken.nonce, checkNonceCookie).then((err) =>
                                    {
                                        if (!res)
                                        {
                                            forceLogout()
                                        }
                                    }).catch((error) => console.log(error))
                                }
                            } else
                            {
                                if (refreshTokenLocalStorage)
                                {
                                    refreshTokenExchange(refreshTokenLocalStorage).then((response) =>
                                    {
                                        if (!response)
                                        {
                                            forceLogout()
                                        }
                                    }).catch((error) => console.log(error))
                                } else
                                {
                                    forceLogout()
                                }
                            }
                        }).catch((error) => console.log(error))
                    }
                }

                return forward(operation);
            })

            const errorLink = onError(({ graphQLErrors, networkError }) =>
            {
                if (graphQLErrors)
                {
                    console.log(graphQLErrors);

                    graphQLErrors.forEach((e: any) =>
                    {
                        if ([400, 401, 403, 404, 500].includes(e.extensions.statusCode))
                        {
                            if (e.extensions.code === "UNAUTHORIZED" || e.extensions.originalError === "Invalid credentials")
                            {
                                toastError(t("validations:session.expired"), { toastId: 0 });

                                setTimeout(() =>
                                {
                                    globalThis.location.href = `${REACT_ENV.REACT_APP_OIDC_URL}/session/end?id_token_hint=${base64_decode(currentCookieIDToken)}&force=true&post_logout_redirect_uri=${REACT_ENV.REACT_APP_URL_UI}/login`
                                }, 1500)
                            }
                        }
                    })
                }

                if (networkError)
                {
                    toastError("CONNECTION_REFUSED", { toastId: 0 });
                    console.log(networkError);
                }

                // const isInvalid = graphQLErrors && graphQLErrors.find(e => e.message.includes("Invalid credentials"))

                // if (isInvalid || networkError) {
                //     clearStorage();
                //     window.location.href = "/login"
                // }
            });

            const newClient = new ApolloClient({
                link: from([
                    errorLink,
                    activityMiddleware,
                    authLink,
                    createUploadLink({ uri: REACT_ENV.REACT_APP_PRIM_BACKEND_URI, credentials: 'include', headers: { 'Apollo-Require-Preflight': 'true' } })
                ]),
                cache,
                typeDefs,
                defaultOptions: {
                    watchQuery: {
                        fetchPolicy: "no-cache",
                        errorPolicy: "all"
                    },
                    query: {
                        fetchPolicy: "no-cache",
                        errorPolicy: "all"
                    },
                    mutate: {
                        fetchPolicy: "no-cache",
                        errorPolicy: "all"
                    }
                }
            });

            setClient(newClient);
        }
    }, [currentCookie, csrfValue]);

    if (client === undefined) {
        return <div>Loading...</div>;
    }

    return (
        <ApolloProvider client={client}>
            <DataProvider>
                <PrimThemeProvider>
                    <Routes />

                    <ToastContainer position="top-center" />

                    <span className="material-icons" style={{ position: 'absolute', left: -9999, top: 0 }}>webhook</span>

                    <span className="material-icons-outlined" style={{ position: 'absolute', left: -9999, top: 0 }}>stop_circle</span>

                    <span className="material-symbols-outlined" style={{ position: 'absolute', left: -9999, top: 0 }}>manage_history</span>
                </PrimThemeProvider>
            </DataProvider>
        </ApolloProvider>
    );
}

export default App;

/* eslint-disable max-lines */
import { gql } from "@apollo/client";

const FIELD_FRAGMENT = gql`
  fragment FieldsFields on NiamField {
      _id
      isArray
      isRequired
      isNaming
      isSecret
      isDisplay
      displayName
      fieldType
      relatedTo
      name
  }
`;

/** ********/
/* DELETE */
/** ********/
export const RP_DELETE_OBJECT = gql`
  mutation rPObjectDelete($ObjectID: ID!) {
      deleteNiamObject(input: { ObjectID: $ObjectID })
  }
`;

export const RP_DELETE_INTERFACE = gql`
  mutation rPInterfaceDelete($interfaceID: ID!) {
      deleteNiamInterface(input: { _id: $interfaceID })
  }
`;

export const RP_DELETE_FIELD = gql`
  mutation rPDeleteField($fieldID: ID!) {
      deleteNiamField(input: { FieldID: $fieldID })
  }
`;

export const RP_DELETE_RELATIONSHIP = gql`
  mutation rPDeleteRelationship($relID: ID!) {
      deleteNiamRel(input: { relID: $relID })
  }
`;

export const RP_DELETE_SCRIPT = gql`
  mutation rPDeleteScript($scriptID: ID!) {
      deleteNiamScript(input: { scriptID: $scriptID })
  }
`;

export const RP_DELETE_CUSTOM_MUTATION = gql`
  mutation rPDeleteCustomMutation($customMutationID: ID!) {
      deleteNiamCustomMutation(input: { customMutationID: $customMutationID })
  }
`;

export const RP_DELETE_TAG = gql`
  mutation rPDeleteTag($tagID: ID!) {
      deleteNiamTag(input: { tagID: $tagID })
  }
`;

export const RP_DELETE_ENUM = gql`
  mutation rPDeleteEnum($_id: ID!) {
      deleteNiamEnum(input: { _id: $_id }) {
          deletedFieldsIDs
      }
  }
`;

export const RP_DELETE_IMPLEMENT = gql`
  mutation rPDeleteImplement($implementID: ID!) {
      deleteNiamImplement(input: { implementID: $implementID })
  }
`;

export const RP_DELETE_NODE_RELATIONSHIP = gql`
  mutation rPDeleteNodeRelationship($nodeRelationshipID: ID!) {
      deleteNiamNodeRelationship(input: { nodeRelationshipID: $nodeRelationshipID })
  }
`;

/** ********/
/* UPDATE */
/** ********/

export const RP_UPDATE_OBJECT = gql`
  ${FIELD_FRAGMENT}
  mutation rPUpdateObject($objectID: ID!, $params: NiamObjectParamsInput!, $serverID: ID!) {
      updateNiamObject(input: { objectID: $objectID, params: $params, serverID: $serverID }) {
          _id
          displayName
          name
          description
          fields {
              ...FieldsFields
          }
          selfRegistration
      }
  }
`;

export const RP_UPDATE_RELATIONSHIP = gql`
  mutation rPUpdateRelationship($relID: ID!, $scriptID: ID!, $relatedTo: ID!, $params: NiamRelationshipParamsInput!) {
      updateNiamRel(input: { relID: $relID, scriptID: $scriptID, relatedTo: $relatedTo, params: $params }) {
          _id
          name
          displayName
          eventType
      }
  }
`;

export const RP_UPDATE_INTERFACE = gql`
  ${FIELD_FRAGMENT}
  mutation rPUpdateInterface($interfaceID: ID!, $serverID: ID!, $params: niamInterfaceParamsInput!) {
      updateNiamInterface(input: { niamInterfaceID: $interfaceID, niamDataServerID: $serverID, params: $params }) {
          _id
          displayName
          name
          fields {
              ...FieldsFields
          }
      }
  }
`;

export const RP_UPDATE_CUSTOM_MUTATION = gql`
  mutation rPUpdateCustomMutation($customMutationID: ID!, $serverID: ID!, $params: CustomMutationParamsInput!) {
      updateNiamCustomMutation(input: { customMutationID: $customMutationID, serverID: $serverID, params: $params }) {
          _id
          displayName
          name
          inputSchema
          args
          mutatuinName
          returnType
          script
      }
  }
`;

export const RP_UPDATE_TAG = gql`
  mutation rPUpdateTag($tagID: ID!, $serverID: ID!, $params: TagParamsInput!) {
      updateNiamTag(input: { tagID: $tagID, serverID: $serverID, params: $params }) {
          _id
          displayName
          name
      }
  }
`;

export const RP_UPDATE_ENUM = gql`
  mutation rPUpdateEnum(
      $_id: ID!
      $name: String
      $displayName: String
      $icon: String
      $niamID: String
      $serverID: ID!
      $enumerates: [String!]
  ) {
      updateNiamEnum(
          input: {
              _id: $_id
              name: $name
              displayName: $displayName
              icon: $icon
              niamID: $niamID
              serverID: $serverID
              enumerates: $enumerates
          }
      ) {
          _id
          displayName
          name
          enumerates
      }
  }
`;

export const RP_UPDATE_SCRIPT = gql`
  mutation rPUpdateScript($scriptID: ID!, $params: NiamScriptParamsInput!, $serverID: ID!) {
      updateNiamScript(input: { scriptID: $scriptID, params: $params, serverID: $serverID }) {
          _id
          name
          displayName
          funcName
          returnType
          scriptText
      }
  }
`;

export const RP_UPDATE_NODE_RELATIONSHIP = gql`
  mutation rPUpdateNodeRelationship(
      $nodeRelationshipID: ID!
      $serverID: ID!
      $objFrom: ID
      $objTo: ID
      $objFromName: String
      $objToName: String
      $params: AddNewDefaultPrimNodeRelationshipParamsInput!
  ) {
      updateNiamNodeRelationship(
          input: {
              nodeRelationshipID: $nodeRelationshipID
              serverID: $serverID
              params: $params
              objFrom: $objFrom
              objTo: $objTo
              objFromName: $objFromName
              objToName: $objToName
          }
      ) {
          _id
          displayName
          name
          description
          fields {
              _id
              displayName
              name
              fieldType
              relatedTo
          }
          relation {
            objFrom {
                  _id
                  name
                  displayName
              }
              objTo {
                  _id
                  name
                  displayName
              }
              script {
                  _id
                  name
                  displayName
              }
              relScript {
                  _id
                  name
                  displayName
              }
              relFrom {
                  _id
                  name
                  displayName
              }
              relTo {
                  _id
                  name
                  displayName
              }
              rel {
                  _id
                  x
                  y
                  name
                  displayName
                  isResource
                  isAction
                  isVC
                  simplePath {
                        relatedTo
                        _id
                    }
                  fields {
                      _id
                      displayName
                      name
                      fieldType
                      relatedTo
                  }
              }
          }
      }
  }
`;

/** *****/
/* GET */
/** *****/

export const RP_GET_OBJECT = gql`
  ${FIELD_FRAGMENT}
  query rPObjectGet($objectID: ID!) {
      niamObject(input: { ObjectID: $objectID }) {
          _id
          displayName
          name
          description
          fields {
              ...FieldsFields
          }
          interfaces {
              _id
              displayName
              fields {
                  ...FieldsFields
              }
          }
          selfRegistration
      }
  }
`;

export const RP_GET_PROPERTY = gql`
  query rPPropertyGet($FieldID: ID!) {
      niamField(input: { FieldID: $FieldID }) {
          _id
          displayName
          name
          fieldType
          isArray
          isRequired
          isNaming
          isSecret
          isUnique
          isDisplay
          regex
          minLength
          maxLength
          relatedTo
      }
  }
`;

export const RP_GET_PROPERTY_RELATIONSHIP = gql`
  query rPPropertyRelationshipGet($relID: ID!) {
      niamRelationship(input: { relID: $relID }) {
          _id
          displayName
          name
          eventType
      }
  }
`;

export const RP_GET_IMPLEMENT = gql`
  query rPImplementGet($implementID: ID!) {
      niamImplement(input: { implementID: $implementID }) {
          _id
          objectID
          interfaceID
      }
  }
`;

export const RP_GET_INTERFACE = gql`
  ${FIELD_FRAGMENT}
  query rPInterfaceGet($interfaceID: ID!) {
      niamInterface(input: { _id: $interfaceID }) {
          _id
          displayName
          name
          fields {
              ...FieldsFields
          }
      }
  }
`;

export const RP_GET_SCRIPT = gql`
  query rPScriptGet($scriptID: ID!) {
      niamScript(input: { scriptID: $scriptID }) {
          _id
          displayName
          name
          funcName
          returnType
          scriptText
      }
  }
`;

export const RP_GET_CUSTOM_MUTATION = gql`
  query rPCustomMutationGet($customMutationID: ID!) {
      niamCustomMutation(input: { customMutationID: $customMutationID }) {
          _id
          displayName
          name
          inputSchema
          args
          mutatuinName
          returnType
          script
      }
  }
`;

export const RP_GET_TAG = gql`
  query rPTagGet($tagID: ID!) {
      niamTag(input: { tagID: $tagID }) {
          _id
          displayName
          name
      }
  }
`;

export const RP_GET_ENUM = gql`
  query rPEnumGet($_id: ID!) {
      niamEnum(input: { _id: $_id }) {
          _id
          displayName
          name
          enumerates
      }
  }
`;

export const RP_GET_NODE_RELATIONSHIP = gql`
  query rPNodeRelationshipGet($nodeRelationshipID: ID!) {
      niamNodeRelationship(input: { nodeRelationshipID: $nodeRelationshipID }) {
          _id
          displayName
          name
          description
          fields {
              _id
              displayName
              name
              fieldType
              relatedTo
              isRequired
              isDisplay
              isNaming
              isSecret
          }
          relation {
            objFrom {
                  _id
                  name
                  displayName
              }
              objTo {
                  _id
                  name
                  displayName
              }
              script {
                  _id
                  name
                  displayName
              }
              relScript {
                  _id
                  name
                  displayName
              }
              relFrom {
                  _id
                  name
                  displayName
              }
              relTo {
                  _id
                  name
                  displayName
              }
              rel {
                  _id
                  x
                  y
                  name
                  displayName
                  isAction
                  isVC
                  isResource
                  simplePath {
                        relatedTo
                        _id
                    }
                  fields {
                      _id
                      displayName
                      name
                      fieldType
                      relatedTo
                  }
              }
          }
      }
  }
`;

/**********/
/* CREATE */
/**********/

export const RP_ADD_FIELD_TO_OBJECT = gql`
  mutation rPAddFieldToObject($NiamObjectID: ID!, $newNiamFieldInput: NiamFieldInput!) {
      addNewFieldToNiamObject(input: { newNiamFieldInput: $newNiamFieldInput, NiamObjectID: $NiamObjectID }) {
          _id
          isArray
          isRequired
          isNaming
          isSecret
          isDisplay
          displayName
          fieldType
          relatedTo
          name
      }
  }
`;

export const RP_ADD_FIELD_TO_NODE_RELATIONSHIP = gql`
  mutation rPAddFieldToNodeRelationship($nodeRelationshipID: ID!, $newNiamFieldInput: NiamFieldInput!) {
      addNewFieldToNiamNodeRelationship(
          input: { newNiamFieldInput: $newNiamFieldInput, nodeRelationshipID: $nodeRelationshipID }
      ) {
          _id
          isArray
          isRequired
          isNaming
          isSecret
          isDisplay
          displayName
          fieldType
          relatedTo
          name
      }
  }
`;

export const RP_UPDATE_FIELD = gql`
  mutation rPUpdateNiamField($fieldID: ID!, $parentID: ID!, $params: NiamFieldInput!, $serverID: ID!, $parentType: String!) {
      updateNiamField(input: { fieldID: $fieldID, parentID: $parentID, params: $params, serverID: $serverID, parentType: $parentType }) {
          _id
          name
          displayName
          fieldType
          isRequired
          isNaming
          isSecret
          isArray
          isUnique
          isDisplay
          maxLength
          minLength
          regex
          relatedTo
      }
  }
`;

export const RP_ADD_FIELD_TO_INTERFACE = gql`
  mutation rPAddFieldToInterface($niamInterfaceID: ID!, $newNiamFieldInput: NiamFieldInput!) {
      addNewNiamFieldToNiamInterface(
          input: { newNiamFieldInput: $newNiamFieldInput, niamInterfaceID: $niamInterfaceID }
      ) {
          _id
          isArray
          isRequired
          isNaming
          isSecret
          isDisplay
          displayName
          fieldType
          relatedTo
          name
      }
  }
`;

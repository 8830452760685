/* eslint-disable max-lines */
// @flow
import
    {
        CustomMutationIcon, EnumIcon, InterfaceIcon,
        NodeRelationshipIcon,
        ObjectIcon,
        ScriptIcon, TagIcon
    } from "assets";

import { isNotEmpty } from "@3edges/utils/dist/utils";
import { getStorage } from "cache";
import { iD3Selection, NodeTypename } from "components/PrimGraphicalCanvas/types";
import { drag } from "d3";
import { select } from "d3-selection";
import type { DefaultTheme } from "styled-components";
import { IDropData, SourceData, TargetData } from "typedefs";
export interface OrbitType {
    x: number;
    y: number;
    __typename: NodeTypename;
    displayName: string;
    _id: string;
    index: number;
    color?: string;
    item?: unknown;
}

const getIconSVG = (__typename: string): any =>
{
    if (__typename === NodeTypename.NIAM_OBJECT) {
        return ObjectIcon;
    }
    if (__typename === NodeTypename.NIAM_INTERFACE) {
        return InterfaceIcon;
    }
    if (__typename === NodeTypename.NIAM_SCRIPT) {
        return ScriptIcon;
    }
    if (__typename === NodeTypename.NIAM_TAG) {
        return TagIcon;
    }
    if (__typename === NodeTypename.NIAM_CUSTOM_MUTATION) {
        return CustomMutationIcon;
    }
    if (__typename === NodeTypename.NIAM_ENUM)
    {
        return EnumIcon;
    }
    if (__typename === NodeTypename.NIAM_NODE_RELATIONSHIP) {
        return NodeRelationshipIcon;
    }
};

let hasDrawnRel = false;
let sourceOrbit = null;

const DRAG_HANDLE_RADIUS = 11,
    DRAG_HANDLE_Y = 0,
    DRAG_HANDLE_X_LEFT = -60,
    DRAG_HANDLE_X_RIGHT = 60,
    ICON_POSITION = -18,
    ICON_SIZE = 36,
    INNER_CIRCLE_RADIUS = 40,
    INNER_ORBIT_RADIUS = 86,
    OUTER_ORBIT_RADIUS = 93,
    TEXT_Y = 60,
    ZERO = 0,
    enterOrbit =
        (theme: Readonly<DefaultTheme>, dataTransfer: IDropData) => (selection: iD3Selection): void => {
            selection.classed("orbit", true);

            const isShowDashboard = getStorage("isShowDashboard");

            const orbitSelection = selection.append("g");
            orbitSelection.classed("orbitCore", true);

            // eslint-disable-next-line no-underscore-dangle
            selection.attr("id", (data: { _id: string }) => `orbitID_${data._id}`);

            selection.each((currentOrbit: any) => {
                const selectedOrbit = select(`#orbitID_${currentOrbit._id}`);

                const dragHandleG = selectedOrbit
                    .append("g")
                    .classed("dragHandle", true)
                    .attr("display", "none")
                    .attr("cursor", "copy")

                const endDragCB = (): void => {
                    document.querySelectorAll(".orbitCore").forEach((e) => {
                        e.classList.remove("setColorOrbit", "setColorOrbitValid", "setColorOrbitInValid");
                    });

                    hasDrawnRel = false;
                    dataTransfer.setIsDrawing(false);
                    selectedOrbit.select(".newRel").remove();
                };

                const dragCBGenerator =
                    (xDif: number) => (event: { x: number; y: number }, d1: { x: number; y: number; _id: string }) => {
                        if (hasDrawnRel) {
                            selectedOrbit
                                .select(".newRel")
                                .attr("x2", event.x - d1.x + xDif)
                                .attr("y2", event.y - d1.y);
                        } else {
                            hasDrawnRel = true;
                            dataTransfer.setIsDrawing(true);
                            dataTransfer.setItemSelected(null)

                            selectedOrbit
                                .append("line")
                                .classed("newRel", true)
                                .attr("x1", 0)
                                .attr("y1", 0)
                                .attr("x2", event.x - d1.x + xDif)
                                .attr("y2", event.y - d1.y)
                                .style("stroke-width", 2)
                                .attr("stroke", "#1e88e5");

                            dataTransfer.setTargetData(undefined);

                            dataTransfer.setSourceData({
                                _id: d1._id,
                                data: selectedOrbit.datum() as SourceData
                            });

                            sourceOrbit = currentOrbit as SourceData
                        }
                    };

                if (isShowDashboard) {
                    if (
                        currentOrbit.__typename !== NodeTypename.NIAM_NODE_RELATIONSHIP &&
                        currentOrbit.__typename !== NodeTypename.NIAM_TAG &&
                        currentOrbit.__typename !== NodeTypename.NIAM_CUSTOM_MUTATION &&
                        currentOrbit.__typename !== NodeTypename.NIAM_INTERFACE
                    ) {
                        dragHandleG
                            .append("circle")
                            .attr("cx", DRAG_HANDLE_X_RIGHT)
                            .attr("cy", DRAG_HANDLE_Y)
                            .attr("r", DRAG_HANDLE_RADIUS)
                            .attr("fill", "rgba(30, 136, 229, 0.5)")
                            .call(drag().on("drag", dragCBGenerator(DRAG_HANDLE_X_RIGHT)).on("end", endDragCB));

                        dragHandleG
                            .append("circle")
                            .attr("cx", DRAG_HANDLE_X_LEFT)
                            .attr("cy", DRAG_HANDLE_Y)
                            .attr("r", DRAG_HANDLE_RADIUS)
                            .attr("fill", "rgba(30, 136, 229, 0.5)")
                            .call(drag().on("drag", dragCBGenerator(DRAG_HANDLE_X_LEFT)).on("end", endDragCB));
                    }

                    if (currentOrbit.__typename === NodeTypename.NIAM_INTERFACE) {
                        dragHandleG
                            .append("circle")
                            .attr("cx", DRAG_HANDLE_X_RIGHT)
                            .attr("cy", DRAG_HANDLE_Y)
                            .attr("r", DRAG_HANDLE_RADIUS)
                            .attr("fill", "#C5C5C5")
                            .call(drag().on("drag", dragCBGenerator(DRAG_HANDLE_X_RIGHT)).on("end", endDragCB));
                        dragHandleG
                            .append("circle")
                            .attr("cx", DRAG_HANDLE_X_LEFT)
                            .attr("cy", DRAG_HANDLE_Y)
                            .attr("r", DRAG_HANDLE_RADIUS)
                            .attr("fill", "#C5C5C5")
                            .call(drag().on("drag", dragCBGenerator(DRAG_HANDLE_X_LEFT)).on("end", endDragCB));
                    }

                    selectedOrbit
                        .on("mouseover", () => {
                            if (hasDrawnRel) {
                                const data = selectedOrbit.datum() as TargetData;

                                dataTransfer.setTargetData({
                                    _id: data._id,
                                    __typename: data.__typename,
                                    data
                                });

                                if (data._id !== sourceOrbit._id)
                                {
                                    let relIsValid = false

                                    // Script -> Object OR NodeRelationship
                                    if (sourceOrbit.__typename === NodeTypename.NIAM_SCRIPT && (currentOrbit.__typename === NodeTypename.NIAM_OBJECT || currentOrbit.__typename === NodeTypename.NIAM_NODE_RELATIONSHIP)) {
                                        relIsValid = true
                                    }

                                    // Interface -> Object
                                    if (sourceOrbit.__typename === NodeTypename.NIAM_INTERFACE && currentOrbit.__typename === NodeTypename.NIAM_OBJECT) {
                                        relIsValid = true
                                    }

                                    // Enum -> Object
                                    if (sourceOrbit.__typename === NodeTypename.NIAM_ENUM && currentOrbit.__typename === NodeTypename.NIAM_OBJECT) {
                                        relIsValid = true
                                    }

                                    // Object -> Object
                                    if (sourceOrbit.__typename === NodeTypename.NIAM_OBJECT && currentOrbit.__typename === NodeTypename.NIAM_OBJECT) {
                                        relIsValid = true
                                    }

                                    const overTargetOrbit = select(`#orbitID_${data._id}`);
                                    overTargetOrbit.select(".orbitCore").classed(relIsValid ? "setColorOrbitValid" : "setColorOrbitInValid", true);
                                }

                            } else {
                                selectedOrbit.select(".dragHandle").attr("display", "block");
                                selectedOrbit.select(".itemsHandleG").attr("display", "block");
                            }
                        })
                        .on("mouseleave", (data) =>
                        {
                            if (hasDrawnRel) {
                                const overTargetOrbit = select(`#orbitID_${currentOrbit._id}`);
                                overTargetOrbit.select(".orbitCore").classed("setColorOrbitValid", false);
                                overTargetOrbit.select(".orbitCore").classed("setColorOrbitInValid", false);
                            }

                            dataTransfer.setTargetData(undefined);
                            selectedOrbit.select(".dragHandle").attr("display", "none");
                            selectedOrbit.select(".itemsHandleG").attr("display", "none");
                        });
                }

                if (!isShowDashboard &&
                        (
                            currentOrbit.__typename === NodeTypename.NIAM_CUSTOM_MUTATION ||
                            (currentOrbit.__typename === NodeTypename.NIAM_OBJECT && currentOrbit.isSubject)
                        )
                    ) {
                        if (currentOrbit.__typename === NodeTypename.NIAM_OBJECT) {
                            dragHandleG
                                .append("circle")
                                .attr("cx", DRAG_HANDLE_X_RIGHT)
                                .attr("cy", DRAG_HANDLE_Y)
                                .attr("r", DRAG_HANDLE_RADIUS)
                                .attr("fill", "rgb(100, 0, 100, 0.6)")
                                .call(drag().on("drag", dragCBGenerator(DRAG_HANDLE_X_RIGHT)).on("end", endDragCB));

                            dragHandleG
                                .append("circle")
                                .attr("cx", DRAG_HANDLE_X_LEFT)
                                .attr("cy", DRAG_HANDLE_Y)
                                .attr("r", DRAG_HANDLE_RADIUS)
                                .attr("fill", "rgb(100, 0, 100, 0.6)")
                                .call(drag().on("drag", dragCBGenerator(DRAG_HANDLE_X_LEFT)).on("end", endDragCB));
                        }

                    selectedOrbit
                        .on("mouseover", () => {
                            if (hasDrawnRel) {
                                const data = selectedOrbit.datum() as TargetData;

                                dataTransfer.setTargetData({
                                    _id: data._id,
                                    __typename: data.__typename,
                                    data
                                });
                            } else {
                                selectedOrbit.select(".dragHandle").attr("display", "block");
                            }
                        })
                        .on("mouseleave", (data) => {
                            dataTransfer.setTargetData(undefined);
                            selectedOrbit.select(".dragHandle").attr("display", "none");
                        });
                }

                if (!isShowDashboard &&
                    (
                        isNotEmpty(currentOrbit.simplePath) || isNotEmpty(currentOrbit.simplePathCreate) || isNotEmpty(currentOrbit.simplePathRead) || isNotEmpty(currentOrbit.simplePathUpdate) || isNotEmpty(currentOrbit.simplePathDelete)
                        || isNotEmpty(currentOrbit.isResource) || isNotEmpty(currentOrbit.isSubject) || isNotEmpty(currentOrbit.isAction)
                        || isNotEmpty(currentOrbit.isVC) || isNotEmpty(currentOrbit.isHolder) || isNotEmpty(currentOrbit.isAndOperator) || isNotEmpty(currentOrbit.isOrOperator) || isNotEmpty(currentOrbit.isIssuer) || isNotEmpty(currentOrbit.isClaim)
                    )
                ) {
                    let currentAuthSubject = select(`#authSubject_${currentOrbit._id}`)
                    let currentAuthResource = select(`#authResource_${currentOrbit._id}`)
                    let currentAuthAction = select(`#authAction_${currentOrbit._id}`)
                    let currentAuthVC = select(`#authVC_${currentOrbit._id}`)
                    let currentAuthHolder = select(`#authHolder_${currentOrbit._id}`)
                    let currentAuthAndOperator = select(`#authAndOperator_${currentOrbit._id}`)
                    let currentAuthOrOperator = select(`#authOrOperator_${currentOrbit._id}`)
                    let currentAuthIssuer = select(`#authIssuer_${currentOrbit._id}`)
                    let currentAuthClaim = select(`#authClaim_${currentOrbit._id}`)
                    const listLabel = [];

                    if (currentOrbit.isResource) {
                        select(`#authResource_${currentOrbit._id}`).remove()
                        selectedOrbit.classed("setColorIsResource", true);

                        selectedOrbit
                            .append("text")
                            .attr("id", `authResource_${currentOrbit._id}`)
                            .attr("text-anchor", "middle")
                            .attr("x", 0)
                            .attr("font-family", "IBM Plex Sans")
                            .text("AuthResource");

                        currentAuthResource = select(`#authResource_${currentOrbit._id}`)
                        listLabel.push(currentAuthResource)
                    }

                    if (currentOrbit.isSubject) {
                        select(`#authSubject_${currentOrbit._id}`).remove()
                        selectedOrbit.classed("setColorIsSubject", true);

                        selectedOrbit
                            .append("text")
                            .attr("id", `authSubject_${currentOrbit._id}`)
                            .attr("text-anchor", "middle")
                            .attr("x", 0)
                            .attr("font-family", "IBM Plex Sans")
                            .text("AuthSubject");

                        currentAuthSubject = select(`#authSubject_${currentOrbit._id}`)
                        listLabel.push(currentAuthSubject)
                    }

                    if (currentOrbit.isAction) {
                        select(`#authAction_${currentOrbit._id}`).remove()
                        selectedOrbit.classed("setColorIsAction", true);

                        selectedOrbit
                            .append("text")
                            .attr("id", `authAction_${currentOrbit._id}`)
                            .attr("text-anchor", "middle")
                            .attr("x", 0)
                            .attr("font-family", "IBM Plex Sans")
                            .text("AuthAction");

                        currentAuthAction = select(`#authAction_${currentOrbit._id}`)
                        listLabel.push(currentAuthAction)
                    }

                    if (currentOrbit.isVC) {
                        select(`#authVC_${currentOrbit._id}`).remove()
                        selectedOrbit.classed("setColorIsVC", true);

                        selectedOrbit
                            .append("text")
                            .attr("id", `authVC_${currentOrbit._id}`)
                            .attr("text-anchor", "middle")
                            .attr("x", 0)
                            .attr("font-family", "IBM Plex Sans")
                            .text("AuthVC");

                        currentAuthVC = select(`#authVC_${currentOrbit._id}`)
                        listLabel.push(currentAuthVC)
                    }

                    if (currentOrbit.isHolder) {
                        select(`#authHolder_${currentOrbit._id}`).remove()
                        selectedOrbit.classed("setColorIsHolder", true);

                        selectedOrbit
                            .append("text")
                            .attr("id", `authHolder_${currentOrbit._id}`)
                            .attr("text-anchor", "middle")
                            .attr("x", 0)
                            .attr("font-family", "IBM Plex Sans")
                            .text("AuthHolder");

                        currentAuthHolder = select(`#authHolder_${currentOrbit._id}`)
                        listLabel.push(currentAuthHolder)
                    }

                    if (currentOrbit.isAndOperator) {
                        select(`#authAndOperator_${currentOrbit._id}`).remove()
                        selectedOrbit.classed("setColorIsAndOperator", true);

                        selectedOrbit
                            .append("text")
                            .attr("id", `authAndOperator_${currentOrbit._id}`)
                            .attr("text-anchor", "middle")
                            .attr("x", 0)
                            .attr("font-family", "IBM Plex Sans")
                            .text("AND");

                        currentAuthAndOperator = select(`#authAndOperator_${currentOrbit._id}`)
                        listLabel.push(currentAuthAndOperator)
                    }

                    if (currentOrbit.isOrOperator) {
                        select(`#authOrOperator_${currentOrbit._id}`).remove()
                        selectedOrbit.classed("setColorIsOrOperator", true);

                        selectedOrbit
                            .append("text")
                            .attr("id", `authOrOperator_${currentOrbit._id}`)
                            .attr("text-anchor", "middle")
                            .attr("x", 0)
                            .attr("font-family", "IBM Plex Sans")
                            .text("OR");

                        currentAuthOrOperator = select(`#authOrOperator_${currentOrbit._id}`)
                        listLabel.push(currentAuthOrOperator)
                    }

                    if (currentOrbit.isIssuer) {
                        select(`#authIssuer_${currentOrbit._id}`).remove()
                        selectedOrbit.classed("setColorIsIssuer", true);

                        selectedOrbit
                            .append("text")
                            .attr("id", `authIssuer_${currentOrbit._id}`)
                            .attr("text-anchor", "middle")
                            .attr("x", 0)
                            .attr("font-family", "IBM Plex Sans")
                            .text("AuthIssuer");

                        currentAuthIssuer = select(`#authIssuer_${currentOrbit._id}`)
                        listLabel.push(currentAuthIssuer)
                    }

                    if (currentOrbit.isClaim) {
                        select(`#authClaim_${currentOrbit._id}`).remove()
                        selectedOrbit.classed("setColorIsClaim", true);

                        selectedOrbit
                            .append("text")
                            .attr("id", `authClaim_${currentOrbit._id}`)
                            .attr("text-anchor", "middle")
                            .attr("x", 0)
                            .attr("font-family", "IBM Plex Sans")
                            .text("AuthClaim");

                        currentAuthClaim = select(`#authClaim_${currentOrbit._id}`)
                        listLabel.push(currentAuthClaim)
                    }

                    if (listLabel.length === 1) {
                        listLabel[0].attr("y", -50).attr("font-size", "16px");
                    }

                    if (listLabel.length === 2) {
                        listLabel[1].attr("y", -60).attr("font-size", "14px");
                        listLabel[0].attr("y", -45).attr("font-size", "14px");
                    }

                    if (listLabel.length === 3) {
                        listLabel[2].attr("y", -75).attr("font-size", "13px");
                        listLabel[1].attr("y", -60).attr("font-size", "13px");
                        listLabel[0].attr("y", -45).attr("font-size", "13px");
                    }

                    if (listLabel.length === 4) {
                        listLabel[3].attr("y", -90).attr("font-size", "13px");
                        listLabel[2].attr("y", -75).attr("font-size", "13px");
                        listLabel[1].attr("y", -60).attr("font-size", "13px");
                        listLabel[0].attr("y", -45).attr("font-size", "13px");
                    }

                    if (currentOrbit.simplePath  && currentOrbit.__typename === NodeTypename.NIAM_NODE_RELATIONSHIP) {
                        const currentAuthPath = select(`#orbitID_${currentOrbit._id}`)

                        if (currentAuthPath.node()) {
                            select(`#authPath_${currentOrbit._id}`).remove()
                            selectedOrbit.classed("setColorIsPath", true);

                            if (currentAuthResource.node()) {
                                currentAuthResource.attr("y", -45);
                                currentAuthResource.attr("font-size", currentAuthResource.node() ? "14px" : "16px")
                            }
                        }
                    }

                    if (currentOrbit.simplePathCreate && currentOrbit.__typename === NodeTypename.NIAM_NODE_RELATIONSHIP) {
                        const currentAuthPath = select(`#orbitID_${currentOrbit._id}`)

                        if (currentAuthPath.node()) {
                            select(`#authPathCreate_${currentOrbit._id}`).remove()
                            selectedOrbit.classed("setColorIsPath", true);

                            if (currentAuthResource.node()) {
                                currentAuthResource.attr("y", -45);
                                currentAuthResource.attr("font-size", currentAuthResource.node() ? "14px" : "16px")
                            }
                        }
                    }

                    if (currentOrbit.simplePathRead && currentOrbit.__typename === NodeTypename.NIAM_NODE_RELATIONSHIP) {
                        const currentAuthPath = select(`#orbitID_${currentOrbit._id}`)

                        if (currentAuthPath.node()) {
                            select(`#authPathRead_${currentOrbit._id}`).remove()
                            selectedOrbit.classed("setColorIsPath", true);

                            if (currentAuthResource.node()) {
                                currentAuthResource.attr("y", -45);
                                currentAuthResource.attr("font-size", currentAuthResource.node() ? "14px" : "16px")
                            }
                        }
                    }

                    if (currentOrbit.simplePathUpdate && currentOrbit.__typename === NodeTypename.NIAM_NODE_RELATIONSHIP) {
                        const currentAuthPath = select(`#orbitID_${currentOrbit._id}`)

                        if (currentAuthPath.node()) {
                            select(`#authPathUpdate_${currentOrbit._id}`).remove()
                            selectedOrbit.classed("setColorIsPath", true);

                            if (currentAuthResource.node()) {
                                currentAuthResource.attr("y", -45);
                                currentAuthResource.attr("font-size", currentAuthResource.node() ? "14px" : "16px")
                            }
                        }
                    }

                    if (currentOrbit.simplePathDelete && currentOrbit.__typename === NodeTypename.NIAM_NODE_RELATIONSHIP) {
                        const currentAuthPath = select(`#orbitID_${currentOrbit._id}`)

                        if (currentAuthPath.node()) {
                            select(`#authPathDelete_${currentOrbit._id}`).remove()
                            selectedOrbit.classed("setColorIsPath", true);

                            if (currentAuthResource.node()) {
                                currentAuthResource.attr("y", -45);
                                currentAuthResource.attr("font-size", currentAuthResource.node() ? "14px" : "16px")
                            }
                        }
                    }
                }
            });

            orbitSelection
                .append("circle")
                .attr("r", INNER_ORBIT_RADIUS)
                .attr("cx", ZERO)
                .attr("cy", ZERO)
                .attr("filter", () => "url(#innerBlur)")
                .attr("stroke", (data: { __typename: string }) => {
                    if (theme.colors.colorOrbit[data.__typename]) {
                        return `${theme.colors.colorOrbit[data.__typename]}75`;
                    }
                    return `${theme.colors.defaultColorOrbitBorder}`;
                })
                .attr("fill", (data: { __typename: string }) => {
                    if (theme.colors.colorOrbit[data.__typename]) {
                        return `${theme.colors.colorOrbit[data.__typename]}05`;
                    }
                    return `${theme.colors.defaultColorOrbit}`;
                })
                .classed("innerLayer", true);

            orbitSelection
                .append("circle")
                .attr("r", OUTER_ORBIT_RADIUS)
                .attr("cx", ZERO)
                .attr("cy", ZERO)
                .attr("filter", () => "url(#outerBlur)")
                .attr("stroke", (data: { __typename: string }) => {
                    if (theme.colors.colorOrbit[data.__typename]) {
                        return `${theme.colors.colorOrbit[data.__typename]}75`;
                    }
                    return `${theme.colors.defaultColorOrbitBorder}`;
                })
                .attr("fill", (data: { __typename: string }) => {
                    if (theme.colors.colorOrbit[data.__typename]) {
                        return `${theme.colors.colorOrbit[data.__typename]}05`;
                    }
                    return `${theme.colors.defaultColorOrbit}`;
                })
                .classed("outerLayer", true);

            orbitSelection
                .append("circle")
                .attr("r", INNER_CIRCLE_RADIUS)
                .attr("cx", ZERO)
                .attr("cy", ZERO)
                .attr("fill", "#EFEBE9")
                .classed("iconLayer", true);

            orbitSelection
                .append("text")
                .attr("text-anchor", "middle")
                .attr("x", ZERO)
                .attr("y", TEXT_Y)
                .attr("font-family", "IBM Plex Sans")
                .attr("font-size", "16px")
                .text((data: { displayName: string }) => data.displayName);

            orbitSelection
                .append("svg")
                .attr("x", ICON_POSITION)
                .attr("y", ICON_POSITION)
                .append("image")
                .attr("xlink:href", (data: { __typename: string }) => getIconSVG(data.__typename))
                .attr("width", ICON_SIZE)
                .attr("height", ICON_SIZE);
        };
export default enterOrbit;

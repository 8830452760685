import { iD3Data, iData } from "../types";

export const deleteD3Resource = ({
    d3Data,
    relatedTo,
    data,
    setData
}: {
    d3Data: iD3Data;
    relatedTo: string;
    data: iData;
    setData: React.Dispatch<React.SetStateAction<iData>>;
}): iData => {
    const newDataObjects = data.objects.map((o) => {
        if (o._id === relatedTo) {
            o.isResource = false;
        }

        return o;
    });

    const newNodeRelationships = data.nodeRelationships.map((nr) => {
        if (nr._id === relatedTo) {
            nr.isResource = false;
        }

        return nr;
    });

    const newDataCustomMutations = data.customMutations.map((cm) => {
        if (cm._id === relatedTo) {
            cm.isResource = false;
        }

        return cm;
    });

    const newData = {
        ...data,
        objects: newDataObjects,
        nodeRelationships: newNodeRelationships,
        customMutations: newDataCustomMutations
    };

    setData(newData);
    return newData;
};

import { HighlightOff } from "@mui/icons-material";
import { Button, ButtonProps, Input, Table, TableCell, TableRow } from "@mui/material";
// import { ReactComponent as CloseCircleIcon } from "assets/images/icon-close.svg";
import styled from "styled-components";

export const MainContainer = styled.div`
    margin-top: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;

export const StyledTable = styled(Table)`
    position: relative;
    thead tr {
        border-top: 1px solid rgba(224, 224, 224, 1) !important;
    }
    tbody td.role {
        text-transform: capitalize;
    }
    th {
        text-transform: capitalize;
        font-weight: 700;
        text-align: center;

        &.left-aligned {
            text-align: left;
        }
        &.right-aligned {
            text-align: right;
        }
    }

    td {
        text-align: center;

        &.left-aligned {
            text-align: left;
        }
        &.right-aligned {
            text-align: right;
        }
        &.no-border {
            border: none;
        }

        div.multi-cell {
            display: flex;
            justify-content: center;
        }
    }

    @media (max-width: 450px) {
        td {
            max-width: 80px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: pre;
        }
    }
`;

export const AddUserButton = styled(Button).attrs((prop) => {
    const props: ButtonProps = {
        color: prop.color
            ? prop.color
            : "primary",
        variant: "contained"
    };
    return props;
})`
    && {
        text-transform: capitalize;
        width: 130px;
        padding: 7px;
        margin-bottom: 10px;
        box-sizing: border-box;
        border-radius: 4px;
        font-family: IBM Plex Sans;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        align-self: flex-end;
    }
`;

export const LoaderContainer = styled.div`
    margin-top: 50px;
`;

export const CloseCircleIconStyled = styled(HighlightOff)`
    flex: 0;
    width: 20px;
    height: 20px;
    cursor: pointer;
    fill: #bd8e0f;
    transition: all 0.2s;
`;

export const StyledTableCell = styled(TableCell)<{ $border?: boolean; leftAlign?: boolean }>`
    padding-left: 0px !important;
    text-align: ${({ leftAlign }) => leftAlign && "left"}!important;
    border: ${({ $border }) => $border && "none"}!important;
`;

export const StyledTableRow = styled(TableRow)`
    display: flex !important;
    justify-content: flex-end !important;
    td {
        padding-right: 0px !important;
    }
`;
export const HeaderTableContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: none;
    flex-wrap: wrap;
`;
export const StyledSearchInput = styled(Input)`
    font-size: 14px !important;
    margin: 0 16px 16px 16px;
`;

export const EmptyList = styled.div`
    display: flex;
    margin: 50px auto;
    justify-content: center;
`;

import { toastWarning } from "@3edges/utils/dist/toastify";
import { isEmpty, isNotEmpty } from "@3edges/utils/dist/utils";
import React from "react";
import { useTranslation } from "react-i18next";
import
    {
        useAddNewAction, useAddNewActionRelationship,
        useAddNewAndOperator,
        useAddNewClaim, useAddNewEnum,
        useAddNewHolder,
        useAddNewIssuer,
        useAddNewNiamCustomMutation,
        useAddNewNiamInterface,
        useAddNewNiamNodeRelationship,
        useAddNewNiamObject,
        useAddNewNiamScript,
        useAddNewNiamTag,
        useAddNewOrOperator,
        useAddNewPath,
        useAddNewPathCreate,
        useAddNewPathDelete,
        useAddNewPathRead,
        useAddNewPathUpdate,
        useAddNewResource, useAddNewSubject, useAddNewVC, useAddNewVCRelationship
    } from "../RightClickHandlers/OnPageRightClick";
import { NodeTypename, RelationshipType } from "../types";
import * as d3 from "d3";
import { useCanvasContext } from "contexts/canvasContext";

export const checkIfAlreadyExistsVCs = (targetData): boolean => {
    if (targetData.isVC || targetData.isHolder || targetData.isIssuer || targetData.isClaim) {
        return true
    }

    return false
}

export const checkIfAlreadyExistsOperators = (targetData): boolean => {
    if (targetData.isAndOperator || targetData.isOrOperator) {
        return true
    }

    return false
}

const useLeftPanelDragHandlers = (): {
    onDrop: (e: React.DragEvent<SVGSVGElement>) => void;
} => {
    const addNewNiamObject = useAddNewNiamObject();
    const addNewNiamInterface = useAddNewNiamInterface();
    const addNewNiamScript = useAddNewNiamScript();
    const addNewNiamCustomMutation = useAddNewNiamCustomMutation();
    const addNewNiamNodeRelationship = useAddNewNiamNodeRelationship();
    const addNewNiamTag = useAddNewNiamTag();
    const addNewEnum = useAddNewEnum();
    const addNewPath = useAddNewPath();
    const addNewPathCreate = useAddNewPathCreate();
    const addNewPathRead = useAddNewPathRead();
    const addNewPathUpdate = useAddNewPathUpdate();
    const addNewPathDelete = useAddNewPathDelete();
    const addNewResource = useAddNewResource();
    const addNewAction = useAddNewAction();
    const addNewVC = useAddNewVC();
    const addNewHolder = useAddNewHolder();
    const addNewAndOperator = useAddNewAndOperator();
    const addNewOrOperator = useAddNewOrOperator();
    const addNewIssuer = useAddNewIssuer();
    const addNewClaim = useAddNewClaim();
    const addNewSubject = useAddNewSubject();
    const addNewActionRelationship = useAddNewActionRelationship();
    const addNewVCRelationship = useAddNewVCRelationship();
    const { t } = useTranslation();
    const { d3Data } = useCanvasContext();

    return {
        onDrop: (e: React.DragEvent<SVGSVGElement>) => {
            const draggedItemType = e.dataTransfer.getData("type");
            const targetData = e.target["__data__"];

            const [mouseX, mouseY] = d3.pointer(e, d3Data.g.node());

            switch (draggedItemType) {
                case "LPD-Object": {
                    addNewNiamObject({ x: mouseX, y: mouseY });
                    break;
                }
                case "LPD-Interface": {
                    addNewNiamInterface({ x: mouseX, y: mouseY });
                    break;
                }
                case "LPD-Script": {
                    addNewNiamScript({ x: mouseX, y: mouseY });
                    break;
                }
                case "LPD-CustomMutation": {
                    addNewNiamCustomMutation({ x: mouseX, y: mouseY });
                    break;
                }
                case "LPD-Tag": {
                    addNewNiamTag({ x: mouseX, y: mouseY });
                    break;
                }
                case "LPD-NodeRelationship": {
                    addNewNiamNodeRelationship({ x: mouseX, y: mouseY });
                    break;
                }
                case "LPD-Enum": {
                    addNewEnum({ x: mouseX, y: mouseY });
                    break;
                }
                case "LPD-Path": {
                    if (targetData && (targetData.type === RelationshipType.RELATION || [NodeTypename.NIAM_NODE_RELATIONSHIP].includes(targetData.__typename))) {
                        if (isEmpty(targetData.simplePath)) {
                            addNewPath(targetData)
                        } else {
                            toastWarning(t(`validations:path.already.exists`));
                        }
                    } else {
                        toastWarning(t(`validations:path.incorrect.relationship`));
                    }
                    break;
                }
                case "LPD-PathCreate": {
                    if (targetData && (targetData.type === RelationshipType.RELATION || [NodeTypename.NIAM_NODE_RELATIONSHIP].includes(targetData.__typename))) {
                        if(isNotEmpty(targetData.simplePath)){
                            toastWarning(t(`validations:path.already.exists`));

                        } else if (isEmpty(targetData.simplePathCreate)) {
                            addNewPathCreate(targetData)
                        } else {
                            toastWarning(t(`validations:path.already.exists`));
                        }
                    } else {
                        toastWarning(t(`validations:path.incorrect.relationship`));
                    }
                    break;
                }
                case "LPD-PathRead": {
                    if (targetData && (targetData.type === RelationshipType.RELATION || [NodeTypename.NIAM_NODE_RELATIONSHIP].includes(targetData.__typename))) {
                        if(isNotEmpty(targetData.simplePath)){
                            toastWarning(t(`validations:path.already.exists`));

                        } else if (isEmpty(targetData.simplePathRead)) {
                            addNewPathRead(targetData)
                        } else {
                            toastWarning(t(`validations:path.already.exists`));
                        }
                    } else {
                        toastWarning(t(`validations:path.incorrect.relationship`));
                    }
                    break;
                }
                case "LPD-PathUpdate": {
                    if (targetData && (targetData.type === RelationshipType.RELATION || [NodeTypename.NIAM_NODE_RELATIONSHIP].includes(targetData.__typename))) {
                        if(isNotEmpty(targetData.simplePath)){
                            toastWarning(t(`validations:path.already.exists`));

                        } else if (isEmpty(targetData.simplePathUpdate)) {
                            addNewPathUpdate(targetData)
                        } else {
                            toastWarning(t(`validations:path.already.exists`));
                        }
                    } else {
                        toastWarning(t(`validations:path.incorrect.relationship`));
                    }
                    break;
                }
                case "LPD-PathDelete": {
                    if (targetData && (targetData.type === RelationshipType.RELATION || [NodeTypename.NIAM_NODE_RELATIONSHIP].includes(targetData.__typename))) {
                        if(isNotEmpty(targetData.simplePath)){
                            toastWarning(t(`validations:path.already.exists`));

                        } else if (isEmpty(targetData.simplePathDelete)) {
                            addNewPathDelete(targetData)
                        } else {
                            toastWarning(t(`validations:path.already.exists`));
                        }
                    } else {
                        toastWarning(t(`validations:path.incorrect.relationship`));
                    }
                    break;
                }
                case "LPD-Resource": {
                    if (targetData && [NodeTypename.NIAM_OBJECT, NodeTypename.NIAM_CUSTOM_MUTATION].includes(targetData.__typename)) {
                        if (isEmpty(targetData.isResource)) {
                            addNewResource(targetData)
                        } else {
                            toastWarning(t(`validations:isResource.already.exists`));
                        }
                    } else {
                        toastWarning(t(`validations:isResouce.incorrect.orbit`));
                    }
                    break;
                }
                case "LPD-Subject": {
                    if (targetData && [NodeTypename.NIAM_OBJECT].includes(targetData.__typename)) {
                        if (isEmpty(targetData.isSubject)) {
                            addNewSubject(targetData)
                        } else {
                            toastWarning(t(`validations:isSubject.already.exists`));
                        }
                    } else {
                        toastWarning(t(`validations:isSubject.incorrect.orbit`));
                    }
                    break;
                }
                case "LPD-Action": {
                    if (targetData && [NodeTypename.NIAM_OBJECT].includes(targetData.__typename)) {
                        if (isEmpty(targetData.isAction)) {
                            addNewAction(targetData)
                        } else {
                            toastWarning(t(`validations:isAction.already.exists`));
                        }
                    }
                    else if (targetData && (targetData.type === RelationshipType.RELATION || [NodeTypename.NIAM_NODE_RELATIONSHIP].includes(targetData.__typename))) {
                        if (isEmpty(targetData.isAction)) {
                            addNewActionRelationship(targetData)
                        } else {
                            toastWarning(t(`validations:isAction.already.exists`));
                        }
                    }
                    break;
                }
                case "LPD-VC": {
                    if (targetData && [NodeTypename.NIAM_OBJECT].includes(targetData.__typename))
                    {
                        if (checkIfAlreadyExistsVCs(targetData))
                        {
                            toastWarning(t(`validations:vcs.already.exists`));
                        } else
                        {
                            if (isEmpty(targetData.isVC))
                            {
                                addNewVC(targetData)
                            } else
                            {
                                toastWarning(t(`validations:isVC.already.exists`));
                            }
                        }
                    }
                    else if (targetData && (targetData.type === RelationshipType.RELATION || [NodeTypename.NIAM_NODE_RELATIONSHIP].includes(targetData.__typename))) {
                        if (isEmpty(targetData.isVC)) {
                            addNewVCRelationship(targetData)
                        } else {
                            toastWarning(t(`validations:isVC.already.exists`));
                        }
                    } else {
                        toastWarning(t(`validations:isVC.incorrect.orbit`));
                    }
                    break;
                }
                case "LPD-Holder": {
                    if (targetData && [NodeTypename.NIAM_OBJECT].includes(targetData.__typename)) {
                        if (checkIfAlreadyExistsVCs(targetData)) {
                            toastWarning(t(`validations:vcs.already.exists`));
                        } else {
                            if (isEmpty(targetData.isHolder)) {
                                addNewHolder(targetData)
                            } else {
                                toastWarning(t(`validations:isHolder.already.exists`));
                            }
                        }
                    } else {
                        toastWarning(t(`validations:isHolder.incorrect.orbit`));
                    }
                    break;
                }
                case "LPD-AndOperator": {
                    if (targetData && [NodeTypename.NIAM_OBJECT].includes(targetData.__typename)) {

                        if (isEmpty(targetData.isAndOperator)) {
                            if (checkIfAlreadyExistsOperators(targetData)) {
                                toastWarning(t(`validations:operator.already.exists`));
                            } else {
                                addNewAndOperator(targetData)
                            }
                        } else {
                            toastWarning(t(`validations:isAndOperator.already.exists`));
                        }

                    } else {
                        toastWarning(t(`validations:isAndOperator.incorrect.orbit`));
                    }
                    break;
                }
                case "LPD-OrOperator": {
                    if (targetData && [NodeTypename.NIAM_OBJECT].includes(targetData.__typename)) {

                        if (isEmpty(targetData.isOrOperator)) {
                            if (checkIfAlreadyExistsOperators(targetData)) {
                                toastWarning(t(`validations:operator.already.exists`));
                            } else {
                                addNewOrOperator(targetData)
                            }
                        } else {
                            toastWarning(t(`validations:isOrOperator.already.exists`));
                        }

                    } else {
                        toastWarning(t(`validations:isOrOperator.incorrect.orbit`));
                    }
                    break;
                }
                case "LPD-Issuer": {
                    if (targetData && [NodeTypename.NIAM_OBJECT].includes(targetData.__typename))
                    {
                        if (checkIfAlreadyExistsVCs(targetData))
                        {
                            toastWarning(t(`validations:vcs.already.exists`));
                        } else
                        {
                            if (isEmpty(targetData.isIssuer))
                            {
                                addNewIssuer(targetData)
                            } else
                            {
                                toastWarning(t(`validations:isIssuer.already.exists`));
                            }
                        }
                    } else {
                        toastWarning(t(`validations:isIssuer.incorrect.orbit`));
                    }
                    break;
                }
                case "LPD-Claim": {
                    if (targetData && [NodeTypename.NIAM_OBJECT].includes(targetData.__typename))
                    {
                        if (checkIfAlreadyExistsVCs(targetData))
                        {
                            toastWarning(t(`validations:vcs.already.exists`));
                        } else
                        {
                            if (isEmpty(targetData.isClaim))
                            {
                                addNewClaim(targetData)
                            } else
                            {
                                toastWarning(t(`validations:isClaim.already.exists`));
                            }
                        }
                    } else {
                        toastWarning(t(`validations:isClaim.incorrect.orbit`));
                    }
                    break;
                }

            }

            e.dataTransfer.clearData();
        }
    };
};

export default useLeftPanelDragHandlers;

// @flow
/* eslint-disable @typescript-eslint/no-type-alias */
import * as d3 from "d3";
import { ReactNode } from "react";
import { DefaultTheme } from "styled-components";
import
    {
        getPrimGInfo_niamDataServer_niamCustomMutations as NiamCustomMutations,
        getPrimGInfo_niamDataServer_niamEnums as NiamEnums,
        rPImplementGet_niamImplement as NiamImplement,
        getPrimGInfo_niamDataServer_niamInterfaces as NiamInterfaces,
        getPrimGInfo_niamDataServer_niamNodeRelationships as NiamNodeRelationships,
        getPrimGInfo_niamDataServer_niamObjects as NiamObjects,
        getPrimGInfo_niamDataServer_niamObjects_nodeRelationships as NiamObjectsNodeRelation,
        getPrimGInfo_niamDataServer_niamScripts as NiamScripts,
        getPrimGInfo_niamDataServer_niamTags as NiamTags
    } from "types/operation-result-types";
import { Maybe, RPOrbitData } from "../../typedefs";
import { DataPoint } from "./Zoom/types";
import { NewDataItem } from "./addD3Items/types";

export enum RelationshipType {
    RELATION = "relation",
    IMPLEMENT = "implement",
    PATHS = "paths",
    NODE_RELATION = "nodeRelation",
}

export enum NodeTypename {
    NIAM_OBJECT = "NiamObject",
    NIAM_CUSTOM_MUTATION = "NiamCustomMutation",
    NIAM_ENUM = "NiamEnum",
    NIAM_TAG = "NiamTag",
    NIAM_SCRIPT = "NiamScript",
    NIAM_INTERFACE = "NiamInterface",
    NIAM_NODE_RELATIONSHIP = "NiamNodeRelationship",
}

export enum MenuOptionsType
{
    ADD = "ADD",
    HR = "HR",
    DEL = "DEL"
}

export enum EDeleteType {
    CUSTOM_MUTATION = "customMutation",
    IMPLEMENT = "implement",
    PATHS = "paths",
    INTERFACE = "interface",
    NODE_RELATIONSHIP = "nodeRelationship",
    OBJECT = "object",
    RELATIONSHIP = "relationship",
    SCRIPT = "script",
    TAG = "tag",
    ENUM = "enum",
    SIMPLE_PATH = "simplePath",
    SIMPLE_PATH_CREATE = "simplePathCreate",
    SIMPLE_PATH_READ = "simplePathRead",
    SIMPLE_PATH_UPDATE = "simplePathUpdate",
    SIMPLE_PATH_DELETE = "simplePathDelete",
    AUTHZ_SUBJECT = "subject",
    AUTHZ_ACTION = "action",
    AUTHZ_RESOURCE = "resource",
    AUTHZ_VC = "vc",
    AUTHZ_HOLDER = "holder",
    AUTHZ_ISSUER = "issuer",
    AUTHZ_CLAIM = "claim",
    AUTHZ_AND_OPERATOR = "AndOperator",
    AUTHZ_OR_OPERATOR = "OrOperator"
}

export enum EUpdateType {
    OBJECT = "object",
    SCRIPT = "script",
    CUSTOM_MUTATION = "customMutation",
    NODE_RELATIONSHIP = "nodeRelationship",
    INTERFACE = "interface",
    RELATIONSHIP = "relationship",
    TAG = "tag",
    ENUM = "enum"
}
export interface iD3Data {
    nodes: d3.Selection<d3.BaseType, unknown, SVGGElement, unknown>;
    links: d3.Selection<d3.BaseType, unknown, SVGGElement, unknown>;
    simulation: d3.Simulation<unknown, undefined>;
    g: d3.Selection<d3.BaseType, unknown, SVGGElement, unknown>;
    zoomOnLinksInstance: (d: d3.Selection<SVGGElement, unknown, SVGGElement, unknown>) => void;
    zoomOnNodesInstance:
        | ((event: React.MouseEvent, parent: RPOrbitData | NiamSystemItem | (iData & NewDataItem)) => void)
        | null;
    removeZoom?: (container: ReactNode) => void;
}

export type iD3Selection = d3.Selection<any, unknown, any, unknown>;

export type NodeRelationship = Point2D & NiamObjectsNodeRelation & {
    relation: NiamNodeRelationships;
    _id: string;
    name: string;
    displayName: string | null;
} & Maybe<{
    isResource?: boolean;
    simplePath?: { _id: string; relatedTo: string };
    simplePathCreate?: { _id: string; relatedTo: string };
    simplePathRead?: { _id: string; relatedTo: string };
    simplePathUpdate?: { _id: string; relatedTo: string };
    simplePathDelete?: { _id: string; relatedTo: string };
    fields?: { relatedTo: any; _id: string; name: string }[];
    isAction?: boolean;
    isVC?: boolean;
}>;

export type NiamImplementElement = DataPoint & {
    source: DataPoint["source"] | string;
    target: DataPoint["target"] | string;
    curve: number;
    isShown: boolean;
    relation: NodeRelationship["relation"];
    relatedTo?: string | null;
} & Omit<NiamImplement, "source" | "target"> & Maybe<{
    isPath?: boolean;
    isPathCreate?: boolean;
    isPathRead?: boolean;
    isPathUpdate?: boolean;
    isPathDelete?: boolean;
    eventType?: string;
    simplePath?: { _id: string; relatedTo: string };
    simplePathCreate?: { _id: string; relatedTo: string };
    simplePathRead?: { _id: string; relatedTo: string };
    simplePathUpdate?: { _id: string; relatedTo: string };
    simplePathDelete?: { _id: string; relatedTo: string };
    isAction?: boolean;
    isVC?: boolean;
}>;

export type NiamPathElement = DataPoint & {
    source: DataPoint["source"] | string;
    target: DataPoint["target"] | string;
    curve: number;
    isShown: boolean;
    relation: NodeRelationship["relation"];
} & Omit<NiamImplement, "source" | "target">;

export type NiamEnum = NiamEnums & Maybe<{ isShown?: boolean }>;

export type NiamInterface = NiamInterfaces & Maybe<{ isShown?: boolean }>;

export type NiamObject = NiamObjects & Maybe<{ isShown?: boolean; item: { _id: string; name: string }; }>;

export type NiamScript = NiamScripts & Maybe<{ isShown?: boolean }>;

export type NiamCustomMutation = NiamCustomMutations & Maybe<{ isShown?: boolean, isResource?: boolean }>;

export type NiamTag = NiamTags & Maybe<{ isShown?: boolean }>;

export interface iData {
    interfaces: NiamInterface[];
    objects: NiamObject[];
    scripts: NiamScript[];
    customMutations: NiamCustomMutation[];
    nodeRelationships: NodeRelationship[];
    implementLinks: NiamImplementElement[];
    paths: NiamPathElement[];
    relLinks: NiamImplementElement[];
    tags: NiamTag[];
    enums?: NiamEnum[];
}

export type NiamSystemItem =
    | NiamInterface
    | NiamObject
    | NiamScript
    | NiamCustomMutation
    | NodeRelationship
    | NiamImplementElement
    | NiamTag
    | NiamEnum;

export interface iCleanedData {
    primObjects: NiamObject[];
    primInterfaces: NiamInterface[];
    primScripts: NiamScript[];
    primCustomMutations: NiamCustomMutation[];
    primNodeRelationships: NodeRelationship[];
    primImplements: NiamImplementElement[];
    primPropertiesRelLinks: NiamImplementElement[];
    primPaths: NiamPathElement[];
    primTags: NiamTag[];
    primEnums: NiamEnum[];
}
export interface OrbitItem {
    _id: string;
    __typename: string;
    type?: string;
    simplePath?: { _id: string, relatedTo: string };
    simplePathCreate?: { _id: string, relatedTo: string };
    simplePathRead?: { _id: string, relatedTo: string };
    simplePathUpdate?: { _id: string, relatedTo: string };
    simplePathDelete?: { _id: string, relatedTo: string };
    isAction?: boolean;
    isVC?: boolean;
    isHolder?: boolean;
    isAndOperator?: boolean;
    isOrOperator?: boolean;
    isIssuer?: boolean;
    isClaim?: boolean;
    isResource?: boolean;
    isSubject?: boolean;
    interfaces: { _id: string }[];
    name: string;
    fields: {
        relatedTo: string;
        name: string;
    }[];
    nodeRelationships: { rel?: { name: string } }[];
    relationships: { name: string; relatedTo: string }[];
}

export interface iNewData {
    newInterfaces?: NiamInterface[];
    newObjects?: NiamObject[];
    newScripts?: NiamScript[];
    newCustomMutations?: NiamCustomMutation[];
    newNodeRelationships?: NodeRelationship[];
    newImplements?: NiamImplement[];
    newProperty?: NiamImplement[];
    newTags?: NiamTag[];
    newEnums?: NiamEnum[];
}

export interface iJoin {
    d3Data: iD3Data;
    theme: DefaultTheme;
    onObjectRightClickGenerator: ({
        Ointerfaces,
        Oobjects,
        setNewData
    }: {
        Ointerfaces: unknown[];
        Oobjects: unknown[];
        setNewData: React.Dispatch<React.SetStateAction<iNewData>>;
    }) => (e: React.MouseEvent<Element, MouseEvent>, Oobject: unknown) => void;
    onInterfaceRightClickGenerator: ({
        Ointerfaces,
        Oobjects,
        setNewData
    }: {
        Ointerfaces: NiamInterface[];
        Oobjects: NiamObject[];
        setNewData: React.Dispatch<React.SetStateAction<iNewData>>;
    }) => (e: React.MouseEvent<Element, MouseEvent>, Oobject: unknown) => void;
    setNewData: React.Dispatch<React.SetStateAction<iNewData>>;
    d3NewInterfaces?: NiamInterface[];
    d3NewObjects?: NiamObject[];
}

export interface Point2D {
    x: number;
    y: number;
    fx: number;
    fy: number;
    isShown: boolean;
}

export type PrimCanvasPoint<T> = Point2D & T;
export type PrimCanvasPointObject<T> = PrimCanvasPoint<T> & { item: T };

import { gql } from "@apollo/client";

export const PRIM_CREATE_NEW_USER = gql`
  mutation addUser($email: String!, $name: String, $password: String!, $ip: String!, $emailOptions: emailOptionsInput) {
      addNewUser(input: { email: $email, name: $name, password: $password, ip: $ip, emailOptions: $emailOptions }) {
          _id
          email
          randomCode
      }
  }
`;

export const PRIM_CHECK_IF_USER_ALREADY_EXISTS = gql`
  query checkIfUserAlreadyExists($email: String!) {
      checkIfUserAlreadyExists(input: { email: $email }) {
          _id
          isVerified
          isExpiredVerifyCode
          tenants {
              tenantID: _id
          }
      }
  }
`;

export const PRIM_GET_NEW_VERIFICATION_CODE = gql`
  query getNewVerificationCode($email: String!, $emailOptions: emailOptionsInput) {
      getNewVerificationCode(input: { email: $email, emailOptions: $emailOptions }) {
          randomCode
      }
  }
`;

/* tslint:disable */
/* eslint-disable */
// @flow
import { gql } from "@apollo/client";
// @flow
import
  {
    getPrimGInfo_niamDataServer_niamObjects_fields as INiamField,
    getPrimGInfo_niamDataServer_niamCustomMutations as NiamCustomMutations,
    getPrimGInfo_niamDataServer_niamInterfaces as NiamInterfaces,
    getPrimGInfo_niamDataServer_niamObjects as NiamObjects
  } from "types/operation-result-types";

export interface SourceDataRelationship {
    source: SourceData;
    target: SourceData;
    name: string;
    relatedTo: string;
    curve?: number;
    isShown: boolean;
    index: number;
    type: string;
    _id: string;
    displayName: string;
}
export interface SourceData {
    customMutations: NiamCustomMutations[];
    diplayName: string;
    fields: SourceDataRelationship[];
    index: number;
    interfaces: NiamInterfaces[];
    isShown: boolean;
    name: string;
    objects: NiamObjects[];
    relationships: SourceDataRelationship[];
    vx: number;
    vy: number;
    x: number;
    y: number;
    __typename: string;
    _id: string;
}


export interface IItemSelected {
  data: any,
  source?: any,
  hasSaved: boolean;
}
export interface TargetData {
    displayName: string;
    fields: INiamField[];
    index: number;
    interfaces: NiamInterfaces[];
    isShown: boolean;
    name: string;
    vx: number;
    vy: number;
    x: number;
    y: number;
    __typename: string;
    _id: string;
}

export interface IDropData {
    targetData: {
        data: TargetData;
        __typename: string;
        _id: string;
    };
    sourceData: {
        data: SourceData;
        _id: string;
    };
    setItemSelected: React.Dispatch<any>;
    setTargetData: React.Dispatch<IDropData["targetData"]>;
    setSourceData: React.Dispatch<IDropData["sourceData"]>;
    zoom: any;
    isDrawing: boolean;
    setIsDrawing: React.Dispatch<boolean>;
}

export type Maybe<T> = T | null;

export interface RPState {
  isLoading: boolean;
  hasSaved: boolean;
  messagePrompting: boolean;
  isNew: boolean;
}
export interface RPOrbitData {
  _id: string;
  displayName: string;
  type: "CustomMutation" | "Enum" | "NiamEnum" | "Interface" | "NodeRelationship" | "Object" | "Script" | "Tag" | "Authorization";
  x: number;
  y: number;
  __typename: string;
  isSubject?: boolean;
}

export interface RPPropertyData {
  _id: string;
  type: "Enum" | "NiamEnum" | "Implement" | "Property" | "PropertyNodeRelationship" | "PropertyRelationship";
  parent: RPOrbitData;
  relatedTo?: RPOrbitData;
}

export type RPData = RPOrbitData | RPPropertyData;

export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Upload: unknown;
}

export default gql`
  extend type Query {
      token: String
      user: User
  }
`;

export enum AccessTokenType {
  jwt = "jwt",
  opaque = "opaque",
}

export enum OIDCProviderEnum {
  THREEEDGES = "3edges",
  EXTERNAL = "External",
}

export enum IExternalProvider {
  ForgeRock = "ForgeRock",
  Auth0 = "Auth0",
  Okta = "Okta",
  Other = "Other"
}

/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/jsx-wrap-multilines */
import React, { createContext, useContext, useState } from "react";
import { useEnters } from "ui-components/canvas-d3-components";
import { iD3Data, iD3Selection, iData } from "../../components/PrimGraphicalCanvas/types";

interface CanvasContextProps {
    d3Data: iD3Data;
    data: iData;
    setD3Data: React.Dispatch<React.SetStateAction<iD3Data>>;
    setData: React.Dispatch<React.SetStateAction<iData>>;
    container: React.ReactNode;
    setContainer: (node: React.ReactNode) => void;
    enters: {
        enterOrbit: (selection: iD3Selection) => void;
        updateOrbit: (selection: iD3Selection) => void;
        enterLink: (selection: iD3Selection) => void;
        updateLink: (selection: iD3Selection) => void;
    };
}

export const CanvasContext = createContext<CanvasContextProps>(undefined);

export function CanvasContextProvider({ children }: { children: React.ReactNode }): React.ReactElement {
    const [container, setContainer] = useState<React.ReactNode>();

    const [data, setData] = useState<iData>({
        paths: undefined,
        implementLinks: undefined,
        interfaces: undefined,
        objects: undefined,
        scripts: undefined,
        customMutations: undefined,
        tags: undefined,
        nodeRelationships: undefined,
        relLinks: undefined,
        enums: undefined
    });

    const [d3Data, setD3Data] = useState<iD3Data>({
        g: undefined,
        links: undefined,
        nodes: undefined,
        simulation: undefined,
        zoomOnLinksInstance: undefined,
        zoomOnNodesInstance: undefined
    });

    const enters = useEnters();

    return <CanvasContext.Provider value={{
        d3Data,
        setData,
        setD3Data,
        data,
        container,
        setContainer,
        enters
    }}>{children}</CanvasContext.Provider>
}
export const useCanvasContext = (): CanvasContextProps => useContext(CanvasContext);

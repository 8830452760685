import styled from "styled-components";

export const ContainerBottomRight = styled.div`
    border: 1px solid #D5D5D5;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 525px;
    height: 55px;
    z-index: 2;
`

export const MainNavBar = styled.div`
    position: absolute;
    top: 12px;
    left: 12px;
    display: flex;
    flex-direction: row;
    height: 48px;
    padding: 11px 20px 12px 16px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);
    background-color: ${({ theme }) => theme.colors.white};
`;

export const MainNavBarItem = styled.div`
    flex: auto;
    white-space: nowrap;
    flex-wrap: nowrap;
    height: 40px;
    line-height: 40px;
    font-family: IBM Plex Sans;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: ${({ theme }) => theme.colors.fontNormal};
    :hover {
        color: ${({ theme }) => theme.colors.primary};
    }
    user-select: none;
`;

export const MainNavBarMainItem = styled(MainNavBarItem)`
    font-family: IBM Plex Sans Condensed;
    font-size: 20px;
    letter-spacing: 4px;
    height: 25px;
`;

export const MainNavBarVBR = styled.div`
    flex: 0;
    height: 100%;
    min-width: 1px;
    background-color: ${({ theme }) => theme.colors.borderLight};
    margin: 0px 20px;
`;

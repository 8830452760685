/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useCanvasContext } from "contexts/canvasContext";
import { useData } from "contexts/dataContext";
import { Selection } from "d3-selection";
import React, { useEffect, useState } from "react";
import { RPPropertyData } from "typedefs";
import RpAuthorization from "./RpAuthorization";
import RPCustomMutation from "./RpCustomMutation";
import RpEnum from "./RpEnum";
import RPImplement from "./RpImplement";
import RPInterface from "./RpInterface";
import RPNodeRelationship from "./RpNodeRelationship";
import RPObject from "./RpObject";
import RPProperty from "./RpProperty";
import RPPropertyRelationship from "./RpPropertyRelationship";
import RPScript from "./RpScript";
import RPTag from "./RpTag";
import { RPHeader, RightPanelContainer } from "./styled";

const removeColorFromAllOrbits = () => {
    document.querySelectorAll(".orbitCore").forEach((e) => {
        e.classList.remove("setColorOrbit");
    });

    document.querySelectorAll(".link").forEach((e) => {
        e.classList.remove("setColorLine");
    });
}
interface RightPanelProps {
    isShowDashboard?: boolean;
}

function RightPanel ({ isShowDashboard }: RightPanelProps): React.ReactElement {
    const [isOpen, setIsOpen] = useState(false);

    const { itemSelected } = useData();
    const { container } = useCanvasContext();

    useEffect(() => {
        if (!isShowDashboard && itemSelected && !itemSelected.data["isSubject"]) {
            setIsOpen(false)
        }
    }, [isShowDashboard, itemSelected]);

    const setColorToRelation = (): void => {
        // Define color to line
        if (container && (itemSelected.data as RPPropertyData).parent) {
           const currentLine = (container as Selection<Element, unknown, Window, unknown>).select(`#relationshipID_${itemSelected.data._id}`);
           currentLine.classed("setColorLine", true);
       }
   }

    const setColorToOrbit = (): void => {
       const idOrbit: string = (itemSelected.data as RPPropertyData).parent ? (itemSelected.data as RPPropertyData).parent._id : itemSelected.data._id;

       if (container && (isShowDashboard || !(itemSelected.data as RPPropertyData).parent)) {
           // Set color to one orbit
           const currentOrbit = (container as Selection<Element, unknown, Window, unknown>).select(`#orbitID_${idOrbit}`);
           currentOrbit.select("g").classed("setColorOrbit", true);
       }
   }

    useEffect(() => {
        if (itemSelected && itemSelected.data) {
            setIsOpen(true);

            // Remove color from all orbit
            removeColorFromAllOrbits();

            setColorToOrbit()
            setColorToRelation()
        } else {
            setIsOpen(false);
            removeColorFromAllOrbits();
        }

    }, [itemSelected]);

    useEffect(() => {
        if (!isOpen && itemSelected && itemSelected.data) {
            // Remove color from all orbit
            removeColorFromAllOrbits();
        }

    }, [isOpen]);

    if (!isShowDashboard && itemSelected && !itemSelected.data["isSubject"]) {
        return null
    }

    if (!itemSelected || !isOpen) {
        return null
    }

    const RPElements = {
        Authorization: <RpAuthorization isOpen={isOpen} />,
        Object: <RPObject isOpen={isOpen} setIsOpen={setIsOpen} />,
        Interface: <RPInterface isOpen={isOpen} setIsOpen={setIsOpen} />,
        Script: <RPScript isOpen={isOpen} setIsOpen={setIsOpen} />,
        CustomMutation: <RPCustomMutation isOpen={isOpen} setIsOpen={setIsOpen} />,
        Implement: <RPImplement isOpen={isOpen} setIsOpen={setIsOpen} />,
        NodeRelationship: <RPNodeRelationship isOpen={isOpen} setIsOpen={setIsOpen} />,
        PropertyRelationship: <RPPropertyRelationship isOpen={isOpen} setIsOpen={setIsOpen} />,
        Tag: <RPTag isOpen={isOpen} setIsOpen={setIsOpen} />,
        Enum: <RpEnum isOpen={isOpen} setIsOpen={setIsOpen} />,
        Property: <RPProperty isOpen={isOpen} setIsOpen={setIsOpen} />,
    };

    const renderRPOpenerIcon = (): React.ReactElement => (
        <RightPanelContainer $isShowing={isOpen}>
            <RPHeader />

            {RPElements[isShowDashboard ? itemSelected.data.type : "Authorization"] || null}
        </RightPanelContainer>
    );

    return renderRPOpenerIcon()
}

export default RightPanel;

import logoImage from "assets/images/logo.png";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Description, LogoContainer, MainContainer, RedirectButton } from "./styled";

function NotFoundPage({ isAuth }: { isAuth?: boolean }): React.ReactElement {
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <MainContainer>
            <LogoContainer>
                <img src={logoImage} alt="logo" />
            </LogoContainer>

            <Description>{t("auth:page-not-found")}</Description>

            <RedirectButton
                onClick={() => {
                    history.push("/");
                }}
            >
                {isAuth ? t("auth:redirect-home-page") : t("auth:redirect-sign-in")}
            </RedirectButton>
        </MainContainer>
    );
}

export default NotFoundPage;

import { iD3Data, iData } from "../types";

export const deleteD3OrOperator = ({
    d3Data,
    relatedTo,
    data,
    setData
}: {
    d3Data: iD3Data;
    relatedTo: string;
    data: iData;
    setData: React.Dispatch<React.SetStateAction<iData>>;
}): iData => {
    const newDataObjects = data.objects.map((o) => {
        if (o._id === relatedTo) {
            o.isOrOperator = false;
        }

        return o;
    });

    const newData = {
        ...data,
        objects: newDataObjects,
    };

    setData(newData);
    return newData;
};

import { CenteredContainer } from "pages/Canvas/styled";
import styled from "styled-components";

export const LoginCenteredContainer = styled(CenteredContainer)<{ width?: string; }>`
    width: ${({ width }) => (width ? width : "500px")};
    max-width: ${({ width }) => (width ? width : "500px")};
    min-width: 500px;
    padding: 48px 40px 40px;
    border-radius: 12px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
`;

export const LoginTitle = styled.p`
    flex: 0;
    height: 37px;
    margin: 0 110px 40px;
    font-family: IBM Plex Sans;
    font-size: 28px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;
    text-align: left;
    color: ${(props) => props.theme.colors.fontNormal};
`;

export const LoginBox = styled.div`
    margin-top: -16px;
    margin-bottom: -16px;
    box-sizing: border-box;
    width: 100%;
    flex: 1;
`;

export const LoginForm = styled.form`
    margin-top: -16px;
    margin-bottom: -16px;
    box-sizing: border-box;
    width: 100%;
    flex: 1;
`;

export const ContainerRegister = styled.div<{ $flexEnd?: string }>`
    display: flex;
    flex-direction: row;
    justify-content: ${({ $flexEnd }) => ($flexEnd === 'false' ?  "flex-end" : "space-between")};
`;

export const ItemContainerRegister = styled.h3`
    font-size: 16px;
    color: #1e88e5;
    cursor: pointer;

    :hover {
        color: #1360a4;
    }
`;

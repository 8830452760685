import { useContext } from "react";
import { CanvasContext } from "../../../contexts/canvasContext";
import { NewDataItem } from "../addD3Items/types";
import { EUpdateType } from "../types";
import { updateD3Orbit } from "./updateD3Orbit";

export const useUpdateD3Items = (): (({ type, id, newData, rPData }: { type: EUpdateType; id: string; newData: NewDataItem; rPData: any; }) => void) => {
    const { d3Data, data, setData, enters } = useContext(CanvasContext);

    return ({ type, id, newData, rPData }) => {
        switch (type) {
            case EUpdateType.OBJECT:
            case EUpdateType.SCRIPT:
            case EUpdateType.CUSTOM_MUTATION:
            case EUpdateType.RELATIONSHIP:
            case EUpdateType.NODE_RELATIONSHIP:
            case EUpdateType.INTERFACE:
            case EUpdateType.TAG:
            case EUpdateType.ENUM: {
                updateD3Orbit({
                    rPData,
                    d3Data,
                    data,
                    setData,
                    orbitID: id,
                    newData,
                    type,
                    enters
                });
                break;
            }
        }
    };
};

import { iD3Data, iData } from "../types";

export const deleteD3VC = ({
    d3Data,
    relatedTo,
    data,
    setData
}: {
    d3Data: iD3Data;
    relatedTo: string;
    data: iData;
    setData: React.Dispatch<React.SetStateAction<iData>>;
}): iData => {
    const newDataObjects = data.objects.map((o) => {
        if (o._id === relatedTo) {
            o.isVC = false;
        }

        o.fields = o.fields.map(f => {
            if (f._id === relatedTo) {
                f.isVC = false;
            }

            return f
        })

        return o;
    });

    const newDataRels = data.relLinks.map((rl) => {
        if (rl._id === relatedTo) {
            rl.isVC = false;
        }

        return rl;
    });

    const newNodeRelationships = data.nodeRelationships.map((nr) => {
        if (nr._id === relatedTo) {
            nr.isVC = false;
        }

        return nr;
    });

    const newData = {
        ...data,
        objects: newDataObjects,
        relLinks: newDataRels,
        nodeRelationships: newNodeRelationships
    };

    setData(newData);
    return newData;
};

/* eslint-disable max-lines */
import { gql } from "@apollo/client";

export const GET_TENANT_BY_API_ID = gql`
  query GetTenantByApiID($serverID: ID!) {
    getTenantByApiID(input: { serverID: $serverID }) {
        _id
        name
        description
        tenant {
            _id
            administrators {
                roles
                email
            }
            owners {
                _id
                email
            }
        }
      }
  }
`;

export const GET_PRIM_INFO = gql`
  query getPrimGInfo($serverID: ID) {
      niamDataServer(input: { serverID: $serverID }) {
          _id
          name
          description
          CLUSTER_SELECTED
          tenant {
            _id
          }
          niamObjects {
              _id
              x
              y
              displayName
              name
              description
              isAction
              isResource
              isSubject
              isVC
              isHolder
              isAndOperator
              isOrOperator
              isIssuer
              isClaim
              simplePath {
                relatedTo
                _id
              }
              simplePathCreate {
                relatedTo
                _id
              }
              simplePathRead {
                relatedTo
                _id
              }
              simplePathUpdate {
                relatedTo
                _id
              }
              simplePathDelete {
                relatedTo
                _id
              }
              paths {
                targetID
                _id
              }
              fields {
                  _id
                  displayName
                  name
                  fieldType
                  relatedTo
                  isVC
                  isAction
              }
              interfaces {
                  _id
              }
              nodeRelationships {
                  objFrom {
                      _id
                      name
                      displayName
                  }
                  objTo {
                      _id
                      name
                      displayName
                  }
                  rel {
                      _id
                      name
                      displayName
                      x
                      y
                      description
                      isResource
                      isAction
                      isVC
                      simplePath {
                            relatedTo
                            _id
                        }
                      simplePathCreate {
                            relatedTo
                            _id
                        }
                      simplePathRead {
                            relatedTo
                            _id
                        }
                      simplePathUpdate {
                            relatedTo
                            _id
                        }
                      simplePathDelete {
                            relatedTo
                            _id
                        }
                      fields {
                          _id
                          displayName
                          name
                          fieldType
                          relatedTo
                      }
                  }
              }
          }
          niamNodeRelationships {
              objFrom {
                  _id
                  name
                  displayName
              }
              objTo {
                  _id
                  name
                  displayName
              }
              script {
                  _id
                  name
                  displayName
              }
              relScript {
                  _id
                  name
                  displayName
              }
              relFrom {
                  _id
                  name
                  displayName
              }
              relTo {
                  _id
                  name
                  displayName
              }
              rel {
                  _id
                  x
                  y
                  name
                  displayName
                  isResource
                  isAction
                  isVC
                  simplePath {
                        relatedTo
                        _id
                    }
                  simplePathCreate {
                        relatedTo
                        _id
                    }
                  simplePathRead {
                        relatedTo
                        _id
                    }
                  simplePathUpdate {
                        relatedTo
                        _id
                    }
                  simplePathDelete {
                        relatedTo
                        _id
                    }
                  fields {
                      _id
                      displayName
                      name
                      fieldType
                      relatedTo
                  }
              }
          }
          niamInterfaces {
              _id
              x
              y
              name
              displayName
              fields {
                  _id
                  displayName
                  name
                  fieldType
                  relatedTo
              }
          }
          niamScripts {
              _id
              x
              y
              name
              displayName
              fields {
                  _id
                  displayName
                  name
                  fieldType
                  relatedTo
              }
              relationships {
                  _id
                  name
                  displayName
                  eventType
                  relatedTo
                  fieldType
              }
              objects {
                  _id
              }
          }
          niamCustomMutations {
              _id
              x
              y
              isResource
              name
              displayName
              inputSchema
              args
              mutatuinName
              returnType
              script
          }
          niamTags {
              _id
              x
              y
              name
              displayName
          }
          niamEnums {
              _id
              x
              y
              name
              displayName
              enumerates
          }
      }
  }
`;

export const GET_MODAL_INFO = gql`
  query getModalInfo($serverID: ID!) {
    getModalInfo(input: { serverID: $serverID }) {
        _id
        name
        description
        connectionURL
        clientNameGCP
        CLUSTER_SELECTED
        clusterAutopilot
        clusterLocation
        serviceAccount {
            _id
            cloud
        }
        cluster {
            _id
            clusterID
            name
            location
        }
        autoDeployApiServer
        dbType
        dbVersion
        dbHost
        dbName
        dbUser
        dbPass
        lastUpdate
        MAX_TROTTLE_COST
        NEO4J_POOL_SIZE
        NEO4J_MAX_CONNECTION_LIFETIME
        NEO4J_CONNECTION_ACQUISITION_TIMEOUT_MS
        NEO4J_CONNECTION_TIMEOUT
        EXTERNAL_PROVIDER
        OIDC_URL
        CLAIMS_ARRAY
        AUTHN_QUERY
        EXTERNAL_OIDC_URL
        OIDC_TOKEN_ENDPOINT
        OIDC_TOKEN_INTROSPECTION_ENDPOINT
        ACCESS_TOKEN_TYPE
        OIDC_JWKS_URI
        EXTERNAL_PROVIDER_NAME
        OIDC_ME_ENDPOINT
        OIDC_AUTHORIZE_ENDPOINT
        OIDC_LOGOUT_ENDPOINT
        OIDC_PROVIDER_CLAIMS_MAPPING
        OIDC_CLIENT_ID
        OIDC_CLIENT_PWD
        authZServer {
            _id
            connectionURL
            lastUpdate
            defaultOperator
            allowByDefault
            selfRelDepth
            isMostPermissive
            actionPermissionOrder
        }
        authzCSPConnectionURL
        dashboardServerURL
        lastReleaseDate
        apiKey
        apiEnabled
        superUserID
        emailServerEmailFrom
        emailServerNameFrom
        emailServerSmtp
        emailServerApiKey
        emailServerApiSecret
        emailServerPort
    }
  }
`;

export const ADD_NEW_DEFAULT_PRIM_OBJECT = gql`
  mutation addNewNiamObjectToServer($serverID: ID!, $name: String!, $displayName: String, $x: Float, $y: Float) {
      addNewNiamObjectToServer(
          input: { serverID: $serverID, newNiamObjectInput: { name: $name, displayName: $displayName, x: $x, y: $y } }
      ) {
          _id
          displayName
          name
          interfaces {
              _id
          }
          x
          y
      }
  }
`;

export const ADD_NEW_DEFAULT_PRIM_INTERFACE = gql`
  mutation addNewNiamInterfaceToNiamDataServer($serverID: ID!, $name: String!, $displayName: String, $x: Float, $y: Float) {
      addNewNiamInterfaceToNiamDataServer(
          input: { niamDataServerID: $serverID, newNiamInterfaceInput: { name: $name, displayName: $displayName, x: $x, y: $y } }
      ) {
          _id
          name
          displayName
          x
          y
      }
  }
`;

export const ADD_NEW_DEFAULT_PRIM_SCRIPT = gql`
  mutation addNewNiamScriptToServer($serverID: ID!, $name: String!, $displayName: String, $x: Float, $y: Float) {
      addNewNiamScriptToServer(
          input: { serverID: $serverID, newNiamScriptInput: { name: $name, displayName: $displayName, x: $x, y: $y } }
      ) {
          _id
          name
          displayName
          x
          y
      }
  }
`;

export const ADD_NEW_DEFAULT_PRIM_CUSTOM_MUTATION = gql`
  mutation addNewNiamCustomMutation($serverID: ID!, $name: String!, $displayName: String, $x: Float, $y: Float) {
      addNewNiamCustomMutation(input: { serverID: $serverID, params: { name: $name, displayName: $displayName, x: $x, y: $y } }) {
          _id
          name
          displayName
          inputSchema
          args
          mutatuinName
          returnType
          script
          x
          y
      }
  }
`;

export const ADD_NEW_DEFAULT_PRIM_TAG = gql`
  mutation addNewNiamTagForNiamDataServer($serverID: ID!, $name: String!, $displayName: String, $x: Float, $y: Float) {
      addNewNiamTagForNiamDataServer(input: { serverID: $serverID, params: { name: $name, displayName: $displayName, x: $x, y: $y } }) {
          _id
          name
          displayName
          x
          y
      }
  }
`;

export const ADD_NEW_DEFAULT_ENUM = gql`
  mutation addNewNiamEnum($serverID: ID!, $name: String!, $displayName: String, $enumerates: [String!], $x: Float, $y: Float) {
      addNewNiamEnum(input: { serverID: $serverID, newNiamEnumInput: { name: $name, displayName: $displayName, enumerates: $enumerates, x: $x, y: $y } }) {
          _id
          name
          displayName
          enumerates
          x
          y
      }
  }
`;

export const ADD_NEW_DEFAULT_PRIM_NODE_RELATIONSHIP = gql`
  mutation addNewDefaultPrimNodeRelationship($serverID: ID!, $name: String!, $displayName: String, $description: String, $x: Float, $y: Float) {
      addNewDefaultPrimNodeRelationship(input: { serverID: $serverID, params: { name: $name, displayName: $displayName, description: $description }, x: $x, y: $y }) {
        rel {
            _id
            name
            displayName
            description
            x
            y
        }
      }
  }
`;

import { ButtonListFlex, ConfirmButton } from "components/ModalDataServer/styled";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "ui-components/modal";
import { PwdField } from "ui-components/pwdField";
import { isEmpty, isNotEmpty } from "@3edges/utils/dist/utils";
interface ModalChangePWDProps {
    isModalOpenPWD: boolean;
    newPassword: string;
    setNewPassword: (pass: string) => void;
    confirmNewPassword: string;
    setConfirmNewPassword: (pass: string) => void;
    waitPWD: boolean;
    onClose: () => void;
    onSave: () => void;
    adminPage?: boolean;
}
function ModalChangePWD({
    isModalOpenPWD,
    newPassword,
    setNewPassword,
    confirmNewPassword,
    setConfirmNewPassword,
    waitPWD,
    onClose,
    onSave,
    adminPage
}: ModalChangePWDProps): React.ReactElement {
    const { t } = useTranslation();
    useEffect(() => {
        if (!isModalOpenPWD) {
            setNewPassword("");
            setConfirmNewPassword("");
        }
    }, [isModalOpenPWD]);
    return (
        <Modal isOpen={isModalOpenPWD} onClose={onClose} style={{ width: "600px" }}>
            <h1>
                {adminPage ? t("profile:adminChange.pwd.title") : t("profile:change.pwd.title")}
            </h1>

            <PwdField
                value={newPassword}
                onChange={(e) => {
                    setNewPassword(e.target.value);
                }}
                label={adminPage ? t("profile:adminNewPassword.label") : t("profile:newPassword.label")}
            />

            <PwdField
                value={confirmNewPassword}
                onChange={(e) => {
                    setConfirmNewPassword(e.target.value);
                }}
                label={adminPage ? t("profile:adminConfirmNewPassword.label") : t("profile:confirmNewPassword.label")}
                onKeyPress={(e) => {
                    if ((e.keyCode === 13 || e.charCode === 13) && isNotEmpty(newPassword)) {
                        onSave();
                    }
                }}
            />

            <ButtonListFlex>
                <ConfirmButton onClick={onSave} disabled={waitPWD || isEmpty(newPassword) || isEmpty(confirmNewPassword)}>
                    {t("root:btn.save.label")}
                </ConfirmButton>
            </ButtonListFlex>
        </Modal>
    );
}
export default ModalChangePWD;

/* eslint-disable max-lines */
import { iD3Data, iD3Selection, iData, NodeTypename, OrbitItem, RelationshipType } from "components/PrimGraphicalCanvas/types";
import { getShown } from "components/PrimGraphicalCanvas/utils";
import { addNewNodeRelationship_addNewNodeRelationship as NewNodeRelationship } from "types/operation-result-types";

const definePolicy = (
    d3Data: iD3Data,
    data: iData | Partial<iData & NewNodeRelationship>,
    value: {
        data: {
            _id: string,
            relatedTo: string
        };
        k: string;
    },
    enters: {
        enterOrbit: (selection: iD3Selection) => void;
        updateOrbit: (selection: iD3Selection) => void;
        enterLink: (selection: iD3Selection) => void;
        updateLink: (selection: iD3Selection) => void;
    },
    setData: React.Dispatch<React.SetStateAction<iData>>
): void => {

    const isAction = value.k === "isAction"
    const isVC = value.k === "isVC"
    const isHolder = value.k === "isHolder"
    const isAndOperator = value.k === "isAndOperator"
    const isOrOperator = value.k === "isOrOperator"
    const isIssuer = value.k === "isIssuer"
    const isClaim = value.k === "isClaim"
    const isResource = value.k === "isResource"
    const isSubject = value.k === "isSubject"
    const isPath = value.k === "isPath"
    const isPathCreate = value.k === "isPathCreate"
    const isPathRead = value.k === "isPathRead"
    const isPathUpdate = value.k === "isPathUpdate"
    const isPathDelete = value.k === "isPathDelete"

    let currentData;

    const newDataObjects = data.objects.map((o) => {
        if (o._id === value.data.relatedTo && isResource) {
            o.isResource = isResource;
            currentData = o
        }

        if (o._id === value.data.relatedTo && isAction) {
            o.isAction = isAction;
            currentData = o
        }

        if (o._id === value.data.relatedTo && isSubject) {
            o.isSubject = isSubject;
            currentData = o
        }

        if (o._id === value.data.relatedTo && isVC) {
            o.isVC = isVC;
            currentData = o
        }

        if (o._id === value.data.relatedTo && isHolder) {
            o.isHolder = isHolder;
            currentData = o
        }

        if (o._id === value.data.relatedTo && isAndOperator) {
            o.isAndOperator = isAndOperator;
            currentData = o
        }

        if (o._id === value.data.relatedTo && isOrOperator) {
            o.isOrOperator = isOrOperator;
            currentData = o
        }

        if (o._id === value.data.relatedTo && isIssuer) {
            o.isIssuer = isIssuer;
            currentData = o
        }

        if (o._id === value.data.relatedTo && isClaim) {
            o.isClaim = isClaim;
            currentData = o
        }

        if (isAction) {
            o.fields = o.fields.map(f => {
                if (f._id === value.data.relatedTo) {
                    f.isAction = isAction;
                }

                return f
            })
        }

        if (isVC) {
            o.fields = o.fields.map(f => {
                if (f._id === value.data.relatedTo) {
                    f.isVC = isVC;
                }

                return f
            })
        }

        return o;
    });

    const newNodeRelationships = data.nodeRelationships.map((nr) => {

        if (nr._id === value.data.relatedTo && isPath) {
            nr.simplePath = value.data;
            currentData = nr
        }

        if (nr._id === value.data.relatedTo && isPathCreate) {
            nr.simplePathCreate = value.data;
            currentData = nr
        }

        if (nr._id === value.data.relatedTo && isPathRead) {
            nr.simplePathRead = value.data;
            currentData = nr
        }

        if (nr._id === value.data.relatedTo && isPathUpdate) {
            nr.simplePathUpdate = value.data;
            currentData = nr
        }

        if (nr._id === value.data.relatedTo && isPathDelete) {
            nr.simplePathDelete = value.data;
            currentData = nr
        }

        if (nr._id === value.data.relatedTo && isAction) {
            nr.isAction = isAction;
            currentData = nr
        }

        if (nr._id === value.data.relatedTo && isVC) {
            nr.isVC = isVC;
            currentData = nr
        }

        return nr;
    });

    const newDataCustomMutations = data.customMutations.map((cm) => {
        if (cm._id === value.data.relatedTo && isResource) {
            cm.isResource = isResource;
            currentData = cm
        }

        return cm;
    });

    const newDataRels = data.relLinks.map((rl) => {
        if (rl._id === value.data.relatedTo && isPath) {
            rl.simplePath = value.data;
            currentData = rl
        }

        if (rl._id === value.data.relatedTo && isPathCreate) {
            rl.simplePathCreate = value.data;
            currentData = rl
        }

        if (rl._id === value.data.relatedTo && isPathRead) {
            rl.simplePathRead = value.data;
            currentData = rl
        }

        if (rl._id === value.data.relatedTo && isPathUpdate) {
            rl.simplePathUpdate = value.data;
            currentData = rl
        }

        if (rl._id === value.data.relatedTo && isPathDelete) {
            rl.simplePathDelete = value.data;
            currentData = rl
        }

        if (rl._id === value.data.relatedTo && isAction) {
            rl.isAction = isAction;
            currentData = rl
        }

        if (rl._id === value.data.relatedTo && isVC) {
            rl.isVC = isVC;
            currentData = rl
        }

        return rl;
    });

    const newData: iData = {
        objects: newDataObjects,
        interfaces: data.interfaces,
        scripts: data.scripts,
        customMutations: newDataCustomMutations,
        nodeRelationships: newNodeRelationships,
        implementLinks: data.implementLinks,
        paths: data.paths,
        relLinks: newDataRels,
        tags: data.tags,
        enums: data.enums
    };

    const orbitShown = getShown([
        ...newDataObjects,
        ...newDataCustomMutations,
        ...newNodeRelationships,
    ]);

    const linkShown = getShown([...newData.implementLinks, ...newDataRels, ...newData.paths]).reverse();

    d3Data.g
        .selectAll(".orbit")
        .data(orbitShown, (node: OrbitItem) => node._id)
        .exit()
        .remove();

    d3Data.g
        .selectAll(".orbit")
        .data(orbitShown, (node: OrbitItem) => node._id)
        .enter()
        .append("g")
        .call(enters.enterOrbit)
        .on("click", d3Data.zoomOnNodesInstance);

    d3Data.g
        .selectAll(".link")
        .data(linkShown, (node: OrbitItem) => node._id)
        .exit()
        .remove();

    d3Data.g
        .selectAll(".link")
        .data(linkShown, (node: OrbitItem) => node._id)
        .enter()
        .append("g")
        .call(enters.enterLink)
        .on("click", d3Data.zoomOnLinksInstance);

    if (isPathCreate || isPathRead || isPathUpdate || isPathDelete || isPath || ((isVC || isAction) && (currentData.type === RelationshipType.RELATION || currentData.__typename === NodeTypename.NIAM_NODE_RELATIONSHIP))) {

        const isPetal = currentData.source?._id === currentData.target?._id

        const currentLink = d3Data.g.select(`#relationshipID_${value.data.relatedTo}`)
        currentLink.classed("setColorPath", true);

        if (isPetal) {
            currentLink.classed("setColorLinePetal", true);
        }

        // Add CRUD label to orbit (object->object)
        if(currentLink.node() && isPath) {
            currentLink
                .append("text")
                .attr("dy", 15)
                .attr("stroke", "transparent")
                .attr("stroke-linecap", "round")
                .append("textPath")
                .attr("cursor", "grab")
                .style("font-size", "15px")
                .style("fill", "#555")
                .style("letter-spacing", "3px")
                .attr("text-anchor", "middle")
                .attr("startOffset", "50%")
                .attr("id", `simplePath_${value.data.relatedTo}`)
                .attr("xlink:href", isPetal ? `#petal_${value.data.relatedTo}` : `#${value.data.relatedTo}`)
                .text("CRUD");

        } else if(currentLink.node() && isPathCreate) {
            currentLink
                .append("text")
                .attr("dy", 15)
                .attr("stroke", "transparent")
                .attr("stroke-linecap", "round")
                .append("textPath")
                .attr("cursor", "grab")
                .style("font-size", "15px")
                .style("fill", "#555")
                .style("letter-spacing", "3px")
                .attr("text-anchor", "middle")
                .attr("startOffset", "50%")
                .attr("id", `simplePathCreate_${value.data.relatedTo}`)
                .attr("xlink:href", isPetal ? `#petal_${value.data.relatedTo}` :`#${value.data.relatedTo}`)
                .text("Create");

        } else if(currentLink.node() && isPathRead) {
            currentLink
                .append("text")
                .attr("dy", 15)
                .attr("stroke", "transparent")
                .attr("stroke-linecap", "round")
                .append("textPath")
                .attr("cursor", "grab")
                .style("font-size", "15px")
                .style("fill", "#555")
                .style("letter-spacing", "3px")
                .attr("text-anchor", "middle")
                .attr("startOffset", "50%")
                .attr("id", `simplePathRead_${value.data.relatedTo}`)
                .attr("xlink:href", isPetal ? `#petal_${value.data.relatedTo}` :`#${value.data.relatedTo}`)
                .text("Read");

        } else if(currentLink.node() && isPathUpdate) {
            currentLink
                .append("text")
                .attr("dy", 15)
                .attr("stroke", "transparent")
                .attr("stroke-linecap", "round")
                .append("textPath")
                .attr("cursor", "grab")
                .style("font-size", "15px")
                .style("fill", "#555")
                .style("letter-spacing", "3px")
                .attr("text-anchor", "middle")
                .attr("startOffset", "50%")
                .attr("id", `simplePathUpdate_${value.data.relatedTo}`)
                .attr("xlink:href", isPetal ? `#petal_${value.data.relatedTo}` :`#${value.data.relatedTo}`)
                .text("Update");

        } else if(currentLink.node() && isPathDelete) {
            currentLink
                .append("text")
                .attr("dy", 15)
                .attr("stroke", "transparent")
                .attr("stroke-linecap", "round")
                .append("textPath")
                .attr("cursor", "grab")
                .style("font-size", "15px")
                .style("fill", "#555")
                .style("letter-spacing", "3px")
                .attr("text-anchor", "middle")
                .attr("startOffset", "50%")
                .attr("id", `simplePathDelete_${value.data.relatedTo}`)
                .attr("xlink:href", isPetal ? `#petal_${value.data.relatedTo}` :`#${value.data.relatedTo}`)
                .text("Delete");

        } else if(currentLink.node() && isAction) {
                currentLink
                    .append("text")
                    .attr("dy", 15)
                    .attr("stroke", "transparent")
                    .attr("stroke-linecap", "round")
                    .append("textPath")
                    .attr("cursor", "grab")
                    .style("font-size", "15px")
                    .style("fill", "#555")
                    .style("letter-spacing", "3px")
                    .attr("text-anchor", "middle")
                    .attr("startOffset", "50%")
                    .attr("id", `actionRelationship_${value.data.relatedTo}`)
                    .attr("xlink:href", isPetal ? `#petal_${value.data.relatedTo}` :`#${value.data.relatedTo}`)
                    .text("AuthAction");
        } else if(currentLink.node() && isVC) {
            currentLink
                .append("text")
                .attr("dy", 15)
                .attr("stroke", "transparent")
                .attr("stroke-linecap", "round")
                .append("textPath")
                .attr("cursor", "grab")
                .style("font-size", "15px")
                .style("fill", "#555")
                .style("letter-spacing", "3px")
                .attr("text-anchor", "middle")
                .attr("startOffset", "50%")
                .attr("id", `vcRelationship_${value.data.relatedTo}`)
                .attr("xlink:href", isPetal ? `#petal_${value.data.relatedTo}` :`#${value.data.relatedTo}`)
                .text("AuthVC");
        }

        const currentOrbitNR = d3Data.g.select(`#orbitID_${value.data.relatedTo}`)

        // Check if you are adding a simple path to NR
        if (currentOrbitNR.node()) {
            const currentAuthPath = d3Data.g.select(`#authPath_${value.data.relatedTo}`)

            d3Data.g.select(`#authPath_${value.data.relatedTo}`).remove()
            currentOrbitNR.classed("setColorIsPath", true);

            // Add CRUD label to relationships (relation->NR->relation)
            if (currentData.__typename === NodeTypename.NIAM_NODE_RELATIONSHIP && isPath) {

                const currentLinkBeforeNR = d3Data.g.select(`#relationshipID_${currentData.relation.relFrom._id}`)
                const currentLinkAfterNR = d3Data.g.select(`#relationshipID_${currentData.relation.relTo._id}`)

                if (currentLinkBeforeNR.node()) {
                    currentLinkBeforeNR.classed("setColorPath", true);

                    currentLinkBeforeNR
                        .append("text")
                        .attr("dy", 15)
                        .attr("stroke", "transparent")
                        .attr("stroke-linecap", "round")
                        .append("textPath")
                        .style("font-size", "15px")
                        .style("fill", "#555")
                        .style("letter-spacing", "3px")
                        .attr("text-anchor", "middle")
                        .attr("startOffset", "50%")
                        .attr("id", `simplePath_${currentData.relation.relFrom._id}`)
                        .attr("xlink:href", `#${currentData.relation.relFrom._id}`)
                        .text("CRUD");
                }

                if (currentLinkAfterNR.node()) {
                    currentLinkAfterNR.classed("setColorPath", true);

                    currentLinkAfterNR
                        .append("text")
                        .attr("dy", 15)
                        .attr("stroke", "transparent")
                        .attr("stroke-linecap", "round")
                        .append("textPath")
                        .style("font-size", "15px")
                        .style("fill", "#555")
                        .style("letter-spacing", "3px")
                        .attr("text-anchor", "middle")
                        .attr("startOffset", "50%")
                        .attr("id", `simplePath_${currentData.relation.relTo._id}`)
                        .attr("xlink:href", `#${currentData.relation.relTo._id}`)
                        .text("CRUD");
                }

            } else if (currentData.__typename === NodeTypename.NIAM_NODE_RELATIONSHIP && isPathCreate) {

                const currentLinkBeforeNR = d3Data.g.select(`#relationshipID_${currentData.relation.relFrom._id}`)
                const currentLinkAfterNR = d3Data.g.select(`#relationshipID_${currentData.relation.relTo._id}`)

                if (currentLinkBeforeNR.node()) {
                    currentLinkBeforeNR.classed("setColorPath", true);

                    currentLinkBeforeNR
                        .append("text")
                        .attr("dy", 15)
                        .attr("stroke", "transparent")
                        .attr("stroke-linecap", "round")
                        .append("textPath")
                        .style("font-size", "15px")
                        .style("fill", "#555")
                        .style("letter-spacing", "3px")
                        .attr("text-anchor", "middle")
                        .attr("startOffset", "50%")
                        .attr("id", `simplePathCreate_${currentData.relation.relFrom._id}`)
                        .attr("xlink:href", `#${currentData.relation.relFrom._id}`)
                        .text("Create");
                }

                if (currentLinkAfterNR.node()) {
                    currentLinkAfterNR.classed("setColorPath", true);

                    currentLinkAfterNR
                        .append("text")
                        .attr("dy", 15)
                        .attr("stroke", "transparent")
                        .attr("stroke-linecap", "round")
                        .append("textPath")
                        .style("font-size", "15px")
                        .style("fill", "#555")
                        .style("letter-spacing", "3px")
                        .attr("text-anchor", "middle")
                        .attr("startOffset", "50%")
                        .attr("id", `simplePathCreate_${currentData.relation.relTo._id}`)
                        .attr("xlink:href", `#${currentData.relation.relTo._id}`)
                        .text("Create");
                }

            } else if (currentData.__typename === NodeTypename.NIAM_NODE_RELATIONSHIP && isPathRead) {

                const currentLinkBeforeNR = d3Data.g.select(`#relationshipID_${currentData.relation.relFrom._id}`)
                const currentLinkAfterNR = d3Data.g.select(`#relationshipID_${currentData.relation.relTo._id}`)

                if (currentLinkBeforeNR.node()) {
                    currentLinkBeforeNR.classed("setColorPath", true);

                    currentLinkBeforeNR
                        .append("text")
                        .attr("dy", 15)
                        .attr("stroke", "transparent")
                        .attr("stroke-linecap", "round")
                        .append("textPath")
                        .style("font-size", "15px")
                        .style("fill", "#555")
                        .style("letter-spacing", "3px")
                        .attr("text-anchor", "middle")
                        .attr("startOffset", "50%")
                        .attr("id", `simplePathRead_${currentData.relation.relFrom._id}`)
                        .attr("xlink:href", `#${currentData.relation.relFrom._id}`)
                        .text("Read");
                }

                if (currentLinkAfterNR.node()) {
                    currentLinkAfterNR.classed("setColorPath", true);

                    currentLinkAfterNR
                        .append("text")
                        .attr("dy", 15)
                        .attr("stroke", "transparent")
                        .attr("stroke-linecap", "round")
                        .append("textPath")
                        .style("font-size", "15px")
                        .style("fill", "#555")
                        .style("letter-spacing", "3px")
                        .attr("text-anchor", "middle")
                        .attr("startOffset", "50%")
                        .attr("id", `simplePathRead_${currentData.relation.relTo._id}`)
                        .attr("xlink:href", `#${currentData.relation.relTo._id}`)
                        .text("Read");
                }

            } else if (currentData.__typename === NodeTypename.NIAM_NODE_RELATIONSHIP && isPathUpdate) {

                const currentLinkBeforeNR = d3Data.g.select(`#relationshipID_${currentData.relation.relFrom._id}`)
                const currentLinkAfterNR = d3Data.g.select(`#relationshipID_${currentData.relation.relTo._id}`)

                if (currentLinkBeforeNR.node()) {
                    currentLinkBeforeNR.classed("setColorPath", true);

                    currentLinkBeforeNR
                        .append("text")
                        .attr("dy", 15)
                        .attr("stroke", "transparent")
                        .attr("stroke-linecap", "round")
                        .append("textPath")
                        .style("font-size", "15px")
                        .style("fill", "#555")
                        .style("letter-spacing", "3px")
                        .attr("text-anchor", "middle")
                        .attr("startOffset", "50%")
                        .attr("id", `simplePathUpdate_${currentData.relation.relFrom._id}`)
                        .attr("xlink:href", `#${currentData.relation.relFrom._id}`)
                        .text("Update");
                }

                if (currentLinkAfterNR.node()) {
                    currentLinkAfterNR.classed("setColorPath", true);

                    currentLinkAfterNR
                        .append("text")
                        .attr("dy", 15)
                        .attr("stroke", "transparent")
                        .attr("stroke-linecap", "round")
                        .append("textPath")
                        .style("font-size", "15px")
                        .style("fill", "#555")
                        .style("letter-spacing", "3px")
                        .attr("text-anchor", "middle")
                        .attr("startOffset", "50%")
                        .attr("id", `simplePathUpdate_${currentData.relation.relTo._id}`)
                        .attr("xlink:href", `#${currentData.relation.relTo._id}`)
                        .text("Update");
                }

            } else if (currentData.__typename === NodeTypename.NIAM_NODE_RELATIONSHIP && isPathDelete) {

                const currentLinkBeforeNR = d3Data.g.select(`#relationshipID_${currentData.relation.relFrom._id}`)
                const currentLinkAfterNR = d3Data.g.select(`#relationshipID_${currentData.relation.relTo._id}`)

                if (currentLinkBeforeNR.node()) {
                    currentLinkBeforeNR.classed("setColorPath", true);

                    currentLinkBeforeNR
                        .append("text")
                        .attr("dy", 15)
                        .attr("stroke", "transparent")
                        .attr("stroke-linecap", "round")
                        .append("textPath")
                        .style("font-size", "15px")
                        .style("fill", "#555")
                        .style("letter-spacing", "3px")
                        .attr("text-anchor", "middle")
                        .attr("startOffset", "50%")
                        .attr("id", `simplePathDelete_${currentData.relation.relFrom._id}`)
                        .attr("xlink:href", `#${currentData.relation.relFrom._id}`)
                        .text("Delete");
                }

                if (currentLinkAfterNR.node()) {
                    currentLinkAfterNR.classed("setColorPath", true);

                    currentLinkAfterNR
                        .append("text")
                        .attr("dy", 15)
                        .attr("stroke", "transparent")
                        .attr("stroke-linecap", "round")
                        .append("textPath")
                        .style("font-size", "15px")
                        .style("fill", "#555")
                        .style("letter-spacing", "3px")
                        .attr("text-anchor", "middle")
                        .attr("startOffset", "50%")
                        .attr("id", `simplePathDelete_${currentData.relation.relTo._id}`)
                        .attr("xlink:href", `#${currentData.relation.relTo._id}`)
                        .text("Delete");
                }

            } else if (currentData.__typename === NodeTypename.NIAM_NODE_RELATIONSHIP && (isAction || isVC)) {

                if (isAction) {
                    currentOrbitNR
                        .append("text")
                        .attr("id", `authAction_${value.data.relatedTo}`)
                        .attr("text-anchor", "middle")
                        .attr("x", 0)
                        .attr("font-family", "IBM Plex Sans")
                        .text("AuthAction");
                }

                if (isVC) {
                    currentOrbitNR
                        .append("text")
                        .attr("id", `authVC_${value.data.relatedTo}`)
                        .attr("text-anchor", "middle")
                        .attr("x", 0)
                        .attr("font-family", "IBM Plex Sans")
                        .text("AuthVC");
                }

                const currentAuthAction = d3Data.g.select(`#authAction_${value.data.relatedTo}`)
                const currentAuthVC = d3Data.g.select(`#authVC_${value.data.relatedTo}`)
                const listLabel = [];

                if (currentAuthAction.node()) {
                    listLabel.push(currentAuthAction)
                }

                if (currentAuthVC.node()) {
                    listLabel.push(currentAuthVC)
                }

                if (listLabel.length === 1) {
                    listLabel[0].attr("y", -50).attr("font-size", "16px");
                }

                if (listLabel.length === 2) {
                    listLabel[1].attr("y", -60).attr("font-size", "14px");
                    listLabel[0].attr("y", -45).attr("font-size", "14px");
                }
            }
        }

        if(currentData.__typename === NodeTypename.NIAM_NODE_RELATIONSHIP){

                const currentLinkBeforeNRPath = d3Data.g.select(`#simplePath_${currentData.relation.relFrom._id}`)
                const currentLinkAfterNRPath = d3Data.g.select(`#simplePath_${currentData.relation.relTo._id}`)

                const currentLinkBeforeNRPathCreate = d3Data.g.select(`#simplePathCreate_${currentData.relation.relFrom._id}`)
                const currentLinkAfterNRPathCreate = d3Data.g.select(`#simplePathCreate_${currentData.relation.relTo._id}`)

                const currentLinkBeforeNRPathRead = d3Data.g.select(`#simplePathRead_${currentData.relation.relFrom._id}`)
                const currentLinkAfterNRPathRead = d3Data.g.select(`#simplePathRead_${currentData.relation.relTo._id}`)

                const currentLinkBeforeNRPathUpdate = d3Data.g.select(`#simplePathUpdate_${currentData.relation.relFrom._id}`)
                const currentLinkAfterNRPathUpdate = d3Data.g.select(`#simplePathUpdate_${currentData.relation.relTo._id}`)

                const currentLinkBeforeNRPathDelete = d3Data.g.select(`#simplePathDelete_${currentData.relation.relFrom._id}`)
                const currentLinkAfterNRPathDelete = d3Data.g.select(`#simplePathDelete_${currentData.relation.relTo._id}`)

                const listLabelBefore = [];
                const listLabelAfter = [];
                if(currentLinkBeforeNRPath.node()){
                    listLabelBefore.push(currentLinkBeforeNRPath);
                }
                if(currentLinkBeforeNRPathCreate.node()){
                    listLabelBefore.push(currentLinkBeforeNRPathCreate);
                }
                if(currentLinkBeforeNRPathRead.node()){
                    listLabelBefore.push(currentLinkBeforeNRPathRead);
                }
                if(currentLinkBeforeNRPathUpdate.node()){
                    listLabelBefore.push(currentLinkBeforeNRPathUpdate);
                }
                if(currentLinkBeforeNRPathDelete.node()){
                    listLabelBefore.push(currentLinkBeforeNRPathDelete);
                }

                if(currentLinkAfterNRPath.node()){
                    listLabelAfter.push(currentLinkAfterNRPath);
                }
                if(currentLinkAfterNRPathCreate.node()){
                    listLabelAfter.push(currentLinkAfterNRPathCreate);
                }
                if(currentLinkAfterNRPathRead.node()){
                    listLabelAfter.push(currentLinkAfterNRPathRead);
                }
                if(currentLinkAfterNRPathUpdate.node()){
                    listLabelAfter.push(currentLinkAfterNRPathUpdate);
                }
                if(currentLinkAfterNRPathDelete.node()){
                    listLabelAfter.push(currentLinkAfterNRPathDelete);
                }

                switch (listLabelBefore.length) {
                case 1: {
                    listLabelBefore[0].attr("startOffset", "50%").attr("font-size", "16px");

                break;
                }
                case 2: {
                    listLabelBefore[1].attr("startOffset", "66%").attr("font-size", "14px");
                    listLabelBefore[0].attr("startOffset", "33%").attr("font-size", "14px");

                break;
                }
                case 3: {
                    listLabelBefore[2].attr("startOffset", "75%").attr("font-size", "13px");
                    listLabelBefore[1].attr("startOffset", "50%").attr("font-size", "13px");
                    listLabelBefore[0].attr("startOffset", "25%").attr("font-size", "13px");

                break;
                }
                case 4: {
                    listLabelBefore[3].attr("startOffset", "80%").attr("font-size", "12px");
                    listLabelBefore[2].attr("startOffset", "60%").attr("font-size", "12px");
                    listLabelBefore[1].attr("startOffset", "40%").attr("font-size", "12px");
                    listLabelBefore[0].attr("startOffset", "20%").attr("font-size", "12px");

                break;
                }
                case 5: {
                    listLabelBefore[4].attr("startOffset", "82%").attr("font-size", "11px");
                    listLabelBefore[3].attr("startOffset", "66%").attr("font-size", "11px");
                    listLabelBefore[2].attr("startOffset", "50%").attr("font-size", "11px");
                    listLabelBefore[1].attr("startOffset", "32%").attr("font-size", "11px");
                    listLabelBefore[0].attr("startOffset", "16%").attr("font-size", "11px");

                break;
                }
                // No default
                }

                switch (listLabelAfter.length) {
                case 1: {
                    listLabelAfter[0].attr("startOffset", "50%").attr("font-size", "16px");

                break;
                }
                case 2: {
                    listLabelAfter[1].attr("startOffset", "66%").attr("font-size", "14px");
                    listLabelAfter[0].attr("startOffset", "33%").attr("font-size", "14px");

                break;
                }
                case 3: {
                    listLabelAfter[2].attr("startOffset", "75%").attr("font-size", "13px");
                    listLabelAfter[1].attr("startOffset", "50%").attr("font-size", "13px");
                    listLabelAfter[0].attr("startOffset", "25%").attr("font-size", "13px");

                break;
                }
                case 4: {
                    listLabelAfter[3].attr("startOffset", "80%").attr("font-size", "12px");
                    listLabelAfter[2].attr("startOffset", "60%").attr("font-size", "12px");
                    listLabelAfter[1].attr("startOffset", "40%").attr("font-size", "12px");
                    listLabelAfter[0].attr("startOffset", "20%").attr("font-size", "12px");

                break;
                }
                case 5: {
                    listLabelAfter[4].attr("startOffset", "82%").attr("font-size", "11px");
                    listLabelAfter[3].attr("startOffset", "66%").attr("font-size", "11px");
                    listLabelAfter[2].attr("startOffset", "50%").attr("font-size", "11px");
                    listLabelAfter[1].attr("startOffset", "32%").attr("font-size", "11px");
                    listLabelAfter[0].attr("startOffset", "16%").attr("font-size", "11px");

                break;
                }
                // No default
                }
        }

        const currentSimpleAuthPath = d3Data.g.select(`#simplePath_${value.data.relatedTo}`)
        const currentSimpleAuthPathCreate = d3Data.g.select(`#simplePathCreate_${value.data.relatedTo}`)
        const currentSimpleAuthPathRead = d3Data.g.select(`#simplePathRead_${value.data.relatedTo}`)
        const currentSimpleAuthPathUpdate = d3Data.g.select(`#simplePathUpdate_${value.data.relatedTo}`)
        const currentSimpleAuthPathDelete = d3Data.g.select(`#simplePathDelete_${value.data.relatedTo}`)
        const currentAuthActionRel = d3Data.g.select(`#actionRelationship_${value.data.relatedTo}`)
        const currentAuthVCRel = d3Data.g.select(`#vcRelationship_${value.data.relatedTo}`)

        const listLabel = [];

        if (currentSimpleAuthPath.node()) {
            listLabel.push(currentSimpleAuthPath)
        }

        if (currentSimpleAuthPathCreate.node()) {
            listLabel.push(currentSimpleAuthPathCreate)
        }

        if (currentSimpleAuthPathRead.node()) {
            listLabel.push(currentSimpleAuthPathRead)
        }

        if (currentSimpleAuthPathUpdate.node()) {
            listLabel.push(currentSimpleAuthPathUpdate)
        }

        if (currentSimpleAuthPathDelete.node()) {
            listLabel.push(currentSimpleAuthPathDelete)
        }

        if (currentAuthActionRel.node()) {
            listLabel.push(currentAuthActionRel)
        }

        if (currentAuthVCRel.node()) {
            listLabel.push(currentAuthVCRel)
        }

        switch (listLabel.length) {
        case 1: {
            listLabel[0].attr("startOffset", "50%").attr("font-size", "16px");

        break;
        }
        case 2: {
            listLabel[1].attr("startOffset", "66%").attr("font-size", "14px");
            listLabel[0].attr("startOffset", "33%").attr("font-size", "14px");

        break;
        }
        case 3: {
            listLabel[2].attr("startOffset", "75%").attr("font-size", "13px");
            listLabel[1].attr("startOffset", "50%").attr("font-size", "13px");
            listLabel[0].attr("startOffset", "25%").attr("font-size", "13px");

        break;
        }
        case 4: {
            listLabel[3].attr("startOffset", "80%").attr("font-size", "12px");
            listLabel[2].attr("startOffset", "60%").attr("font-size", "12px");
            listLabel[1].attr("startOffset", "40%").attr("font-size", "12px");
            listLabel[0].attr("startOffset", "20%").attr("font-size", "12px");

        break;
        }
        case 5: {
            listLabel[4].attr("startOffset", "82%").attr("font-size", "11px");
            listLabel[3].attr("startOffset", "66%").attr("font-size", "11px");
            listLabel[2].attr("startOffset", "50%").attr("font-size", "11px");
            listLabel[1].attr("startOffset", "32%").attr("font-size", "11px");
            listLabel[0].attr("startOffset", "16%").attr("font-size", "11px");

        break;
        }
        case 6: {
            listLabel[5].attr("startOffset", "84%").attr("font-size", "10px");
            listLabel[4].attr("startOffset", "70%").attr("font-size", "10px");
            listLabel[3].attr("startOffset", "56%").attr("font-size", "10px");
            listLabel[2].attr("startOffset", "42%").attr("font-size", "10px");
            listLabel[1].attr("startOffset", "28%").attr("font-size", "10px");
            listLabel[0].attr("startOffset", "14%").attr("font-size", "10px");

        break;
        }
        case 7: {
            listLabel[6].attr("startOffset", "86.5%").attr("font-size", "9px");
            listLabel[5].attr("startOffset", "75%").attr("font-size", "9px");
            listLabel[4].attr("startOffset", "62.2%").attr("font-size", "9px");
            listLabel[3].attr("startOffset", "50%").attr("font-size", "9px");
            listLabel[2].attr("startOffset", "37.5%").attr("font-size", "9px");
            listLabel[1].attr("startOffset", "25%").attr("font-size", "9px");
            listLabel[0].attr("startOffset", "12.5%").attr("font-size", "9px");

        break;
        }
        // No default
        }

    } else {

        const currentOrbit = d3Data.g.select(`#orbitID_${value.data.relatedTo}`)
        const currentAuthPath = d3Data.g.select(`#authPath_${value.data.relatedTo}`)
        let currentAuthSubject = d3Data.g.select(`#authSubject_${value.data.relatedTo}`)
        let currentAuthResource = d3Data.g.select(`#authResource_${value.data.relatedTo}`)
        let currentAuthAction = d3Data.g.select(`#authAction_${value.data.relatedTo}`)
        let currentAuthVC = d3Data.g.select(`#authVC_${value.data.relatedTo}`)
        let currentAuthHolder = d3Data.g.select(`#authHolder_${value.data.relatedTo}`)
        let currentAuthAndOperator = d3Data.g.select(`#authAndOperator_${value.data.relatedTo}`)
        let currentAuthOrOperator = d3Data.g.select(`#authOrOperator_${value.data.relatedTo}`)
        let currentAuthIssuer = d3Data.g.select(`#authIssuer_${value.data.relatedTo}`)
        let currentAuthClaim = d3Data.g.select(`#authClaim_${value.data.relatedTo}`)
        const listLabel = [];

        // Add Resource label to orbit (object/CM)
        if (isResource)
        {
            d3Data.g.select(`#authResource_${value.data.relatedTo}`).remove()
            currentOrbit.classed("setColorIsResource", true);

            if (currentAuthPath.node()) {
                currentAuthPath.attr("y", -45);
                currentAuthPath.attr("font-size", currentAuthPath.node() ? "14px" : "16px")
            }

            currentOrbit
                .append("text")
                .attr("id", `authResource_${value.data.relatedTo}`)
                .attr("text-anchor", "middle")
                .attr("x", 0)
                .attr("font-family", "IBM Plex Sans")
                .text("AuthResource");

            currentAuthResource = d3Data.g.select(`#authResource_${value.data.relatedTo}`)
        }

        // Add Subject label to orbit (object)
        if (isSubject) {
            d3Data.g.select(`#authSubject_${value.data.relatedTo}`).remove()
            currentOrbit.classed("setColorIsSubject", true);

            currentOrbit
                .append("text")
                .attr("id", `authSubject_${value.data.relatedTo}`)
                .attr("text-anchor", "middle")
                .attr("x", 0)
                .attr("font-family", "IBM Plex Sans")
                .text("AuthSubject");

            currentAuthSubject = d3Data.g.select(`#authSubject_${value.data.relatedTo}`)
        }

        // Add Action label to orbit (object)
        if (isAction) {
            d3Data.g.select(`#authAction_${value.data.relatedTo}`).remove()
            currentOrbit.classed("setColorIsAction", true);

            currentOrbit
                .append("text")
                .attr("id", `authAction_${value.data.relatedTo}`)
                .attr("text-anchor", "middle")
                .attr("x", 0)
                .attr("font-family", "IBM Plex Sans")
                .text("AuthAction");

            currentAuthAction = d3Data.g.select(`#authAction_${value.data.relatedTo}`)
        }

        // Add VC label to orbit (object)
        if (isVC) {
            d3Data.g.select(`#authVC_${value.data.relatedTo}`).remove()
            currentOrbit.classed("setColorIsVC", true);

            currentOrbit
                .append("text")
                .attr("id", `authVC_${value.data.relatedTo}`)
                .attr("text-anchor", "middle")
                .attr("x", 0)
                .attr("font-family", "IBM Plex Sans")
                .text("AuthVC");

            currentAuthVC = d3Data.g.select(`#authVC_${value.data.relatedTo}`)
        }

        // Add Holder label to orbit (object)
        if (isHolder) {
            d3Data.g.select(`#authHolder_${value.data.relatedTo}`).remove()
            currentOrbit.classed("setColorIsHolder", true);

            currentOrbit
                .append("text")
                .attr("id", `authHolder_${value.data.relatedTo}`)
                .attr("text-anchor", "middle")
                .attr("x", 0)
                .attr("font-family", "IBM Plex Sans")
                .text("AuthHolder");

            currentAuthHolder = d3Data.g.select(`#authHolder_${value.data.relatedTo}`)
        }

        // Add And Operator label to orbit (object)
        if (isAndOperator) {
            d3Data.g.select(`#authAndOperator_${value.data.relatedTo}`).remove()
            currentOrbit.classed("setColorIsAndOperator", true);

            currentOrbit
                .append("text")
                .attr("id", `authAndOperator_${value.data.relatedTo}`)
                .attr("text-anchor", "middle")
                .attr("x", 0)
                .attr("font-family", "IBM Plex Sans")
                .text("AND");

            currentAuthAndOperator = d3Data.g.select(`#authAndOperator_${value.data.relatedTo}`)
        }

        // Add Or Operator label to orbit (object)
        if (isOrOperator) {
            d3Data.g.select(`#authOrOperator_${value.data.relatedTo}`).remove()
            currentOrbit.classed("setColorIsOrOperator", true);

            currentOrbit
                .append("text")
                .attr("id", `authOrOperator_${value.data.relatedTo}`)
                .attr("text-anchor", "middle")
                .attr("x", 0)
                .attr("font-family", "IBM Plex Sans")
                .text("OR");

            currentAuthOrOperator = d3Data.g.select(`#authOrOperator_${value.data.relatedTo}`)
        }

        // Add Issuer label to orbit (object)
        if (isIssuer) {
            d3Data.g.select(`#authIssuer_${value.data.relatedTo}`).remove()
            currentOrbit.classed("setColorIsIssuer", true);

            currentOrbit
                .append("text")
                .attr("id", `authIssuer_${value.data.relatedTo}`)
                .attr("text-anchor", "middle")
                .attr("x", 0)
                .attr("font-family", "IBM Plex Sans")
                .text("AuthIssuer");

            currentAuthIssuer = d3Data.g.select(`#authIssuer_${value.data.relatedTo}`)
        }

        // Add Claim label to orbit (object)
        if (isClaim) {
            d3Data.g.select(`#authClaim_${value.data.relatedTo}`).remove()
            currentOrbit.classed("setColorIsClaim", true);

            currentOrbit
                .append("text")
                .attr("id", `authClaim_${value.data.relatedTo}`)
                .attr("text-anchor", "middle")
                .attr("x", 0)
                .attr("font-family", "IBM Plex Sans")
                .text("AuthClaim");

            currentAuthClaim = d3Data.g.select(`#authClaim_${value.data.relatedTo}`)
        }

        if (currentAuthResource.node()) {
            listLabel.push(currentAuthResource)
        }

        if (currentAuthSubject.node()) {
            listLabel.push(currentAuthSubject)
        }

        if (currentAuthAction.node()) {
            listLabel.push(currentAuthAction)
        }

        if (currentAuthVC.node()) {
            listLabel.push(currentAuthVC)
        }

        if (currentAuthHolder.node()) {
            listLabel.push(currentAuthHolder)
        }

        if (currentAuthAndOperator.node()) {
            listLabel.push(currentAuthAndOperator)
        }

        if (currentAuthOrOperator.node()) {
            listLabel.push(currentAuthOrOperator)
        }

        if (currentAuthIssuer.node()) {
            listLabel.push(currentAuthIssuer)
        }

        if (currentAuthClaim.node()) {
            listLabel.push(currentAuthClaim)
        }

        if (listLabel.length === 1) {
            listLabel[0].attr("y", -50).attr("font-size", "16px");
        }

        if (listLabel.length === 2) {
            listLabel[1].attr("y", -60).attr("font-size", "14px");
            listLabel[0].attr("y", -45).attr("font-size", "14px");
        }

        if (listLabel.length === 3) {
            listLabel[2].attr("y", -75).attr("font-size", "13px");
            listLabel[1].attr("y", -60).attr("font-size", "13px");
            listLabel[0].attr("y", -45).attr("font-size", "13px");
        }

        if (listLabel.length === 4) {
            listLabel[3].attr("y", -90).attr("font-size", "13px");
            listLabel[2].attr("y", -75).attr("font-size", "13px");
            listLabel[1].attr("y", -60).attr("font-size", "13px");
            listLabel[0].attr("y", -45).attr("font-size", "13px");
        }
    }

    d3Data.g.selectAll(".orbit").call(enters.updateOrbit);
    setData(newData);
};

export default definePolicy;

import { iD3Data, iData } from "../types";

interface DeleteD3ImplementParams {
    d3Data: iD3Data;
    implementId: string;
    data: iData;
    setData: React.Dispatch<React.SetStateAction<iData>>;
}

export const deleteD3Implement = ({ d3Data, implementId, data, setData }: DeleteD3ImplementParams): iData => {
    const newDataImplements = data.implementLinks.filter((item) => item._id !== implementId);

    const newDataObjects = data.objects.map((item) => {
        item.interfaces = item.interfaces.filter((interfaceObj) => {
            // Check the interface ID and object ID
            const interID = implementId.slice(0, 36)
            const objID = implementId.slice(36)

            if (interfaceObj._id === interID && item._id === objID) {
                return false;
            }

            return true;
        });

        return item;
    });

    const newData = {
        ...data,
        objects: newDataObjects,
        implementLinks: newDataImplements
    };

    setData(newData);
    return newData;
};

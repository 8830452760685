import { gql } from "@apollo/client";

export const GET_ALL_DATA_SERVERS = gql`
  query GetAllDataServers {
    niamDataServers {
      _id
      name
      description
      CLUSTER_SELECTED
      clientNameGCP
      autoDeployApiServer
      serviceAccount {
        _id
        cloud
      }
      cluster {
        _id
        clusterID
        name
        location
      }
      authZServer {
        _id
        connectionURL
        lastUpdate
        defaultOperator
        allowByDefault
        selfRelDepth
        isMostPermissive
        actionPermissionOrder
      }
      administrators {
          roles
          email
      }
      owners {
        _id
        email
      }
    }
  }
`;

export const SEND_EMAIL_TO_CLIENTS = gql`
  query sendEmailToClients($local: String!) {
    sendEmailToClients(input: { local: $local})
  }
`

export const SHUTDOWN_APIS = gql`
  mutation shutdownApis($local: String!) {
    shutdownApis(input: { local: $local}) {
      status
    }
  }
`

export const SHUTDOWN_SPECIFIC_API = gql`
  mutation shutdownSpecificApi( $clientNameGCP: String!) {
    shutdownSpecificApi(input: { clientNameGCP: $clientNameGCP}) {
      status
    }
  }
`

export const REDEPLOY_SPECIFIC_API = gql`
  mutation reDeploySpecificApi( $clientNameGCP: String!) {
    reDeploySpecificApi(input: { clientNameGCP: $clientNameGCP}) {
      status
    }
  }
`

export const UPDATE_STARTSTOP = gql`
  mutation updateStartStop($serverID: ID!, $start: Boolean!) {
    updateStartStop(input: { serverID: $serverID, start: $start }) {
      status
    }
  }
`

export const UPDATE_TO_RELEASED_SPECIFIC_API = gql`
  mutation updateToReleasedSpecificWorkload($clientNameGCP: String!) {
    updateToReleasedSpecificWorkload(input: { clientNameGCP: $clientNameGCP}) {
      status
    }
  }
`

export const GET_CREDENTIALS_INFO = gql`
  query getCredentialsInfo($serverID: ID) {
    getCredentialsInfo(input: { serverID: $serverID }) {
        apiKey
        apiEnabled
        superUserID
        authz3edgesID
        authz3edgesPWD
        proxy3edgesID
        proxy3edgesPWD
        authzClientID
        authzClientPWD
        proxyClientID
        proxyClientPWD
        niamSvcAcctClient {
          client_id
          client_secret
          ENC_KEY
        }
        niamSvcAcct {
          username
          pwd
        }
    }
  }
`

export const ALL_STATUS = gql`
  query getAllStatus($serverID: ID!) {
    getAllStatus(input: { serverID: $serverID }) {
      podStatus
      clusterStatus
      lastUpdate
      isProcessing
      cached
      niamLastReleaseDate
      lastReleaseDate
      podError
      clientNameGCP
    }
  }
`

export const GET_ALL_DATA_SERVERS_BY_TENANT = gql`
  query getAllDataServersByTenant($tenantID: ID!) {
      niamDataServers: niamDataServersByTenant(input: { tenantID: $tenantID }) {
          _id
          name
          description
          CLUSTER_SELECTED
          clientNameGCP
          autoDeployApiServer
          serviceAccount {
            _id
            cloud
          }
          cluster {
            _id
            clusterID
            name
            location
          }
          authZServer {
            _id
            connectionURL
            lastUpdate
            defaultOperator
            allowByDefault
            selfRelDepth
            isMostPermissive
            actionPermissionOrder
          }
          lastReleaseDate
          administrators {
              roles
              email
          }
          owners {
            _id
            email
          }
      }
  }
`;

export const DELETE_DATA_SERVER = gql`
  mutation deleteNiamDataServer($serverID: ID!) {
      deleteNiamDataServer(input: { serverID: $serverID })
  }
`;

// export const SAVE_SUPER_USER_ID = gql`
//   mutation saveSuperUserID($serverID: ID!, $superUserID: String) {
//     saveSuperUserID(input: { serverID: $serverID, superUserID: $superUserID })
//   }
// `

export const SAVE_API_KEY_DISABLED_ENABLED = gql`
  mutation saveApiKeyDisabledEnabled($serverID: ID!, $apiEnabled: Boolean) {
    saveApiKeyDisabledEnabled(input: { serverID: $serverID, apiEnabled: $apiEnabled })
  }
`

import styled from "styled-components";

export const Container = styled.div`
    .description {
        font-size: 20px;
    }

    .buttons {
        margin-top: 20px;
        display: flex;
        gap: 10px;
        flex-direction: row-reverse;
    }
`;

export const ConfirmButton = styled.div`
    color: #ffffff;
    padding: 10px 17px;
    border-radius: 4px;
    cursor: pointer;
    background-color: ${({ color }) => color || "#ffffff"};

    :hover {
        background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
    }
`;

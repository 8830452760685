import { gql } from "@apollo/client";

export const GET_ALL_TENANTS = gql`
  query GetAllTenants {
      niamTenants {
          _id
          name
          administrators {
              roles
              email
          }
          owners {
            _id
            email
          }
      }
  }
`;

export const GET_TENANT_BY_ID = gql`
  query GetTenantByID($tenantID: ID!) {
      niamTenant(input: { tenantID: $tenantID }) {
          _id
          name
          administrators {
              roles
              email
          }
          owners {
            _id
            email
          }
      }
  }
`;

export const DELETE_TENANT = gql`
  mutation deleteTenant($tenantID: ID!) {
      deleteNiamTenant(input: { tenantID: $tenantID })
  }
`;

export const ADD_NEW_PRIM_TENANT = gql`
  mutation AddNewNiamTenant($name: String!) {
      addNewNiamTenant(input: { newNiamTenantInput: { name: $name } }) {
          _id
          name
          administrators {
              roles
              email
          }
          owners {
            _id
            email
          }
      }
  }
`;

export const UPDATE_PRIM_TENANT = gql`
  mutation updateNiamTenant($tenantID: ID!, $name: String!) {
      updateNiamTenant(input: { tenantID: $tenantID, params: { name: $name } }) {
          _id
          name
      }
  }
`;

export const EXPORT_PRIM_TENANT = gql`
  query exportConfig($serverID: ID!) {
      exportConfig(input: { serverID: $serverID }) {
        nodes {
          data {
            labels
            id
            name
            x
            y
            properties {
              __typename
              ... on NiamTag {
                name
                displayName
              }
              ... on NiamObject {
                name
                description
                displayName
                icon
                isSubject
                isAction
                isVC
                isHolder
                isAndOperator
                isOrOperator
                isIssuer
                isClaim
                isResource
                selfRegistration
              }
              ... on NiamEnum {
                name
                displayName
                enumerates
              }
              ... on NiamInterface {
                name
                displayName
                icon
              }
              ... on NiamField {
                name
                displayName
                fieldType
                relatedTo
                relatedToName
                isArray
                icon
                isRequired
                isNaming
                isSecret
                maxLength
                minLength
                relLabelName
                direction
                accessibleOnOppositeSide
                oppositeSideFieldName
                regex
                isUnique
                isDisplay
                customValidationFunc
                properties
              }
              ... on NiamCustomMutation {
                name
                displayName
                args
                mutatuinName
                inputSchema
                returnType
                script
                isResource
              }
              ... on NiamScript {
                name
                displayName
                returnType
                funcName
                scriptText
              }
              ... on NiamNodeRelationship {
                name
                displayName
                description
                isResource
                fieldType
                relation {
                  objFrom { name, displayName }
                  objTo { name, displayName }
                  rel { name, displayName }
                  relFrom { name, displayName }
                  relTo { name, displayName }
                  script { name, displayName }
                  relScript { name, displayName }
                }
              }
            }
          }
        }
        edges {
          data {
            source
            target
            name
            id
            properties {
              name
              displayName

              # Specific for NiamScript
              relatedTo
              fieldType
              eventType
            }
          }
        }
      }
    }
`;

export const IMPORT_PRIM_TENANT = gql`
  mutation importConfig($serverID: String!, $file: Upload!) {
      importConfig(input: { serverID: $serverID, file: $file })
    }
`;

/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/function-component-definition */
import { toastError } from "@3edges/utils/dist/toastify";
import { D3Canvas } from "components/PrimGraphicalCanvas";
import SidebarWrapper from "components/SidebarWrapper";
import { CanvasContextProvider } from "contexts/canvasContext";
import { useData } from "contexts/dataContext";
import { ContextMenuProvider } from "contexts/rightClickContext";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useParams } from "react-router-dom";

const CanvasPage = () => {
    const { t } = useTranslation();
    const { serverID } = useParams<{ serverID: string }>();
    const { getDataServer, errorDS, loadingDS } = useData()

    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = (): void => {
        setOpen(true);
    };

    const handleDrawerClose = (): void => {
        setOpen(false);
    };

    useEffect(() => {
        if (serverID) {
            getDataServer(serverID)
        }
    }, [serverID])

    if (errorDS) {
        toastError(t(`serverInfo:${errorDS.message}`));
        return <Redirect to="/" />;
    }

    if (loadingDS) {
        return <div>Loading...</div>
    }

    return (
        <SidebarWrapper open={open} handleDrawerClose={handleDrawerClose}>
            <CanvasContextProvider>
                <ContextMenuProvider>
                    <D3Canvas open={open} handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen} />
                </ContextMenuProvider>
            </CanvasContextProvider>
        </SidebarWrapper>
    );
}

export default CanvasPage;

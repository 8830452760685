import { iD3Selection } from "components/PrimGraphicalCanvas/types";
import { useData } from "contexts/dataContext";
import * as d3 from "d3";
import { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { enterLink, updateLink } from "./enterLink";
import enterOrbit from "./enterOrbit";
import { updateOrbit } from "./updateOrbits";

export const useEnters = (): {
    enterOrbit: (selection: iD3Selection) => void;
    updateOrbit: (selection: iD3Selection) => void;
    enterLink: (selection: iD3Selection) => void;
    updateLink: (selection: d3.Selection<d3.BaseType, unknown, d3.BaseType, unknown>) => void;
} => {
    const theme = useContext(ThemeContext);
    const dataTransfer = useData();

    return {
        enterOrbit: enterOrbit(theme, dataTransfer),
        updateOrbit: updateOrbit(theme, dataTransfer),
        enterLink: enterLink(theme),
        updateLink: updateLink(theme)
    };
};

export const HundredPSVG = styled.svg`
    width: 100%;
    height: calc(100vh - 70px);
    background-color: ${(props) => props.theme.colors.background};
    .innerLayer {
        stroke-width: 2px;
    }
    .outerLayer {
        stroke-width: 2px;
    }
    .iconLayer {
        stroke: #ffffff;
        stroke-width: 1px;
    }
    .link {
        stroke-width: 10px;
        stroke-opacity: 0.5;
    }
    .link:not(.NR_Link, .petals) path:hover, .linkHover {
        stroke-width: 3px !important;
    }
    .link:not(.NR_Link) path:hover, .linkHoverPetal {
        stroke-width: 1.5px !important;
    }
    .implementLink {
        stroke: #cccccc;
    }
    .implementLink:hover {
        stroke-width: 5px;
        cursor: pointer;
    }
    .pathsLink {
        stroke: rgb(100, 0, 100, 0.6);
    }
    .pathsLink:hover {
        stroke-width: 5px;
        cursor: pointer;
    }
    .petals {
        stroke-opacity: 0.5;
    }
`;

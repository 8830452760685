import styled from "styled-components";

export const SContextMenu = styled.div.attrs((props) => ({}))<{
    x: number;
    y: number;
}>`
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.sizes.normalBorderRadius};
    border: ${({ theme }) => theme.sizes.normalBorderSize} solid ${({ theme }) => theme.colors.borderLight};
    box-shadow: 0px 2px ${({ theme }) => theme.sizes.blurRadius} 0px ${({ theme }) => theme.colors.shadow};
    width: 250px;
    position: absolute;
    left: ${(props) => props.x}px;
    top: ${(props) => props.y}px;
`;
export const SNestedMenu = styled.div<{
    opensToRight: boolean;
}>`
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme, opensToRight }) => (opensToRight
        ? `0 ${theme.sizes.normalBorderRadius} ${theme.sizes.normalBorderRadius} ${theme.sizes.normalBorderRadius}`
        : `${theme.sizes.normalBorderRadius} 0 ${theme.sizes.normalBorderRadius} ${theme.sizes.normalBorderRadius}`)};
    border: ${({ theme }) => theme.sizes.normalBorderSize} solid ${({ theme }) => theme.colors.borderLight};
    box-shadow: 0px 2px ${({ theme }) => theme.sizes.blurRadius} 0px ${({ theme }) => theme.colors.shadow};
    width: 250px;
    position: absolute;
    left: ${({ opensToRight }) => (opensToRight
        ? 250
        : -168)}px;
    top: 0px;
    padding-top: 16px;
    z-index: 1;
`;

export const STitleBar = styled.div`
    color: ${({ theme }) => theme.colors.fontLight};
    padding: 16px;
    height: 15px;
    margin-bottom: 8px;
    font-family: IBM Plex Mono;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    cursor: default;
    min-width: fit-content;
    white-space: nowrap;
`;

export const SMenuItem = styled.div<{
    isDisabled?: boolean;
    className?: string;
}>`
    height: 48px;
    width: 100%;
    margin-top: -8px;
    /* margin-right:-10px; */
    margin-bottom: 8px;
    flex-direction: row;
    display: flex;
    align-items: center;
    position: relative;
    min-width: fit-content;
    :hover {
        background-color: ${({ theme, isDisabled }) => !isDisabled && theme.colors.lightOnHoverOverlay};
        cursor: ${({ isDisabled }) => !isDisabled && "pointer"};
    }
`;

export const SMenuItemTitle = styled.div<{
    isDisabled?: boolean;
}>`
    font-family: IBM Plex Sans;
    font-size: 16px;
    margin-right: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: ${({ theme }) => theme.colors.fontNormal};
    cursor: default;
    flex: 1;
    :hover {
        cursor: ${({ isDisabled }) => !isDisabled && "pointer"};
    }
    min-width: fit-content;
    white-space: nowrap;
`;

import { ButtonListFlex, ConfirmButton } from "components/ModalDataServer/styled";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "ui-components/modal";
import { TextField } from "ui-components/styleds";
import { isEmpty, isNotEmpty } from "@3edges/utils/dist/utils";

interface ModalChangeEmailProps {
    isModalOpenEmail: boolean;
    tmpEmail: string;
    setTmpEmail: (email: string) => void;
    waitEmail: boolean;
    onClose: () => void;
    onSave: () => void;
}

function ModalChangeEmail({
    isModalOpenEmail,
    tmpEmail,
    setTmpEmail,
    waitEmail,
    onClose,
    onSave
}: ModalChangeEmailProps): React.ReactElement {
    const { t } = useTranslation();

    useEffect(() => {
        if (!isModalOpenEmail) {
            setTmpEmail("");
        }
    }, [isModalOpenEmail]);

    return (
        <Modal isOpen={isModalOpenEmail} onClose={onClose} style={{ width: "600px" }}>
            <h1>
                {t("profile:change.email.title")}
            </h1>

            <TextField
                value={tmpEmail}
                type="email"
                onChange={(e) => {
                    setTmpEmail(e.target.value);
                }}
                label={t("profile:tmpEmail.label")}
                onKeyPress={(e) => {
                    if ((e.keyCode === 13 || e.charCode === 13) && isNotEmpty(tmpEmail)) {
                        onSave();
                    }
                }}
            />

            <ButtonListFlex>
                <ConfirmButton onClick={onSave} disabled={waitEmail || isEmpty(tmpEmail)}>
                    {t("root:btn.save.label")}
                </ConfirmButton>
            </ButtonListFlex>
        </Modal>
    );
}

export default ModalChangeEmail;

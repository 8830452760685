import { AddRounded, ApartmentOutlined, RotateRightOutlined } from "@mui/icons-material";
import React from "react";
import SVG from "react-inlinesvg";
import styled from "styled-components";

export const Card = styled.div<{ $isAdd?: boolean; $smallCard?: boolean }>`
    cursor: pointer;
    width: ${({ $smallCard }) => ($smallCard ? "100px" : "300px")};
    height: ${({ $smallCard }) => ($smallCard ? "90px" : "236px")};
    margin: ${({ $smallCard }) => ($smallCard ? "5px 7px 0 0" : "10px 10px 0 0")};
    padding: ${({ $smallCard, $isAdd }) => ($smallCard ? "5px 5px" : $isAdd ? "45px 0": "34px 46px 40px")};
    box-sizing: border-box;
    flex-direction: column;
    display: flex;
    border-radius: ${({ theme }) => theme.sizes.normalBorderRadius};
    background-color: ${({ theme, $isAdd }) => ($isAdd ? " rgba(0, 136, 255, 0.2)" : theme.colors.white)};
    justify-content: start;
    align-items: center;

    ${(props) => (props.$isAdd
            ? ""
            : `
    :hover path {
        fill: #1e88e5 !important;
    }
    :hover span {
        color: #1e88e5 !important;
    }
  `)}
    z-index: 1;
`;

export const IconBox = styled.img<{ $isAdd?: boolean }>`
    flex: 0;
    width: ${(props) => (props.$isAdd ? "64px" : "96px")};
    min-height: ${(props) => (props.$isAdd ? "64px" : "96px")};
    margin: ${(props) => (props.$isAdd ? "20px 0px 12px" : "0px 0px 36px")};
    object-fit: cover;
`;

export const PlayApiServerIconStyled = styled.div<{ $smallCard?: boolean }>`
    & span {
        position: ${({ $smallCard }) => ($smallCard ? "relative" : "absolute")};
        right: ${({ $smallCard }) => ($smallCard ? "5px !important" : "26px !important")};
        top: ${({ $smallCard }) => ($smallCard ? 0 : "60px !important")};
        z-index: 2;
        cursor: pointer;
        transition: all 0.2s !important;
        color: green;
        font-size: ${({ $smallCard }) => ($smallCard ? "25px !important" : "33px !important")};

        :hover {
            font-size: ${({ $smallCard }) => ($smallCard ? "25px !important" : "39px !important")};
            right: ${({ $smallCard }) => ($smallCard ? 0 : "23px !important")};
            top: ${({ $smallCard }) => ($smallCard ? 0 : "57px !important")};
        }
    }
`;

export const ProcessingApiServerIconStyled = styled(RotateRightOutlined) <React.SVGProps<SVGElement> & { $insideBtn?: boolean; $smallCard?: boolean, title: string }>`
    flex: 0;
    width: ${({ $smallCard }) => ($smallCard ? "25px !important" : "33px !important")};
    height: ${({ $smallCard }) => ($smallCard ? "25px !important" : "33px !important")};
    position: ${({ $smallCard }) => ($smallCard ? "relative" : "absolute")};
    right: ${({ $insideBtn, $smallCard }) => ($smallCard ? "5px !important" : $insideBtn ? "15px !important" : "26px !important")};
    top: ${({ $insideBtn, $smallCard }) => ($smallCard ? 0 : $insideBtn ? "3px !important" : "60px !important")};
    z-index: 2;
    cursor: pointer;
    transition: all 0.2s !important;
    fill: ${({ $insideBtn }) => ($insideBtn ? "#fff" : "orange")} !important;

    animation-name: loadingCircle;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    @keyframes loadingCircle {
        from { transform: rotate(-360deg); }
        to { transform: rotate(360deg); }
    }
`;

export const StopApiServerIconStyled = styled.div<{ $smallCard?: boolean }>`
    & span {
        position: ${({ $smallCard }) => ($smallCard ? "relative" : "absolute")};
        right: ${({ $smallCard }) => ($smallCard ? "5px !important" : "26px")};
        top: ${({ $smallCard }) => ($smallCard ? 0 : "60px")};
        z-index: 2;
        cursor: pointer;
        transition: all 0.2s !important;
        color: darkred;
        font-size: ${({ $smallCard }) => ($smallCard ? "25px !important" : "33px !important")};

        :hover {
            font-size: ${({ $smallCard }) => ($smallCard ? "25px !important" : "39px !important")};
            right: ${({ $smallCard }) => ($smallCard ? 0 : "23px !important")};
            top: ${({ $smallCard }) => ($smallCard ? 0 : "57px !important")};
        }
    }
`;

export const TenantUsersIconStyled = styled.div<{ $smallCard?: boolean }>`
    & span {
        flex: 0;
        position: ${({ $smallCard }) => ($smallCard ? "relative" : "absolute")};
        left: ${({ $smallCard }) => ($smallCard ? 0 : "193px !important")};
        top: ${({ $smallCard }) => ($smallCard ? 0 : "18px !important")};
        z-index: 2;
        cursor: pointer;
        transition: all 0.2s !important;
        color: #000;
        font-size: 25px !important;

        :hover {
            color: #e53935 !important;
            font-size: ${({ $smallCard }) => ($smallCard ? "25px !important" : "29px !important")};
            left: ${({ $smallCard }) => ($smallCard ? 0 : "191px !important")};
            top: ${({ $smallCard }) => ($smallCard ? 0 : "16px !important")};
        }
    }
`;

export const UpdateDataServerIconStyled = styled.div<{ $smallCard?: boolean }>`
    & span {
        flex: 0;
        position: ${({ $smallCard }) => ($smallCard ? "relative" : "absolute")};
        right: ${({ $smallCard }) => ($smallCard ? "5px !important" : "26px !important")};
        top: ${({ $smallCard }) => ($smallCard ? 0 : "60px !important")};
        z-index: 2;
        cursor: pointer;
        transition: all 0.2s !important;
        color: #e53935;
        font-size: 25px !important;

        :hover {
            color: #e53935 !important;
            font-size: ${({ $smallCard }) => ($smallCard ? "25px !important" : "29px !important")};
            right: ${({ $smallCard }) => ($smallCard ? 0 : "23px !important")};
            top: ${({ $smallCard }) => ($smallCard ? 0 : "57px !important")};
        }
    }
`;

export const ShareDataServerIconStyled = styled.div<{ $smallCard?: boolean }>`
    & span {
        flex: 0;
        position: ${({ $smallCard }) => ($smallCard ? "relative" : "absolute")};
        left: ${({ $smallCard }) => ($smallCard ? 0 : "192px !important")};
        top: ${({ $smallCard }) => ($smallCard ? 0 : "18px !important")};
        z-index: 2;
        cursor: pointer;
        transition: all 0.2s !important;
        color: #000;
        font-size: 25px !important;

        :hover {
            color: #e53935 !important;
            font-size: ${({ $smallCard }) => ($smallCard ? "25px !important" : "29px !important")};
            left: ${({ $smallCard }) => ($smallCard ? 0 : "190px !important")};
            top: ${({ $smallCard }) => ($smallCard ? 0 : "16px !important")};
        }
    }
`;

export const EditDataServerIconStyled = styled.div<{ $smallCard?: boolean }>`
    & span {
        flex: 0;
        position: ${({ $smallCard }) => ($smallCard ? "relative" : "absolute")};
        left: ${({ $smallCard }) => ($smallCard ? "3px !important" : "231px !important")};
        top: ${({ $smallCard }) => ($smallCard ? 0 : "19px !important")};
        z-index: 2;
        cursor: pointer;
        transition: all 0.2s !important;
        color: #000;
        font-size: 25px !important;

        :hover {
            color: #e53935 !important;
            font-size: ${({ $smallCard }) => ($smallCard ? "25px !important" : "29px !important")};
            left: ${({ $smallCard }) => ($smallCard ? 0 : "229px !important")};
            top: ${({ $smallCard }) => ($smallCard ? 0 : "17px !important")};
        }
    }
`;

export const DelDataServerIconStyled = styled.div<{ $smallCard?: boolean }>`
    & span {
        flex: 0;
        position: ${({ $smallCard }) => ($smallCard ? "relative" : "absolute")};
        left: ${({ $smallCard }) => ($smallCard ? "3px !important" : "262px !important")};
        top: ${({ $smallCard }) => ($smallCard ? 0 : "19px !important")};
        z-index: 2;
        cursor: pointer;
        transition: all 0.2s !important;
        color: #000;
        font-size: 25px !important;

        :hover {
            color: #e53935 !important;
            font-size: ${({ $smallCard }) => ($smallCard ? "25px !important" : "29px !important")};
            left: ${({ $smallCard }) => ($smallCard ? 0 : "260px !important")};
            top: ${({ $smallCard }) => ($smallCard ? 0 : "17px !important")};
        }
    }
`

export const AccountTreeOutlinedStyled = styled.div<{ $smallCard?: boolean }>`
    & span {
        margin-top: 35px;
        margin-bottom: 15px;
        font-size: 70px;
        color: rgb(144, 164, 174);
    }
`

export const ApartmentOutlinedStyled = styled(ApartmentOutlined) <React.SVGProps<SVGElement> & { $smallCard?: boolean }>`
    width: 70px !important;
    height: 70px !important;
    fill: rgb(144, 164, 174) !important;
    margin-top: 35px;
    margin-bottom: 15px;
`

export const AddDataServerIconStyled = styled(AddRounded) <React.SVGProps<SVGElement> & { $smallCard?: boolean }>`
    flex: 0;
    fill: #1e88e5 !important;
    stroke-width: 5;
    width: ${({ $smallCard }) => ($smallCard ? "32px !important" : "64px !important")};
    min-height: ${({ $smallCard }) => ($smallCard ? "32px !important" : "64px !important")};
    margin: ${({ $smallCard }) => ($smallCard ? "10px 0px 6px" : "20px 0px 12px")};
`;

export const CardIconStyled = styled(SVG)<{ $smallCard?: boolean; $isUsersCard?: boolean }>`
    flex: 0;
    width: 96px;
    min-height: 96px;
    margin: 15px 0 15px 0;

    & path {
        fill: rgb(144, 164, 174);
    }

    :hover path {
        fill: #1e88e5 !important;
    }
`;

const fontsizeByCard = (smallCard: boolean): string => (smallCard ? "70%" : "16px");

export const TextBox = styled.p<{
    $isAdd?: boolean;
    fontSize?: string;
    $smallCard?: boolean;
}>`
    flex: 0;
    width: 100%;
    border: "1px solid red";
    min-height: 20px;
    font-family: IBM Plex Sans;
    font-size: ${({ fontSize, $smallCard }) => (fontSize ? fontSize : fontsizeByCard($smallCard))};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    margin: 0;
    color: ${({ theme, $isAdd }) => ($isAdd ? "#1e88e5" : theme.colors.fontNormal)};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: opacity 0.5s ease-in-out;
`;

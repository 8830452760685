import {
    Button,
    ButtonProps,
    Tab as MUTab,
    Tabs as MUTabs,
    TabProps,
    TabsProps,
    TextField,
    TextFieldProps
} from "@mui/material";
import styled from "styled-components";

export const AlertDSName = styled.div<{ $color?: string; $noMargin?: boolean }>`
    color: ${({ $color }) => $color || 'gray'};
    font-size: 14px;
    margin-top: ${({ $noMargin }) => $noMargin ? 0 : "-5px"};
    margin-bottom: ${({ $noMargin }) => $noMargin ? 0 : "5px"};
`;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const Tabs = styled(MUTabs).attrs((props: TabsProps) => <TabsProps>{
            ...props,
            classes: { indicator: "indicator", flexContainer: "flexContainer" },
            indicatorColor: "primary",
            centered: true
        })<TabsProps>`
    && {
        .flexContainer {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: row;
            align-items: center;
        }
        .indicator {
            display: flex;
            justify-content: center;
            background-color: transparent;
            & > span {
                max-width: 44px;
                width: 100%;
                background-color: ${({ theme }) => theme.colors.primary};
            }
        }
    }
`;
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const Tab = styled(MUTab).attrs((props: TabProps) => <TabProps>{ ...props })`
    && {
        padding: 0px 20px;
        box-sizing: border-box;
        flex: 0;
        font-family: IBM Plex Sans;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: center;
        color: ${({ theme }) => theme.colors.fontNormal};
        text-transform: none;
        min-width: 140px;
        display: flex;

        & > span {
            flex: 0;
            min-width: 140px;
        }
        &:focus {
            opacity: 1;
        }
    }
`;

export const FlexContainer = styled.div`
    box-sizing: border-box;
    padding: 48px 32px 32px;
`;
export const ModalTitle = styled.p`
    width: 100%;
    height: 32px;
    margin: 0 0 25px;
    box-sizing: border-box;
    font-family: IBM Plex Sans;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: ${({ theme }) => theme.colors.fontNormal};
`;

export const MiniSpan = styled.div`
    font-size: 12px;
    color: #555;
`;

export const UnderTabsBR = styled.div`
    width: 100%+64px;
    margin: -1px -32px 0;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.borderLight};
`;

export const FlexForm = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 48px;
    box-sizing: border-box;
`;

export const FormTitle = styled.p`
    width: 100%;
    height: 32px;
    margin: 0 0 32px;
    box-sizing: border-box;
    font-family: IBM Plex Sans;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: ${({ theme }) => theme.colors.fontNormal};
`;

export const TextFieldStyled = styled(TextField).attrs<TextFieldProps>(() => {
    const attrs: TextFieldProps = {
        variant: "outlined"
    };
    return attrs;
})<TextFieldProps>`
    && {
        margin: 16px 0;
    }
    && input {
        height: 58px;
        max-height: 58px;
        box-sizing: border-box;
        padding: 22.2px 24px 24.8px;
        font-family: IBM Plex Sans;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        ::placeholder {
            color: rgba(0, 0, 0, 0.6);
        }
        color: ${({ theme }) => theme.colors.fontNormal};
    }
`;

export const TextAreaStyled = styled(TextField).attrs<TextFieldProps>(() => {
    const attrs: TextFieldProps = {
        variant: "outlined",
        multiline: true,
        minRows: 1
    };
    return attrs;
})<TextFieldProps>`
    && div {
        margin: 16px 0;
        padding: 0;
    }
    && label {
        transform: translate(14px, 30px) scale(1);
    }
    && label.MuiInputLabel-shrink {
        transform: translate(14px, 10px) scale(0.75);
    }
    && textarea {
        box-sizing: border-box;
        padding: 18px 16px;
        font-family: IBM Plex Sans;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        ::placeholder {
            color: rgba(0, 0, 0, 0.6);
        }
        color: ${({ theme }) => theme.colors.fontNormal};
    }
`;

export const Hint = styled.p`
    width: 202px;
    height: 16px;
    margin: 0;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.38);
`;

export const ButtonListFlex = styled.div<{ $justifyContent?: string }>`
    margin: 32px 0 0 0;
    display: flex;
    height: 38px;
    justify-content: ${({ $justifyContent }) => $justifyContent || "end"};

    .additional-btns {
        width: 100%;
    }
`;

export const DefaultButton = styled(Button).attrs((prop) => {
    const props: ButtonProps = {
        color: prop.color ? prop.color : "primary",
        variant: "contained"
    };
    return props;
})`
    && {
        height: 38px;
        margin: 0 8px 0 0;
        padding: 10px 16px;
        box-sizing: border-box;
        border-radius: 4px;
        font-family: IBM Plex Sans;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: center;
    }
`;

export const ConfirmButton = styled(Button).attrs((prop) => {
    const props: ButtonProps = {
        color: prop.color ? prop.color : "primary",
        variant: "contained"
    };
    return props;
})`
    && {
        height: 38px;
        margin: 0 0 0 8px;
        padding: 10px 16px;
        box-sizing: border-box;
        border-radius: 4px;
        font-family: IBM Plex Sans;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: center;
    }
`;
export const CancelButton = styled(Button).attrs(() => {
    const props: ButtonProps = { color: "primary", variant: "outlined" };
    return props;
})`
    && {
        width: 76px;
        height: 38px;
        margin: 0 8px 0 8px;
        padding: 10px 16px;
        box-sizing: border-box;
        border-radius: 4px;
        font-family: IBM Plex Sans;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: center;
        border: solid 1px rgba(0, 0, 0, 0.12);
    }
`;

import styled from "styled-components";

export const Form = styled.form`
    box-sizing: border-box;
    flex: 0;
    display: flex;
    flex-direction: column;
    padding: 12px;
    align-items: stretch;
    max-width: 500px;
    margin: auto;
`;

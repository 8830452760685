import { gql } from "@apollo/client";

export const PRIM_SET_AS_VERIFIED = gql`
  query setAccountAsVerified($email: String!, $randomCode: String!) {
      setAccountAsVerified(input: { email: $email, randomCode: $randomCode }) {
          isVerified
          _id
      }
  }
`;

export const PRIM_SET_AS_VERIFIED_BY_ADMIN = gql`
  query setAccountAsVerifiedByAdmin($email: String!, $verificationCode: String!) {
      setAccountAsVerifiedByAdmin(input: { email: $email, verificationCode: $verificationCode }) {
          isVerified
          _id
      }
  }
`;

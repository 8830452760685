import { iD3Data, iData } from "../types";

interface DeleteD3CustomMutationParams {
    d3Data: iD3Data;
    customMutationId: string;
    data: iData;
    setData: React.Dispatch<React.SetStateAction<iData>>;
}

export const deleteD3CustomMutation = ({ d3Data, customMutationId, data, setData }: DeleteD3CustomMutationParams): iData => {
    const newDataCustomMutations = data.customMutations.filter((item) => item._id !== customMutationId);

    const newData = {
        ...data,
        customMutations: newDataCustomMutations
    };

    setData(newData);
    return newData;
};

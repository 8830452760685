/* eslint-disable react/forbid-component-props */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { AccountCircle, Cancel, ExitToApp, SettingsOutlined } from "@mui/icons-material";
import MenuIcon from '@mui/icons-material/Menu';
import { Button, IconButton, InputAdornment, Menu, MenuItem, TextField, Theme, useTheme } from "@mui/material";
import { createStyles, makeStyles } from '@mui/styles';
import logoPink from "assets/images/logo-pink.png";
import logo from "assets/images/logo.png";
import { clearStorage, getStorage } from "cache";
import clsx from 'clsx';
import { ConfigurationActions } from "components/ConfigurationActions";
import ModalDS from "components/ModalDataServer";
import { MainNavBarItem } from "components/PrimGraphicalCanvas/styled";
import { useCookie } from "contexts/cookieContext";
import { useData } from "contexts/dataContext";
import * as d3 from "d3";
import { REACT_ENV } from "environmentVariables";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { titleCase } from "utils";
import { OIDC_Logout } from "utilsFn";
import { Header } from "./styled";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    }
  }),
);

function PanelHeader({ refetchPrimInfo, showServerOptions = false, open, handleDrawerClose, handleDrawerOpen, d3Data = null, data = null, container = null }: any): React.ReactElement {
    const { t } = useTranslation();
    const { server, tenant, setItemSelected, setZoom, zoom, initialCenterX, initialCenterY } = useData();
    const { getIDTokenCookie, removeAllCookies } = useCookie();
    const currentCookieIDToken = getIDTokenCookie();

    const history = useHistory();
    const currentUser = getStorage<any>("user");
    const isSocial = getStorage<any>(REACT_ENV.REACT_APP_SOCIAL_PROVIDER_LOCAL_STORAGE_NAME);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editDataServerID, setEditDataServerID] = useState<any | null>();

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const [searchBy, setSearchBy] = useState("");
    const classes = useStyles();
    const theme = useTheme();

    useEffect(() => {
        if (d3Data && d3Data.g && searchBy.length > 0) {
            // Try to find the item
            const item: any = data.objects.find(i => i.displayName.toLowerCase() === searchBy.toLowerCase())

            if (item) {
                // Centralize node after added
                const transformCenter: any = { k: zoom.k, x: initialCenterX - (item.x * zoom.k), y: initialCenterY - (item.y * zoom.k) - 65 }
                setZoom(transformCenter);
                d3Data.g.transition().duration(500).attr("transform", `translate(${transformCenter.x}, ${transformCenter.y}) scale(${transformCenter.k})`);

                // Keep Zoom updated with new positions
                (container as any).call(
                    d3.zoom().transform,
                    d3.zoomIdentity.translate(transformCenter.x, transformCenter.y).scale(transformCenter.k)
                );

                setItemSelected({
                    data: {
                        type: "Object",
                        ...item,
                    },
                    hasSaved: true
                });
            }
        }
    }, [d3Data, searchBy])

    useEffect(() => {
        if (isModalOpen) {
            setEditDataServerID(server._id);
        }
    }, [isModalOpen]);

    if (!currentUser) {
        clearStorage();
        removeAllCookies();
        globalThis.location.href = "/login"
        return
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void =>
    {
        setAnchorEl(null);
    };

    return (
        <Header $open={open}>
            <ModalDS
                refetchPrimInfo={refetchPrimInfo}
                apiServerID={editDataServerID}
                isModalOpen={isModalOpen}
                tenantID={tenant?._id}
                onClose={(dataSaved?: any, closeModal?: boolean) => {
                    if (!dataSaved || closeModal) {
                        setIsModalOpen(false)
                    }
                }}
            />

            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => open ? handleDrawerClose(false) : handleDrawerOpen(true)}
                edge="start"
                // eslint-disable-next-line react/forbid-component-props
                className={clsx(classes.menuButton, open && classes.hide)}
              >
                <MenuIcon />
            </IconButton>

            <img
                src={logo}
                data-cy="headerPage_btnLogo"
                alt={t("root:project.logo")}
                height="40"
                style={{ cursor: "pointer" }}
                onKeyPress={() => {}}
                onClick={() =>
                {
                    if (server) {
                        if (server && tenant) {
                            history.push(`/${tenant._id}/servers`);
                        } else {
                            history.push("/servers");
                        }
                    } else {
                        history.push("/");
                    }
                }}
                onMouseEnter={(e) => {
                    e.currentTarget.src = logoPink;
                }}
                onMouseOut={(e) => {
                    e.currentTarget.src = logo;
                }}
                onBlur={(e) => {
                    e.currentTarget.src = logo;
                }}
                width="auto"
            />

            <div style={{ display: 'flex', alignItems: "center", width: '100%', marginLeft: 15, justifyContent: "center"  }}>
                {server && showServerOptions && (
                    <>
                        <ConfigurationActions refetchPrimInfo={refetchPrimInfo} dataCollection={server} openConfig={() => setIsModalOpen(true)} />

                        <MainNavBarItem onClick={() => { setIsModalOpen(true); }}>
                            {server.name}
                        </MainNavBarItem>

                        <div style={{ display:'flex', width: '100%', justifyContent:'center' }}>
                            <TextField
                                className="searchByObjectName"
                                label='Search by object name'
                                value={searchBy}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                onChange={(e) => {
                                    setSearchBy(e.target.value);
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setSearchBy("")} edge="end">
                                                {searchBy.length > 0 ? <Cancel /> : ""}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div>
                    </>
                )}
            </div>

            <Button onClick={handleClick} data-cy="headerPage_btnSettings" style={{ minWidth: 'auto' }}>
                <span id="username">
                    {currentUser.name && `${titleCase(currentUser.name)} `}
                    ({titleCase(currentUser.email)})
                </span>

                <AccountCircle style={{ margin: "0 0 0 16px" }} />
            </Button>

            {/* placing the anchor on the bottom right corner of the button so that the drop down menu shows up directly below the button*/}
            <Menu anchorEl={anchorEl} style={{ top: 28, left:2.7 }} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} transformOrigin={{ vertical: 'bottom', horizontal: 'right' }} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        history.push("/profile");
                    }}
                >
                    <SettingsOutlined style={{ margin: "0 16px 0 0" }} />

                    {t("auth:profile.title")}
                </MenuItem>

                <MenuItem
                    onClick={async () => {
                        if(isSocial) {
                            handleClose();
                            clearStorage();
                            removeAllCookies();
                            globalThis.location.href = "/login"
                        } else {
                            handleClose();
                            await OIDC_Logout(currentCookieIDToken);
                        }
                    }}
                >
                    <ExitToApp style={{ margin: "0 16px 0 0" }} />

                    {t("auth:sign-out.title")}
                </MenuItem>
            </Menu>
        </Header>
    );
}

export default PanelHeader;

import { Button } from "@mui/material";
import styled from "styled-components";

export const ContainerRegister = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const BackButton = styled(Button)`
    && {
        font-weight: bold;
        color: gray;
        width: 100px;
        height: 48px;
        margin: 16px 0 0;
        border-radius: 4px;
        background-color: ${(props) => props.theme.colors.default};

        :hover {
            color: #1e88e5;
        }
    }
`;

export const NextButton = styled(Button)`
    && {
        font-weight: bold;
        color: white;
        width: 100px;
        height: 48px;
        margin: 16px 0 0;
        border-radius: 4px;
        background-color: ${(props) => props.theme.colors.primary};

        :hover {
            color: #1e88e5;
        }
    }
`;

import { iD3Data, iData } from "../types";

interface DeleteD3TagParams {
    d3Data: iD3Data;
    tagId: string;
    data: iData;
    setData: React.Dispatch<React.SetStateAction<iData>>;
}

export const deleteD3Tag = ({ d3Data, tagId, data, setData }: DeleteD3TagParams): iData => {
    const newDataTags = data.tags.filter((item) => item._id !== tagId);

    const newData = {
        ...data,
        tags: newDataTags
    };

    setData(newData);
    return newData;
};

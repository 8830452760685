import { toastError, toastSuccess, toastWarning } from "@3edges/utils/dist/toastify";
import { ApolloQueryResult, OperationVariables, useLazyQuery, useMutation } from "@apollo/client";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { getStorage } from "cache";
import { DELETE_ACCOUNT_ADMIN } from "components/PanelProfile/gql";
import { useData } from "contexts/dataContext";
import { PRIM_SET_AS_VERIFIED_BY_ADMIN } from "pages/Activation/gql";
import { ADMIN_CHANGE_USER_ROLE, ADMIN_REMOVE_USER_FROM_TENANT, GET_USERS_BY_TENANT } from "pages/AdminPage/gql";
import { PRIM_GET_NEW_VERIFICATION_CODE } from "pages/CreateNewUser/gql";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
    GetAllTenants_niamTenants as NiamTenant, Role,
    adminGetUsersByTenant_adminGetUsersByTenant_users as TenantUsers,
    adminGetUsers_adminGetUsers_users as Users,
    adminDeleteUser as adminDeleteUserResponse,
    adminGetUsers,
    adminGetUsersByTenant
} from "types/operation-result-types";
import { isSuperAdmin } from "utilsFn";
import ModalAddToTenant from "../ModalAddToTenant/ModalAddToTenant";
import ModalChangeRole from "../ModalChangeRole/ModalChangeRole";
import ModalSetPWD from "../ModalSetPWD/ModalSetPWD";
import { ACTIONS, actionType } from "./constants";
import { ActionContainer, ChangeRole, StyledButton, StyledTooltip } from "./styled";

interface IActionButtons {
    user: TenantUsers | Users;
    tenants?: NiamTenant[];
    role: string;
    refetchUsers: (
        variables?: Partial<OperationVariables>
    ) => Promise<ApolloQueryResult<adminGetUsers & adminGetUsersByTenant>>;
}

interface adminChangeUserRoleResponse {
    adminChangeUserRole: boolean;
}
interface adminRemoveUserResponse {
    adminRemoveUser: boolean;
}

export function ActionButtons ({ user, role, tenants, refetchUsers }: IActionButtons): React.ReactElement {
    const { t } = useTranslation();
    const { tenant, currentTenantID } = useData();
    const currUser = getStorage<any>("user");
    const history = useHistory();

    const [action, setAction] = useState<actionType>(null);
    const [isModalRoleOpen, changeModalRoleOpen] = useState(false);
    const [isModalSetPWD, setIsModalSetPWD] = useState(false);
    const [isModalTenatsOpen, changeModalTenantsOpen] = useState(false);

    const [changeUserRole, { data: chageRoleData, error: changeRoleError }] =
        useMutation<adminChangeUserRoleResponse>(ADMIN_CHANGE_USER_ROLE);
    const [removeUser, { data: removeUserData, error: removeError }] =
        useMutation<adminRemoveUserResponse>(ADMIN_REMOVE_USER_FROM_TENANT);
    const [adminDeleteUser] = useMutation<adminDeleteUserResponse>(DELETE_ACCOUNT_ADMIN);
    const isCurrentUser = currUser._id === (user as TenantUsers)._id;
    const [getNewCode] = useLazyQuery(PRIM_GET_NEW_VERIFICATION_CODE);
    const [setAsVerifiedByAdmin] = useLazyQuery(PRIM_SET_AS_VERIFIED_BY_ADMIN);

    const [getUsersFromNiamTenant, { data: dataTenant }] = useLazyQuery(GET_USERS_BY_TENANT, { fetchPolicy: "no-cache" });

    useEffect(() => {
        if (changeRoleError) {
            toastError(t(`validations:${changeRoleError.message}`));
        }
        if (removeError) {
            toastError(t(`validations:${removeError.message}`));
        }

    }, [changeRoleError, removeError]);

    useEffect(() => {
        if (chageRoleData?.adminChangeUserRole || removeUserData?.adminRemoveUser) {
            void refetchUsers();
        }

    }, [chageRoleData, removeUserData]);

    useEffect(() => {
        if (dataTenant) {
            const collectionAdmins = dataTenant.adminGetUsersByTenant.users.filter((f) => f.email !== user.email);
            const existAdmin = collectionAdmins.find((f) => f.role === Role.ADMIN) || null;

            // Before change Role or Remove, check if exists another owner.
            if (!existAdmin && action === ACTIONS.MAKE_USER) {
                toastWarning(t("validations:no_more_users"));
                changeModalRoleOpen(false);
                handleClose();
                return;
            }

            if (!existAdmin && action === ACTIONS.REMOVE && collectionAdmins.length > 0) {
                toastWarning(t("validations:no_more_users"));
                changeModalRoleOpen(false);
                handleClose();
                return;
            }

            handleClickOption();
        }

    }, [dataTenant]);

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const handleClickOption = (): void => {
        // make sure the current tenantID is correctly populated when we do have that information: i.e. if not from the context hook (i.e. from currentTenantID); then we can grab it from the current selected user
        // because that id is a necessary input parameter to changeUserRole and removeUser GraphQL client calls
        const tenantID = !currentTenantID && user && (user as Users).tenants.length > 0 ? (user as Users).tenants[0].tenantID : currentTenantID;

        if (action === ACTIONS.MAKE_ADMIN) {
            void changeUserRole({ variables: { tenantID, userID: (user as TenantUsers)._id, role: Role.ADMIN } });
            changeModalRoleOpen(false);
            handleClose();
        }

        if (action === ACTIONS.MAKE_USER) {
            void changeUserRole({ variables: { tenantID, userID: (user as TenantUsers)._id, role: Role.USER } });
            changeModalRoleOpen(false);
            handleClose();
        }

        if (action === ACTIONS.REMOVE) {
            void removeUser({ variables: { tenantID, userID: (user as TenantUsers)._id } });

            if (isCurrentUser) {
                history.push("/");
            } else {
                handleClose();
            }
        }

        if (action === ACTIONS.DELETE) {
            void adminDeleteUser({
                variables: {
                    userID: (user as TenantUsers)._id
                }
            })
                .then(({ errors }: any) => {
                    if (errors) {
                        for (const e of errors) {
                            toastError(t(`validations:${e.message}`));
                        }
                        return;
                    }

                    toastSuccess(t("adminPage:deleteUser.resolve"));
                    void refetchUsers();
                })
                .catch(() => toastError(t("validations:out.of.service")));
            handleClose();
        }
    };

    return (
        <ActionContainer>
            <StyledButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} data-cy="userTablePage_actionsButtons">
                <StyledTooltip title="User actions" placement="top" arrow>
                    <ChangeRole />
                </StyledTooltip>
            </StyledButton>

            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                {((user as Users).tenants && (user as Users).tenants.length > 0) || currUser.roles?.includes('SUPER_ADMIN') ? (
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            changeModalTenantsOpen(true);
                        }}
                    >
                        {t("adminPage:addToTenant.option")}
                    </MenuItem>
                ): null}

                {((user as Users).tenants && (user as Users).tenants.length > 0) || tenant ? (
                    <div>
                        {!role?.includes(Role.USER) && (
                            <MenuItem
                                onClick={() => {
                                    setAction("makeUser");
                                    changeModalRoleOpen(true);
                                }}
                            >
                                {t("adminPage:makeAsUser.option")}
                            </MenuItem>
                        )}

                        {!role?.includes(Role.ADMIN) && (
                            <MenuItem
                                onClick={() => {
                                    setAction("makeAdmin");
                                    changeModalRoleOpen(true);
                                }}
                            >
                                {t("adminPage:makeAsAdmin.option")}
                            </MenuItem>
                        )}

                        <MenuItem
                            onClick={() => {
                                setAction("remove");
                                changeModalRoleOpen(true);
                            }}
                        >
                            {t("adminPage:removeFromTenant.option")}
                        </MenuItem>
                    </div>
                ): null}

                {!user.isVerified && (
                    <div>
                        {user.isExpiredVerifyCode ? (
                            <MenuItem
                                    onClick={() => {
                                        void getNewCode({
                                            variables: {
                                                email: user.email,
                                                emailOptions: {
                                                    subject: t("createNewUser:step3.emailOptions.subject"),
                                                    html: t("createNewUser:step3.emailOptions.html"),
                                                    text: t("createNewUser:step3.emailOptions.text")
                                                }
                                            }
                                        }).then(({ errors }: any) =>
                                        {
                                            if (errors) {
                                                for (const e of errors) {
                                                    toastError(t(`validations:${e.message}`));
                                                }
                                                return;
                                            }

                                            toastSuccess(t("root:btn.done"));
                                            handleClose();
                                            refetchUsers();
                                        }).catch((error) => console.log(error));
                                    }}
                                >
                                {t("adminPage:action.resend.activation.email")}
                            </MenuItem>
                        ): null}

                        <MenuItem
                            onClick={() => {
                                void setAsVerifiedByAdmin({
                                    variables: {
                                        email: user.email,
                                        verificationCode: user.verificationCode
                                    }
                                }).then(({ errors }: any) => {
                                    if (errors) {
                                        for (const e of errors) {
                                            toastError(t(`validations:${e.message}`));
                                        }
                                        return;
                                    }

                                    toastSuccess(t("root:btn.done"));
                                    handleClose();
                                    refetchUsers();
                                }).catch((error) => console.log(error));
                            }}
                        >
                            {t("adminPage:action.activate.account")}
                        </MenuItem>
                    </div>
                )}

                <MenuItem
                    onClick={() => {
                        setIsModalSetPWD(true)
                        handleClose();
                    }}
                >
                    {t("adminPage:action.reset.password")}
                </MenuItem>

                {isSuperAdmin() && (
                    <MenuItem
                        data-cy="actionsButtonsPage_delete"
                        onClick={() => {
                            setAction("delete");
                            changeModalRoleOpen(true);
                        }}
                    >
                        {t("adminPage:deleteUser.option")}
                    </MenuItem>
                )}
            </Menu>

            {isModalSetPWD ? (
                <ModalSetPWD
                    isModalSetPWD={isModalSetPWD}
                    userID={user._id}
                    onClose={() => {
                        setIsModalSetPWD(false);
                    }}
                />
            ): null}

            {isModalRoleOpen ? (
                <ModalChangeRole
                    handleClickOption={() => {
                        // make sure the current tenantID is correctly populated when we do have that information: i.e. if not from the context hook; then we can grab it from the current selected user
                        const tenantID = !currentTenantID && user && (user as Users).tenants.length > 0 ? (user as Users).tenants[0].tenantID : currentTenantID;
                        return tenantID ? getUsersFromNiamTenant({ variables: { tenantID: tenantID } }) : handleClickOption()
                    }}
                    action={action}
                    isModalRoleOpen={isModalRoleOpen}
                    onClose={() => {
                        changeModalRoleOpen(false);
                        handleClose();
                    }}
                />
            ): null}

            {isModalTenatsOpen ? (
                <ModalAddToTenant
                    tenants={tenants}
                    isModalTenatsOpen={isModalTenatsOpen}
                    userId={(user as TenantUsers)._id}
                    onClose={() => {
                        changeModalTenantsOpen(false);
                        handleClose();
                        refetchUsers()
                    }}
                />
            ): null}
        </ActionContainer>
    );
}

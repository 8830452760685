/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { toastError, toastSuccess, toastWarning } from "@3edges/utils/dist/toastify";
import { isEmpty, isNotEmpty } from "@3edges/utils/dist/utils";
import { useMutation } from "@apollo/client";
import logo from "assets/images/vertical-logo.png";
import { setStorage } from "cache";
import { ButtonListFlex, ConfirmButton } from "components/ModalDataServer/styled";
import { REACT_ENV } from "environmentVariables";
import { PageContainer } from "pages/Canvas/styled";
import { LoginCenteredContainer, LoginForm } from "pages/Login/styled";
import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { PwdField } from "ui-components/pwdField";
import { RESET_PWD } from "./gql";

function ResetPWD(): React.ReactElement {
    const history = useHistory();
    const { t } = useTranslation();

    const [setNewPWD, { loading: waitPWD }] = useMutation<{ resetPWD: boolean }>(RESET_PWD);

    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const recaptchaRefPWD = useRef(null);
    const [recaptchaRefPWDValue, setRecaptchaRefPWDValue] = useState("");

    const ref = new URLSearchParams(useLocation().search).get("ref");
    const code = new URLSearchParams(useLocation().search).get("code");

    const savePwd = async (): Promise<void> => {
        if (newPassword !== confirmNewPassword || isEmpty(newPassword)) {
            toastWarning(t("auth:newPassword.different.confirmNewPassword"));
            return;
        }

        if (isEmpty(recaptchaRefPWDValue)) {
            const resCaptcha = await recaptchaRefPWD.current.executeAsync();

            if (resCaptcha) {
                savePwdAfterCaptcha();
            }
        } else {
            savePwdAfterCaptcha();
        }
    };

    const savePwdAfterCaptcha = (): void => {
        void setNewPWD({
            variables: {
                email: ref,
                randomCode: code,
                password: newPassword
            }
        }).then(({ data, errors }: any) => {
            if (errors) {
                for (const e of errors) {
                    toastError(t(`validations:${e.message}`));
                }

                return;
            }

            if (data.resetPWD) {
                toastSuccess(t("auth:reset.pwd.verified"));
                setStorage("tmp-email", ref);
                history.push("/");
            }
        }).catch((error) => console.log(error));
    };

    return (
        <PageContainer>
            <LoginCenteredContainer>
                <img
                    src={logo}
                    alt={t("root:project.logo")}
                    width="auto"
                    height="160"
                    onKeyPress={() => {}}
                    onClick={() => {
                        history.push("/");
                    }}
                    style={{ marginBottom: 30, cursor: "pointer" }}
                />

                <h1 style={{ fontSize: 25, marginBottom: 50 }}>
                    {t("auth:reset-pwd.title")}
                </h1>

                <LoginForm>
                    <PwdField
                        autoFocus
                        value={newPassword}
                        onChange={(e) => {
                            setNewPassword(e.target.value);
                        }}
                        label={t("auth:newPassword.label")}
                    />

                    <PwdField
                        value={confirmNewPassword}
                        onChange={(e) => {
                            setConfirmNewPassword(e.target.value);
                        }}
                        label={t("auth:confirmNewPassword.label")}
                        onKeyPress={(e) => {
                            if (
                                (e.keyCode === 13 || e.charCode === 13) &&
                                isNotEmpty(newPassword) &&
                                isNotEmpty(confirmNewPassword)
                            ) {
                                void savePwd();
                            }
                        }}
                    />

                    <ButtonListFlex>
                        <ConfirmButton onClick={savePwd} disabled={waitPWD || isEmpty(newPassword) || isEmpty(confirmNewPassword)}>
                            {t("root:btn.save.label")}
                        </ConfirmButton>
                    </ButtonListFlex>
                </LoginForm>
            </LoginCenteredContainer>

            <ReCAPTCHA
                ref={recaptchaRefPWD}
                size="invisible"
                onChange={setRecaptchaRefPWDValue}
                sitekey={REACT_ENV.REACT_APP_CAPTCHA_V2_INVISIBLE}
            />
        </PageContainer>
    );
}

export default ResetPWD;

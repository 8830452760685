import { toastError, toastSuccess } from "@3edges/utils/dist/toastify";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Cancel } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { COLORS } from "assets/defaultVariables";
import { selectedUser } from "cache";
import ListPanel from "components/ListPanel";
import ModalTenant from "components/ModalTenant";
import ShowMode from "components/ShowMode";
import { LoadingData } from "pages/ServiceAccounts/styled";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { GetAllTenants, GetAllTenants_niamTenants as NiamTenant, Role } from "types/operation-result-types";
import PanelCard from "ui-components/PanelCard";
import { DELETE_TENANT, GET_ALL_TENANTS } from "./gql";
import { Body, BodyPanel, FlexList, TitleContainer } from "./styled";

function PanelTenant (): React.ReactElement {
    const { t } = useTranslation();
    const history = useHistory();
    const currentUser = selectedUser()

    const [getListTenants, { data, loading }] = useLazyQuery<GetAllTenants>(GET_ALL_TENANTS)
    const [dataCollection, setDataCollection] = useState([]);
    const [dataCollectionFiltered, setDataCollectionFiltered] = useState([]);

    const [deleteTenant] = useMutation(DELETE_TENANT);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editTenant, setEditTenant] = useState<NiamTenant | null>(null);
    const [isShowModeList, setIsShowModeList] = useState(false);
    const [filterBy, setFilterBy] = useState("");

    useEffect(() => {
        getListTenants()
    }, []);

    useEffect(() => {
        if (dataCollection) {
            const items = dataCollection.filter(t => t.name.toLowerCase().includes(filterBy.toLowerCase()))
            setDataCollectionFiltered(items)
        }
    }, [filterBy, dataCollection]);

    useEffect(() => {
        if (!loading && data) {
            setDataCollection(data.niamTenants)
        }
    }, [data, loading])

    useEffect(() => {
        if (!isModalOpen) {
            setEditTenant(null);
        }
    }, [isModalOpen]);

    const onEdit = (value: NiamTenant): void => {
        setEditTenant(value);
        setIsModalOpen(true);
    };

    const onDelete = (value: NiamTenant): void => {
        void Swal.fire({
            icon: "error",
            title: t("tenant:try.to.delete"),
            showConfirmButton: true,
            confirmButtonText: t("tenant:try.to.delete.yes"),
            showCancelButton: true,
            cancelButtonText: t("tenant:try.to.delete.no"),
            confirmButtonColor: COLORS.GREEN,
            cancelButtonColor: COLORS.RED
        }).then((result) => {
            if (result.isConfirmed) {
                void deleteTenant({
                    variables: {
                        tenantID: value._id
                    }
                }).then(({ errors }: any) => {
                    if (errors) {
                        for (const e of errors) {
                            toastError(t(`validations:${e.message}`));
                        }

                        return;
                    }

                    toastSuccess(t("tenant:message.after.deleted"));

                    const isAdmin = currentUser?.roles?.includes(Role.ADMIN);
                    const isSingleTenantRemained =  data?.niamTenants && data?.niamTenants.length === 1;

                    if (isAdmin && isSingleTenantRemained && data?.niamTenants[0]._id === value._id) {
                        // Need to remove the User's ADMIN role in localStorage after the ONLY Organization that they are in as ADMIN is deleted
                        const roles = currentUser?.roles?.filter(role => role !== Role.ADMIN);

                        selectedUser({
                            ...currentUser,
                            roles
                        } as any);
                    }

                    setDataCollection(dataCollection.filter(i => i._id !== value._id))
                }).catch((error) => console.log(error));
            }
        }).catch((error) => console.log(error));
    };

    const onChange = (value: boolean): void => {
        setIsShowModeList(value);
    };

    const onSelect = (value: string): void => {
        history.push(`/${value}/servers`);
    };

    const showUsers = (value): void => {
        history.push(`/${value._id}/admin-page`);
    };

    return (
        <Body>
            <TitleContainer>
                {t("panel:header.tenants")}
            </TitleContainer>

            <BodyPanel>
                <TextField
                    style={{ width: 220 }}
                    label={t("panel:filterBy.label")}
                    value={filterBy}
                    onChange={(e) => {
                        setFilterBy(e.target.value);
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setFilterBy("")} edge="end">
                                    {filterBy.length > 0 ? <Cancel /> : ""}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />

                <ShowMode onChange={onChange} />
            </BodyPanel>

            <FlexList>
                {isShowModeList
                    ? (
                        <ListPanel t={t} data={dataCollectionFiltered} loading={loading} onEdit={onEdit} onDelete={onDelete} onSelect={onSelect} onShowUsers={showUsers} parentPage="tenant">
                            <PanelCard
                                isAdd
                                smallCard
                                text={t("panel:add.tenant.card")}
                                onClick={() => {
                                    setIsModalOpen(true);
                                }}
                            />
                        </ListPanel>
                    )
                    : (
                        <>
                            <PanelCard
                                isAdd
                                text={t("panel:add.tenant.card")}
                                onClick={() => {
                                    setIsModalOpen(true);
                                }}
                            />

                            {loading && (
                                <LoadingData>
                                    <div>Loading data</div>
                                    <div className="loader" />
                                </LoadingData>
                            )}

                            {!loading && dataCollectionFiltered.length > 0 && dataCollectionFiltered.map((tn) => (
                                <PanelCard
                                    info={tn}
                                    icon="TenantIcon"
                                    text={tn.name}
                                    belongsTo={tn.administrators}
                                    tenantInfo={tn}
                                    key={`tenant-card-${tn._id}`}
                                    onClick={() => {
                                        onSelect(tn._id);
                                    }}
                                    onEdit={() => {
                                        onEdit(tn);
                                    }}
                                    onDelete={() => {
                                        onDelete(tn);
                                    }}
                                    onShowUsers={() => {
                                        showUsers(tn);
                                    }}
                                />
                            ))}
                        </>
                    )}

                <ModalTenant
                    tenantToEdit={editTenant}
                    isModalOpen={isModalOpen}
                    onClose={(dataSaved?: any, closeModal?: boolean) => {
                        if (dataSaved) {
                            const asAdmin = currentUser?.roles?.includes(Role.ADMIN)
                            const roles = asAdmin ? [...currentUser?.roles || []] : [...currentUser?.roles || [], Role.ADMIN]

                            selectedUser({
                                ...currentUser,
                                roles
                            } as any);

                            if (closeModal) {
                                // Add new one
                                setDataCollection([dataSaved, ...dataCollection])
                            } else {
                                // Overwrite the existent
                                const itemsChanged = dataCollection.map((d) => {
                                    if (d._id === dataSaved._id) {
                                        d = dataSaved
                                    }

                                    return d
                                })

                                setDataCollection([...itemsChanged])
                            }
                        }

                        setIsModalOpen(false)
                    }}
                />
            </FlexList>
        </Body>
    );
}

export default PanelTenant;

/* eslint-disable max-lines */
/* eslint-disable prefer-const */
import { getStorage } from "cache";
import { NiamFieldTypeEnum } from "types/operation-result-types";
import { getNewPosition, setCurveFields, setCurveMap } from "utils";
import { NiamImplementElement, NiamPathElement, NodeRelationship, NodeTypename, PrimCanvasPoint, PrimCanvasPointObject, RelationshipType, iCleanedData } from "./types";

const DataFormatter = (dataCollection: any, dataCurrent?: any, enablePhysicsEffect?: boolean, firstLoad?: boolean): iCleanedData => {
    let primPaths: NiamPathElement[] = [];
    let primImplements: NiamImplementElement[] = [];
    let primPropertiesRelLinks: NiamImplementElement[] = [];
    const curveMap = new Map();

    const data = dataCollection

    // Keep the nodes positions updated on D3
    if (firstLoad) {
        data.niamObjects = [...data.niamObjects?.map(item => {
            const itemRearranged = dataCurrent.objects?.find(i => i._id === item._id)
            if (itemRearranged) {
                item.x = itemRearranged.x
                item.y = itemRearranged.y
            }
            return item
        }) || []]
        data.niamCustomMutations = [...data.niamCustomMutations?.map(item => {
            const itemRearranged = dataCurrent.customMutations?.find(i => i._id === item._id)
            if (itemRearranged) {
                item.x = itemRearranged.x
                item.y = itemRearranged.y
            }
            return item
        }) || []]
        data.niamTags = [...data.niamTags?.map(item => {
            const itemRearranged = dataCurrent.tags?.find(i => i._id === item._id)
            if (itemRearranged) {
                item.x = itemRearranged.x
                item.y = itemRearranged.y
            }
            return item
        }) || []]
        data.niamInterfaces = [...data.niamInterfaces?.map(item => {
            const itemRearranged = dataCurrent.interfaces?.find(i => i._id === item._id)
            if (itemRearranged) {
                item.x = itemRearranged.x
                item.y = itemRearranged.y
            }
            return item
        }) || []]
        data.niamScripts = [...data.niamScripts?.map(item => {
            const itemRearranged = dataCurrent.scripts?.find(i => i._id === item._id)
            if (itemRearranged) {
                item.x = itemRearranged.x
                item.y = itemRearranged.y
            }
            return item
        }) || []]
        data.niamEnums = [...data.niamEnums?.map(item => {
            const itemRearranged = dataCurrent.enums?.find(i => i._id === item._id)
            if (itemRearranged) {
                item.x = itemRearranged.x
                item.y = itemRearranged.y
            }
            return item
        }) || []]
        data.niamNodeRelationships = [...data.niamNodeRelationships?.map(item =>
        {
            const itemRearranged = dataCurrent.nodeRelationships?.find(i => i._id === item._id)
            if (itemRearranged) {
                item.x = itemRearranged.x
                item.y = itemRearranged.y
            }
            const itemRelRearranged = dataCurrent.nodeRelationships?.find(i => i._id === item.rel?._id)
            if (itemRelRearranged) {
                item.rel.x = itemRelRearranged.x
                item.rel.y = itemRelRearranged.y
            }
            const itemRelationRearranged = dataCurrent.nodeRelationships?.find(i => i._id === item.relation?.rel._id)
            if (itemRelationRearranged) {
                item.relation.rel.x = itemRelationRearranged.x
                item.relation.rel.y = itemRelationRearranged.y
            }
            return item
        }) || []]
    }

    const isShowDashboard = getStorage("isShowDashboard");

    const getOrbitXY = (orbit): any => {
        // By default, it will be OFF (fixed)
        let x = orbit.x;
        let fx = orbit.x;
        let y = orbit.y;
        let fy = orbit.y;

        // if you click in the "Rearrange" button, it will be ON (move)
        if (enablePhysicsEffect) {
            const position = getNewPosition()
            x = position.x;
            y = position.y;
            fx = null;
            fy = null;
        }

        return { x, y, fx, fy };
    }

    const primInterfaces = data ? data.niamInterfaces.map((d) => {
        const tempD: PrimCanvasPoint<typeof d> = {
            ...d,
            x: getOrbitXY(d).x,
            y: getOrbitXY(d).y,
            fx: getOrbitXY(d).fx,
            fy: getOrbitXY(d).fy,
            _id: d._id,
            __typename: d.__typename,
            isShown: true
        };

        d.fields.forEach((field) => {
            if (field.fieldType === "Relationship") {
                const curve = setCurveFields(curveMap, field.relatedTo, d._id)

                const cm = setCurveMap(field.relatedTo, d._id, curve)
                curveMap.set(cm[0], cm[1])

                primPropertiesRelLinks.push({
                    source: d._id,
                    target: field.relatedTo,
                    type: RelationshipType.RELATION,
                    curve,
                    name: field.name,
                    displayName: field.displayName,
                    fieldType: NiamFieldTypeEnum.Relationship,
                    _id: field._id,
                    isShown: true
                } as unknown as NiamImplementElement);
            }

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (field.fieldType === NodeTypename.NIAM_ENUM) {
                const curve = setCurveFields(curveMap, field.relatedTo, d._id)

                const cm = setCurveMap(field.relatedTo, d._id, curve)
                curveMap.set(cm[0], cm[1])

                primPropertiesRelLinks.push({
                    source: field.relatedTo,
                    target: d._id,
                    type: "enum",
                    curve,
                    name: field.name,
                    displayName: field.displayName,
                    _id: field._id,
                    isShown: true
                } as unknown as NiamImplementElement);
            }
        });
        return tempD;
    }) : [];

    const primScripts = data ? data.niamScripts.map((d) => {
        const tempD: PrimCanvasPoint<typeof d> = {
            ...d,
            x: getOrbitXY(d).x,
            y: getOrbitXY(d).y,
            fx: getOrbitXY(d).fx,
            fy: getOrbitXY(d).fy,
            _id: d._id,
            __typename: d.__typename,
            isShown: true
        };

        d.relationships.forEach((rel) => {
            if (rel.fieldType === "Relationship") {
            const curve = setCurveFields(curveMap, rel.relatedTo, d._id)

            const cm = setCurveMap(rel.relatedTo, d._id, curve)
            curveMap.set(cm[0], cm[1])

                primPropertiesRelLinks.push({
                    source: d._id,
                    target: rel.relatedTo,
                    type: RelationshipType.RELATION,
                    fieldType: NiamFieldTypeEnum.Relationship,
                    curve,
                    name: rel.name,
                    displayName: rel.eventType ? `${rel.displayName} (${rel.eventType})` : rel.displayName,
                    _id: rel._id,
                    isShown: true
                } as unknown as NiamImplementElement);
            }
        });

        return tempD;
    }) : [];

    const primNodeRelationships = data ? data.niamNodeRelationships.map((nr) => {
        if (nr.relation) {
            nr = {
                ...nr,
                ...nr.relation
            }
        }

        const tempD: PrimCanvasPoint<typeof nr.rel> & {
            relation: typeof nr;
        } = {
            ...nr.rel,
            x: getOrbitXY(nr.rel).x,
            y: getOrbitXY(nr.rel).y,
            fx: getOrbitXY(nr.rel).fx,
            fy: getOrbitXY(nr.rel).fy,
            _id: nr.rel._id,
            __typename: nr.rel.__typename,
            isShown: true,
            relation: nr
        };

        const simplePath = nr.rel.simplePath ? {
            _id: nr.rel.simplePath._id,
            relatedTo: nr.rel.simplePath.relatedTo,
        } : null

        const simplePathCreate = nr.rel.simplePathCreate ? {
            _id: nr.rel.simplePathCreate._id,
            relatedTo: nr.rel.simplePathCreate.relatedTo,
        } : null

        const simplePathRead = nr.rel.simplePathRead ? {
            _id: nr.rel.simplePathRead._id,
            relatedTo: nr.rel.simplePathRead.relatedTo,
        } : null

        const simplePathUpdate = nr.rel.simplePathUpdate ? {
            _id: nr.rel.simplePathUpdate._id,
            relatedTo: nr.rel.simplePathUpdate.relatedTo,
        } : null

        const simplePathDelete = nr.rel.simplePathDelete ? {
            _id: nr.rel.simplePathDelete._id,
            relatedTo: nr.rel.simplePathDelete.relatedTo,
        } : null

        if (nr.objFrom && nr.relFrom) {
            primPropertiesRelLinks.push({
                curve: 0,
                source: nr.objFrom._id,
                target: nr.rel._id,
                type: RelationshipType.NODE_RELATION,
                _id: nr.relFrom._id,
                isShown: true,
                isPath: !!simplePath,
                isPathCreate: !!simplePathCreate,
                isPathRead: !!simplePathRead,
                isPathUpdate: !!simplePathUpdate,
                isPathDelete: !!simplePathDelete,
                simplePath,
                simplePathCreate,
                simplePathRead,
                simplePathUpdate,
                simplePathDelete,
                relation: {
                    objFrom: nr.objFrom,
                    relFrom: nr.relFrom
                }
            } as NiamImplementElement);
        }

        if (nr.objTo && nr.relTo) {
            primPropertiesRelLinks.push({
                curve: 0,
                source: nr.rel._id,
                target: nr.objTo._id,
                type: RelationshipType.NODE_RELATION,
                _id: nr.relTo._id,
                isShown: true,
                isPath: !!simplePath,
                isPathCreate: !!simplePathCreate,
                isPathRead: !!simplePathRead,
                isPathUpdate: !!simplePathUpdate,
                isPathDelete: !!simplePathDelete,
                simplePath,
                simplePathCreate,
                simplePathRead,
                simplePathUpdate,
                simplePathDelete,
                relation: {
                    objTo: nr.objTo,
                    relTo: nr.relTo
                }
            } as NiamImplementElement);
        }

        if (nr.rel.fields && nr.rel.fields.length > 0) {
        const nodeRelation = nr.rel;
        nodeRelation.fields.forEach((field) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (field.fieldType === NodeTypename.NIAM_ENUM) {
                const curve = setCurveFields(curveMap, field.relatedTo, nodeRelation._id)

                const cm = setCurveMap(field.relatedTo, nodeRelation._id, curve)
                curveMap.set(cm[0], cm[1])

                primPropertiesRelLinks.push({
                    source: field.relatedTo,
                    target: nodeRelation._id,
                    type: "enum",
                    curve,
                    name: field.name,
                    displayName: field.displayName,
                    _id: field._id,
                    isShown: true
                } as unknown as NiamImplementElement);
            }
        });
        }

        return tempD as unknown as NodeRelationship;
    }) : [];

    const primCustomMutations = data ? data.niamCustomMutations.map((d) => {
        const tempD: PrimCanvasPoint<typeof d> = {
            ...d,
            x: getOrbitXY(d).x,
            y: getOrbitXY(d).y,
            fx: getOrbitXY(d).fx,
            fy: getOrbitXY(d).fy,
            _id: d._id,
            __typename: d.__typename,
            isShown: true
        };

        return tempD;
    }) : [];

    const primTags = data ? data.niamTags?.map((d) => {
        const tempD: PrimCanvasPoint<typeof d> = {
            ...d,
            x: getOrbitXY(d).x,
            y: getOrbitXY(d).y,
            fx: getOrbitXY(d).fx,
            fy: getOrbitXY(d).fy,
            _id: d._id,
            __typename: d.__typename,
            isShown: true
        };

        return tempD;
    }) : [];

    const primEnums = data ? data.niamEnums.map((d) => {
        const tempD: PrimCanvasPoint<typeof d> = {
            ...d,
            x: getOrbitXY(d).x,
            y: getOrbitXY(d).y,
            fx: getOrbitXY(d).fx,
            fy: getOrbitXY(d).fy,
            _id: d._id,
            __typename: d.__typename,
            isShown: true
        };

        return tempD;
    }) : [];

    const primObjects = data ? data.niamObjects.map((d) => {
        const tempD: PrimCanvasPointObject<typeof d> = {
            ...d,
            x: getOrbitXY(d).x,
            y: getOrbitXY(d).y,
            fx: getOrbitXY(d).fx,
            fy: getOrbitXY(d).fy,
            _id: d._id,
            __typename: d.__typename,
            isShown: true,
            item: d
        };

        if (!isShowDashboard) {
            primPaths = [
                ...primPaths,
                ...d.paths.map((p) => ({
                    source: d._id,
                    target: p.targetID,
                    type: RelationshipType.PATHS,
                    _id: p._id,
                    isShown: true
                }))
            ] as NiamPathElement[];
        }

        tempD.interfaces = d.interfaces.map((di) => ({ _id: di._id } as typeof di));

        d.fields.forEach((field) => {
            if (field.fieldType === "Relationship") {
                const curve = setCurveFields(curveMap, field.relatedTo, d._id)

                const cm = setCurveMap(field.relatedTo, d._id, curve)
                curveMap.set(cm[0], cm[1])

                let simplePath = {}

                if (d.simplePath)
                {
                    const fieldPath = d.simplePath.find(sp => sp.relatedTo === field._id)

                    if (fieldPath) {
                        simplePath = fieldPath
                    }
                }

                let simplePathCreate = {}

                if (d.simplePathCreate) {
                    const fieldPathCreate = d.simplePathCreate.find(sp => sp.relatedTo === field._id)

                    if (fieldPathCreate) {
                        simplePathCreate = fieldPathCreate
                    }
                }

                let simplePathRead = {}

                if (d.simplePathRead) {
                    const fieldPathRead = d.simplePathRead.find(sp => sp.relatedTo === field._id)

                    if (fieldPathRead) {
                        simplePathRead = fieldPathRead
                    }
                }

                let simplePathUpdate = {}

                if (d.simplePathUpdate) {
                    const fieldPathUpdate = d.simplePathUpdate.find(sp => sp.relatedTo === field._id)

                    if (fieldPathUpdate) {
                        simplePathUpdate = fieldPathUpdate
                    }
                }

                let simplePathDelete = {}

                if (d.simplePathDelete) {
                    const fieldPathDelete = d.simplePathDelete.find(sp => sp.relatedTo === field._id)

                    if (fieldPathDelete) {
                        simplePathDelete = fieldPathDelete
                    }
                }

                primPropertiesRelLinks.push({
                    source: d._id,
                    target: field.relatedTo,
                    type: RelationshipType.RELATION,
                    fieldType: NiamFieldTypeEnum.Relationship,
                    curve,
                    name: field.name,
                    displayName: field.displayName,
                    _id: field._id,
                    isShown: true,
                    simplePath,
                    simplePathCreate,
                    simplePathRead,
                    simplePathUpdate,
                    simplePathDelete,
                    isAction: field.isAction,
                    isVC: field.isVC
                } as unknown as NiamImplementElement);
            }

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (field.fieldType === NodeTypename.NIAM_ENUM) {
                const curve = setCurveFields(curveMap, field.relatedTo, d._id)

                const cm = setCurveMap(field.relatedTo, d._id, curve)
                curveMap.set(cm[0], cm[1])

                primPropertiesRelLinks.push({
                    source: field.relatedTo,
                    target: d._id,
                    type: "enum",
                    curve,
                    name: field.name,
                    displayName: field.displayName,
                    _id: field._id,
                    isShown: true
                } as unknown as NiamImplementElement);
            }
        });

        primImplements = [
            ...primImplements,
            ...d.interfaces.map((di) => ({
                source: d._id,
                target: di._id,
                type: RelationshipType.IMPLEMENT,
                _id: di._id + d._id,
                isShown: true
            }))
        ] as NiamImplementElement[];

        return tempD;
    }) : [];

    primImplements = primImplements.map((d) => d);
    primPaths = primPaths.map((d) => d);
    primPropertiesRelLinks = primPropertiesRelLinks.map((d) => d);

    return {
        primObjects,
        primNodeRelationships,
        primInterfaces,
        primScripts,
        primCustomMutations,
        primTags,
        primEnums,
        primImplements,
        primPaths,
        primPropertiesRelLinks
    };
};

export default DataFormatter;

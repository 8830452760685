import { TextField as MUTextField, TextFieldProps } from "@mui/material";
import { COLORS } from "assets/defaultVariables";
import styled from "styled-components";

export const ZoomButtonsPanel = styled.div`
    height: 50px;
    width: 150px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    img {
        cursor: pointer;
        width: 32px;
        height: 32px;
    }
`;

export const TextField = styled(MUTextField).attrs<TextFieldProps>(() => {
    const attrs: TextFieldProps = { variant: "outlined" };
    return attrs;
}) <TextFieldProps & { $shouldBeLarge?: boolean; }>`
    && {
        width: "100%";
        max-width: "100%";
        margin: 10px 0;
    }

    && input {
        height: ${(props) => (props.$shouldBeLarge ? "40px" : "20px")};
        max-height: ${(props) => (props.$shouldBeLarge ? "40px" : "20px")};
    }
`;

export const WrapperStyled = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
`;

export const RowBasedNormalContainerStyled = styled.div`
    padding: 30px;
    flex: 0;
    flex-direction: row;
    display: flex;
    width: 100%;
`;

export const ButtonStyled = styled.button<{ $isPrimary?: boolean }>`
    border-radius: 4px;
    min-width: 90px;
    flex: 0;
    min-height: 35px;
    border: 0px;
    background-color: ${(props) => (props.$isPrimary ? COLORS.BLUE : COLORS.WHITE)};
    color: ${(props) => (props.$isPrimary ? COLORS.WHITE : COLORS.BLUE)};
    cursor: pointer;
    justify-content: center;
    align-content: center;
    margin: 0px 15px 0px 0px;
`;

/* eslint-disable unicorn/no-lonely-if */
import
    {
        getPrimGInfo_niamDataServer_niamObjects_fields as INiamField,
        addNewNodeRelationship_addNewNodeRelationship as NewNodeRelationship,
        NiamFieldTypeEnum,
        getPrimGInfo_niamDataServer_niamObjects_interfaces as NiamObjectsInterfaces,
        getPrimGInfo_niamDataServer_niamScripts_relationships as NiamScriptsRelationships,
        getPrimGInfo_niamDataServer_niamObjects_paths as paths
    } from "types/operation-result-types";
import { updateSimulation } from "../simulation";
import { NiamImplementElement, NodeTypename, RelationshipType, iD3Data, iD3Selection, iData } from "../types";
import { getShown } from "../utils";
import { NewDataItem } from "./types";
import { EAddType } from "./useAddD3Items";

interface AddD3LinkParams {
    d3Data: iD3Data;
    data: iData | Partial<iData & NewNodeRelationship>;
    type: EAddType;
    enters: {
        enterOrbit: (selection: iD3Selection) => void;
        updateOrbit: (selection: iD3Selection) => void;
        enterLink: (selection: iD3Selection) => void;
        updateLink: (selection: iD3Selection) => void;
    };
    newItemData: NewDataItem;
}

export const addD3Link = ({ d3Data, data, type, enters, newItemData }: AddD3LinkParams): any => {
    let newRelLinksData = data.relLinks.filter((item) => item._id !== newItemData._id);
    let newDataObjects = [...data.objects];
    let newDataInterfaces = [...data.interfaces];
    let newDataScripts = [...data.scripts];
    let newDataNodeRelationships = [...data.nodeRelationships];
    let newPathLinksData = [...data.paths];
    let newImplementLinksData = [...data.implementLinks];
    const newDataCustomMutations = [...data.customMutations];
    const newDataTags = [...data.tags];
    const newDataEnums = [...data.enums];

    if (type === RelationshipType.RELATION) {
        newDataObjects = newDataObjects.map((obj) => {
            if (obj._id === newItemData.source["_id"] || obj._id === newItemData.source) {
                obj.fields = [...obj.fields, newItemData as INiamField];
            }

            // adds relationship with Enum and Object in Fields
            if (newItemData.type === "enum" || newItemData.type === NiamFieldTypeEnum.NiamEnum) {
                if (obj._id === newItemData.target["_id"] || obj._id === newItemData.target) {
                    obj.fields = [...obj.fields, newItemData as INiamField];
                }
            }

            return obj;
        });

        // adds relationship with Enum and Object in Fields
        if (newItemData.type === "enum" || newItemData.type === NiamFieldTypeEnum.NiamEnum) {
            newDataInterfaces = newDataInterfaces.map((inter) => {
                if (inter._id === newItemData.target["_id"] || inter._id === newItemData.target) {
                    inter.fields = [...inter.fields, newItemData as INiamField];
                }

                return inter;
            });
        }

        newDataNodeRelationships = newDataNodeRelationships.map((nr) => {
            if (nr._id === newItemData.target["_id"] || nr._id === newItemData.target) {
                nr.fields = [...nr.fields, newItemData as INiamField];
            }

            return nr;
        });

        newDataScripts = newDataScripts.map((scr) => {
            if (scr._id === newItemData.source["_id"] || scr._id === newItemData.source) {
                scr.relationships = [...scr.relationships, newItemData as NiamScriptsRelationships];

                if (newItemData.target["__typename"] === NodeTypename.NIAM_OBJECT) {
                    scr.objects = [...scr.objects, {
                        __typename: NodeTypename.NIAM_OBJECT,
                        _id: newItemData.target["_id"]
                    }];
                }
            }

            return scr;
        });
    }

    if (type === RelationshipType.IMPLEMENT) {
        newDataObjects.map((obj) => {
            if (obj._id === newItemData.source["_id"] || obj._id === newItemData.source) {
                obj.interfaces = [...obj.interfaces, newItemData.target as NiamObjectsInterfaces];
            }

            return obj;
        });

        newImplementLinksData = [...newImplementLinksData, newItemData as NiamImplementElement]
    }

    if (type === RelationshipType.PATHS) {
        newDataObjects.map((obj) => {
            if (obj._id === newItemData.source["_id"] || obj._id === newItemData.source) {
                obj.paths = [...obj.paths, newItemData.target as paths];
            }

            return obj;
        });

        newPathLinksData = [...newPathLinksData, newItemData as NiamImplementElement]
    }

    if (type === RelationshipType.RELATION || type === RelationshipType.NODE_RELATION) {
        newRelLinksData = [...newRelLinksData, newItemData as NiamImplementElement]
    }

    const newData = {
        ...(data as iData),
        objects: newDataObjects,
        scripts: newDataScripts,
        interfaces: newDataInterfaces,
        customMutations: newDataCustomMutations,
        tags: newDataTags,
        enums: newDataEnums,
        nodeRelationships: newDataNodeRelationships,
        implementLinks: newImplementLinksData,
        relLinks: newRelLinksData,
        paths: newPathLinksData
    };

    const orbitShown = getShown([
        ...newData.objects,
        ...newData.interfaces,
        ...newData.scripts,
        ...newData.customMutations,
        ...newData.tags,
        ...newData.enums,
        ...newData.nodeRelationships
    ]);

    const linkShown = getShown([...newData.implementLinks, ...newData.relLinks, ...newData.paths]).reverse();

    // d3Data.g.selectAll(".link:not(.petals)").remove();

    d3Data.g
        .selectAll(".orbit")
        .data(orbitShown, (node: { _id: string }) => node._id)
        .enter()
        .append("g")
        .call(enters.enterOrbit)
        .on("click", d3Data.zoomOnNodesInstance);

    updateSimulation(d3Data.simulation, orbitShown, linkShown);

    d3Data.g
        .selectAll(".link")
        .data(linkShown, (node: { _id: string }) => node._id)
        .enter()
        .append("g")
        .call(enters.enterLink)
        .on("click", d3Data.zoomOnLinksInstance);

    return newData;
};
